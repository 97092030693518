import React, { useEffect, useState } from 'react';
import axios from 'axios';
import pay from "../../assets/img/pay.png";

interface UserDetails {
    id: number;
    [key: string]: any;
}

const DirectTransferForm = () => {
    const [orderNumber, setOrderNumber] = useState<number | null>(null);
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
    const [error, setError] = useState<string | null>(null);
   
  
    const token = sessionStorage.getItem("shopper_token");

    useEffect(() => {
        window.scrollTo(0, 0);

        axios
        .get(`${process.env.REACT_APP_Base_url}/shoppers/me`, {
            headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        })
        .then((res) => {
            console.log(res.data);
            if (res.data.status === 200) {
            setUserDetails(res.data.user);
            } else if (
            res.data.message === "jwt expired" &&
            res.data.status === 401
            ) {
            sessionStorage.clear();
            window.location.href = "/login";
            }
        })
        .catch((err) => {
            console.log(err);
        });

        
        const fetchOrderNumber = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/products/order_number`); // API call to backend
            setOrderNumber(response.data.orderNumber);
        } catch (error) {
            console.error(error);
            setError('Failed to fetch order number');
        }
        };

        fetchOrderNumber();
    }, []);

    // handle checkout logic
    const handleCheckout = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        alert(
        "Thank you for shopping with us. Your order has been placed successfully."
        );

        // clear cart
        axios
        .post(
            `${process.env.REACT_APP_Base_url}/products/clear_all_cart`,
            {
            user_id: userDetails?.id,
            },
            {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
            }
        )
        .then((res) => {
            console.log(res.data);
            window.location.href = "/";
        })
        .catch((err) => {
            console.log(err);
        });
    };

    return (
        <div>
            {/* Your Direct Transfer payment form components */}
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">                             
                    </div>
                </div>
                <div className="bank-account-container">
                    <p>
                        <strong>
                            PLACE ORDER BEFORE MAKING THE TRANSFER
                        </strong>
                    </p>
                    <p>
                        <strong> 
                            REFERENCE: <span style={{ color: 'red' }}> SHOULD BE YOUR ORDER NUMBER </span>
                            &nbsp; YOUR ORDER NUMBER IS: 
                        </strong>
                        {orderNumber ? `#${orderNumber}` : 'Generating order number...'}
                    </p>
                    <p>
                        <img src={pay} alt="pay" />
                    </p>
                        <p className="p-complete-payment">Once you have placed your order, you can make your payment to this MoMo account. Please add your order number to your payment description.</p>
                    </div>
                    <form onSubmit={handleCheckout}>
                        <div id="payment-button-container" className="payment-button-cnt" style={{ visibility: 'visible' }}>
                            <button 
                                type="submit" 
                                name="submit" 
                                className="btn btn-lg btn-custom btn-payment" 
                            >
                                Place Order
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default DirectTransferForm;