import React from 'react';
import { IconContext } from 'react-icons';
import { FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus } from "react-icons/fa6";
import { FaEnvelope, FaFacebookF, FaEdit, FaHome, FaFile, FaShoppingBasket, FaCog, FaComments, 
        FaQuestion, FaLock, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck, FaBan, FaRegClock,
        FaPhone, FaCaretDown, FaPaperPlane, FaUserTimes, FaUserCheck, FaTachometerAlt, FaExclamationTriangle, FaSpinner, FaFilter, FaTrash } from "react-icons/fa";
import { BiLogoGmail, BiChevronDown, BiChevronRight, BiChevronLeft, BiLogInCircle, BiLogOutCircle, BiUserCheck } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";
import { BsHeart, BsHeartFill, BsCart3, BsCartCheck, BsCartCheckFill, BsCartX } from "react-icons/bs";
import { MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline, MdOutlinePending, MdReportProblem, MdOutlineStar,
        MdOutlineStarBorder, MdArrowRightAlt } from "react-icons/md";
import { TbTruckDelivery, TbMessageCircle } from 'react-icons/tb';
import { IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation, IoFilterOutline, IoSettingsOutline, IoArrowBack, IoAdd,
    IoEyeOutline, IoHeartOutline, IoHeartSharp } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { HiMenuAlt2 } from "react-icons/hi";
import { FcApproval, FcCancel } from "react-icons/fc";
import { VscEye, VscEyeClosed, VscLayoutSidebarLeft, VscClose } from "react-icons/vsc";
// Import other icons as needed

const iconComponents = {
    FaEnvelope, FaFacebookF, FaEdit, FaHome, FaFile, FaShoppingBasket, FaQuestion, FaCog, FaComments, FaSpinner, FaFilter, FaTrash,
    FaLock, FaCircle, FaUserPlus, FaArrowRight, FaArrowLeft, FaCheck, FaPhone, FaCaretDown, FaBan, FaPaperPlane,
    FaUserTimes, FaUserCheck, FaTachometerAlt, FaExclamationTriangle, FaRegClock,
    FaXTwitter, FaInstagram, FaWhatsapp, FaEye, FaPlus, FaMinus,
    BiLogoGmail, BiChevronDown, BiChevronLeft, BiChevronRight, BiLogInCircle, BiLogOutCircle, BiUserCheck,
    BsHeart, BsHeartFill, BsCartCheck, BsCartX,
    CiSearch,
    MdLogout, MdLogin, MdClose, MdDeleteForever, MdOutlineFaceRetouchingNatural, MdEmail, MdErrorOutline, MdOutlinePending, MdReportProblem, MdOutlineStar,
    MdOutlineStarBorder, MdArrowRightAlt,
    TbTruckDelivery, TbMessageCircle,
    IoCart, IoCartOutline, IoPhonePortraitOutline, IoLocation, IoFilterOutline, IoSettingsOutline, IoArrowBack, IoAdd, IoEyeOutline, IoHeartOutline, IoHeartSharp,
    RxDashboard,
    HiMenuAlt2,
    FcApproval, FcCancel,
    VscEye, VscEyeClosed, VscLayoutSidebarLeft, VscClose
  // Add other icons here
};

interface IconProps {
    name: keyof typeof iconComponents;
    size?: string;
    color?: string;
    style?: React.CSSProperties;
    className?: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const Icon: React.FC<IconProps> = ({
    name,
    size = "1em",
    color = "currentColor",
    style = {},
    className = "",
    onClick,
    onMouseEnter,
    onMouseLeave,
}) => {
    const IconComponent = iconComponents[name];
  
    if (!IconComponent) {
        console.warn(`Icon ${name} not found`);
        return null;
    }
  
    return (
        <IconContext.Provider value={{ size, color }}>
            <div
                style={{ display: "inline-block", ...style }}
                className={className}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <IconComponent />
            </div>
        </IconContext.Provider>
    );
};
  
export default Icon;

// Usage:
// <Icon name="FaShoppingBag" size="1.5em" color="#333" />
// import Icon from "../icon/Icon";
