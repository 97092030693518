import React from "react";

interface PriceFormatterProps {
    price: number;
    currencySymbol?: string;
    locale?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

const PriceFormatter: React.FC<PriceFormatterProps> = ({
    price,
    currencySymbol = "¢", // Default to cedi symbol
    locale = "en-US", // Default locale
    minimumFractionDigits = 2, // Default decimal formatting
    maximumFractionDigits = 2,
}) => {
    const formattedPrice = new Intl.NumberFormat(locale, {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(price);

    return <span className="price">{`${currencySymbol}${formattedPrice}`}</span>;
};

export default PriceFormatter;