import React, { ReactElement, useEffect, useState } from "react";
import { CategoryProps, SectionProps, ProductFormData } from "../../types/Types";
import styled from "styled-components";
import { sellerService } from "../../services/sellerService";

interface FormFieldProps {
    label: string;
    id: string;
    error?: string;
    children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ label, id, error, children }) => (
    <InputGroup>
        <Label htmlFor={id}>{label}</Label>
        {React.Children.map(children, (child) => 
            React.isValidElement(child)
            ? React.cloneElement(child, {
                id, 
                'aria-describedby': error ? `${id}-error` : undefined,
                } as Partial<typeof child.props>)
            : child
        )}
        {error && <ErrorMessage id={`${id}-error`}>{error}</ErrorMessage>}
    </InputGroup>
);
  
interface EditProductProps {
    categories: CategoryProps[];
    sections: SectionProps[];
    initialData?: ProductFormData;
}

const EditProduct: React.FC<EditProductProps> = ({ categories, sections, initialData }) => {
    const [formData, setFormData] = useState<ProductFormData>(initialData || {
        product_id: 0,
        product_name: '',
        product_mode: '',
        product_quantity: 0,
        product_price: 0,
        product_category: '',
        sub_category: '',
        product_section: '',
        sub_section: '',
        product_description: '',
        existing_images: [],
        new_images: [],
        main_image_index: 0,
    });
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<Record<string, string>>({});

    // Handle form field changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));
    };

    // Generate preview URLs for new images
    useEffect(() => {
        const newImagePreviews = formData.new_images.map((file) => URL.createObjectURL(file));
        setImagePreviews([...formData.existing_images, ...newImagePreviews]);
    }, [formData.new_images, formData.existing_images]);

    // Handle image upload
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        
        if (files) {
            const newImages = Array.from(files);
                if (formData.existing_images.length + formData.new_images.length + newImages.length > 5) {
                alert('Maximum of 5 images allowed');
            return;
        }

        setFormData((prev) => ({
            ...prev,
            new_images: [...prev.new_images, ...newImages],
            }));
        }
    };

    // Handle image deletion
    const handleImageDelete = (index: number) => {
        if (index < formData.existing_images.length) {
            // Delete existing image
            setFormData((prev) => ({
            ...prev,
            existing_images: prev.existing_images.filter((_, i) => i !== index),
            }));
        } else {
            // Delete new image
            const newIndex = index - formData.existing_images.length;
            setFormData((prev) => ({
                ...prev,
                new_images: prev.new_images.filter((_, i) => i !== newIndex),
            }));
        }
    };

    // Handle setting main image
    const handleSetMainImage = (index: number) => {
        setFormData((prev) => ({
        ...prev,
        main_image_index: index,
        }));
    };

    // form fields validation
    const validateForm = (data: ProductFormData): Record<string, string> => {
        const errors: Record<string, string> = {};
      
        if (!data.product_name.trim()) errors.productName = 'Product name is required';
        if (!data.product_mode) errors.productMode = 'Product availability is required';
        if (data.product_quantity <= 0) errors.productQuantity = 'Quantity must be a positive number';
        if (data.product_price <= 0) errors.productPrice = 'Price must be a positive number';
        if (!data.product_category) errors.productCategory = 'Product category is required';
        if (!data.sub_category) errors.subCategory = 'Sub-category is required';
        if (!data.product_section) errors.productSection = 'Product section is required';
        if (!data.product_description.trim()) errors.productDescription = 'Product description is required';
        
        // Validate images: Check if both existing_images and new_images are empty
        if (data.existing_images.length === 0 && data.new_images.length === 0) {
            errors.productImages = 'At least one image is required';
        }
        
        return errors;
    };  

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        // Validate form data
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                setLoading(false);
            return;
        }

        // Prepare FormData for submission
        const formDataToSubmit = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            if (key === 'new_images') {
                value.forEach((file: File) => formDataToSubmit.append('new_images', file));
            } else if (key === 'existing_images') {
                formDataToSubmit.append('existing_images', JSON.stringify(value)); // Send as JSON
            } else {
                formDataToSubmit.append(key, value.toString());
            }
        });

        try {
            const response = await sellerService.editProduct(formData.product_id, formDataToSubmit);
            console.log('Product updated successfully:', response);
            alert('Product updated successfully!');
        } catch (error) {
            console.error('Error updating product:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageContainer>
                <Title>Edit Product</Title>
                <Form onSubmit={handleSubmit}>
                    <FormField label="Product Name" id="productName" error={errors.productName}>
                        <Input
                            type="text"
                            required
                            id="product_name"
                            value={formData.product_name}
                            onChange={handleInputChange}
                        />
                        {errors.productName && <ErrorMessage>{errors.productName}</ErrorMessage>}
                    </FormField>

                    <FormField label="Product Availability" id="productMode" error={errors.productMode}>
                        <Select
                            required
                            id="product_mode"
                            value={formData.product_mode}
                            onChange={handleInputChange}
                        >
                            <option value="">Choose...</option>
                            <option value="In Stock">In Stock</option>
                            <option value="On Preorder">On Preorder</option>
                            <option value="Out of Stock">Out of Stock</option>
                        </Select>
                        {errors.productMode && <ErrorMessage>{errors.productMode}</ErrorMessage>}
                    </FormField>

                    <FormField label="Product Quantity" id="productQuantity" error={errors.productQuantity}>
                        <Input
                            type="number"
                            id="product_quantity"
                            required
                            value={formData.product_quantity}
                            onChange={handleInputChange}
                            title="Please enter a positive number for quantity."
                        />
                        {errors.productQuantity && <ErrorMessage>{errors.productQuantity}</ErrorMessage>}
                    </FormField>

                    <FormField label="Product Price" id="productPrice" error={errors.productPrice}>
                        <Input
                            type="number"
                            id="product_price"
                            step="0.01" // Allows for decimal prices
                            min="0.01" // Minimum price of 0.01
                            placeholder="Enter price without the currency eg: 100"
                            required
                            value={formData.product_price}
                            onChange={handleInputChange}
                            title="Please enter a positive number for product price"
                        />
                        {errors.productPrice && <ErrorMessage>{errors.productPrice}</ErrorMessage>}
                    </FormField>

                    {categories && (
                        <FormField label="Product Category" id="productCategory" error={errors.productCategory}>
                            <Select
                                required
                                id="product_category"
                                value={formData.product_category}
                                onChange={handleInputChange}
                            >
                                <option value="">Choose...</option>
                                {categories.map((category: CategoryProps) => (
                                    <option
                                        value={category.product_category_name}
                                        key={category.id}
                                    >
                                        {category.product_category_name}
                                    </option>
                                ))}
                            </Select>
                            {errors.productCategory && <ErrorMessage>{errors.productCategory}</ErrorMessage>}
                        </FormField>
                    )}

                    <FormField label="Sub Category" id="subCategory" error={errors.subCategory}>
                        <Select
                            required
                            id="sub_category"
                            value={formData.sub_category}
                            onChange={handleInputChange}
                        >
                            <option value="">Choose...</option>
                            <option value="MEN">MEN</option>
                            <option value="WOMEN">WOMEN</option>
                            <option value="UNISEX">UNISEX</option>
                            <option value="BRANDS">BRANDS</option>
                            <option value="HOME APPLIANCES">HOME APPLIANCES</option>
                            <option value="ELECTRONIC WEARABLES">ELECTRONIC WEARABLES</option>
                            <option value="KIDS ARENA">KIDS ARENA</option>
                        </Select>
                        {errors.subCategory && <ErrorMessage>{errors.subCategory}</ErrorMessage>}
                    </FormField>

                    {sections && (
                        <FormField label="Product Section" id="productSection" error={errors.productSection}>
                            <Select
                                required
                                id="product_section"
                                value={formData.product_section}
                                onChange={handleInputChange}
                            >
                                <option value="">Choose...</option>
                                {sections.map((section: SectionProps) => (
                                    <option
                                        value={section.product_section_name}
                                        key={section.id}
                                    >
                                        {section.product_section_name}
                                    </option>
                                ))}
                            </Select>
                            {errors.productSection && <ErrorMessage>{errors.productSection}</ErrorMessage>}
                        </FormField>
                    )}                   

                    <FormField label="Product Tags" id="subSection" error={errors.subSection}>
                        <Input
                            type="text"
                            id="sub_section"
                            placeholder="Add relevant keywords for your product to increase visibility in search results"
                            required
                            value={formData.sub_section}
                            onChange={handleInputChange}
                        />
                        {errors.subSection && <ErrorMessage>{errors.subSection}</ErrorMessage>}
                    </FormField>

                    <FormField label="Product Description" id="productDescription" error={errors.productDescription}>
                        <Textarea
                            required
                            id="product_description"
                            placeholder="Short Description"
                            value={formData.product_description}
                            onChange={handleInputChange}
                        />
                        {errors.productDescription && <ErrorMessage>{errors.productDescription}</ErrorMessage>}
                    </FormField>

                    <FormField label="Product Images" id="productImages" error={errors.productImages}>
                        <Input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageUpload}
                            disabled={formData.existing_images.length + formData.new_images.length >= 5}
                        />
                        <ImagePreview>
                            {imagePreviews.map((url, index) => (
                                <div key={index} style={{ position: 'relative' }}>
                                    <PreviewImage src={url} alt={`Preview ${index + 1}`} />
                                    <button
                                        type="button"
                                        onClick={() => handleImageDelete(index)} 
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            background: 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            padding: '5px',
                                        }}
                                    >
                                        &times; {/* Delete button */}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleSetMainImage(index)} 
                                        style={{
                                            position: 'absolute',
                                            bottom: '5px',
                                            left: '5px',
                                            background: formData.main_image_index === index ? 'green' : 'grey', // Change color based on selection
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            padding: '5px',
                                        }}
                                    >
                                        Main {/* Set as main image button */}
                                    </button>
                                </div>
                            ))}
                        </ImagePreview>
                    </FormField>

                    <SubmitButton type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </SubmitButton>
                </Form>
            </PageContainer>
        </>
    );
};

export default EditProduct;

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,...");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Textarea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ImagePreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const SubmitButton = styled.button`
  padding: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #357abd;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.2rem;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;