import React from 'react';

interface PriceResetProps {
    minPrice: number | null;
    maxPrice: number | null;
    searchKeyword: string;
    handleResetMinPrice: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    handleResetMaxPrice: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    handleResetSearch: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    handleResetAll: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const PriceReset: React.FC<PriceResetProps> = ({
    minPrice,
    maxPrice,
    searchKeyword,
    handleResetMinPrice,
    handleResetMaxPrice,
    handleResetSearch,
    handleResetAll,
}) => {
    return (
        <div className="filter-reset-tag-container">
            {/* Min Price Tag */}
            {minPrice !== null && (
                <div className="filter-reset-tag">
                    <div className="left">
                        <a href="#" onClick={handleResetMinPrice}>
                            <i className="icon-close"></i>
                        </a>
                    </div>
                    <div className="right">
                        <span className="reset-tag-title">Price(¢)</span>
                        <span>Min: {minPrice}</span>
                    </div>
                </div>
            )}

            {/* Max Price Tag */}
            {maxPrice !== null && (
                <div className="filter-reset-tag">
                    <div className="left">
                        <a href="#" onClick={handleResetMaxPrice}>
                            <i className="icon-close"></i>
                        </a>
                    </div>
                    <div className="right">
                        <span className="reset-tag-title">Price(¢)</span>
                        <span>Max: {maxPrice}</span>
                    </div>
                </div>
            )}

            {/* Search Keyword Tag */}
            {searchKeyword && handleResetSearch && (
                <div className="filter-reset-tag">
                    <div className="left">
                        <a href="#" onClick={handleResetSearch}>
                            <i className="icon-close"></i>
                        </a>
                    </div>
                    <div className="right">
                        <span className="reset-tag-title">Search</span>
                        <span>{searchKeyword}</span>
                    </div>
                </div>
            )}

            {/* Reset Filters Link */}
            {(minPrice !== null || maxPrice !== null || searchKeyword) && (
                <a href="#" className="link-reset-filters" onClick={handleResetAll}>
                    Reset Filters
                </a>
            )}
        </div>
    );
};

export default PriceReset;