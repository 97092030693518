import React from "react";
import { ProductProps, ProductFormData, CategoryProps, SectionProps } from "../../types/Types";
import { CancelButton } from "../../utils/ModalStyles";
import ModalWrapper from "./ModalWrapper";
import EditProduct from "../products/EditProduct";

const mapProductToFormData = (product: ProductProps): ProductFormData => {
    return {
        product_id: product.id,
        product_name: product.product_name, 
        product_mode: product.product_mode,
        product_quantity: product.product_quantity,
        product_price: product.product_price,
        product_category: product.product_category,
        sub_category: product.sub_category,
        product_section: product.product_section,
        sub_section: product.sub_section,
        product_description: product.product_description,
        existing_images: product.product_images, // URLs from the database
        main_image: product.main_image, // URLs from the database
        new_images: [], // Initialize as empty
    };
};

interface EditProductModalProps {
    onClose: () => void;
    isOpen: boolean;
    product: ProductProps;
    categories: CategoryProps[];
    sections: SectionProps[];
}

const EditProductModal: React.FC<EditProductModalProps> = ({ isOpen, onClose, product, categories, sections }) => {
    const initialData = mapProductToFormData(product);

    return (
        <>
            <ModalWrapper isOpen={isOpen} onClose={onClose}>
                <EditProduct 
                    categories={categories} 
                    sections={sections} 
                    initialData={initialData}
                />         
                <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
            </ModalWrapper>
        </>
    );
};
  
export default EditProductModal;