import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import HeaderCopy from "../../../components/header/HeaderCopy";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

const Help = () => {
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Help Center", to: "/help-center" },
        { label: "FAQ" },
    ];

    // State to track the currently expanded FAQ item
    const [expandedFAQ, setExpandedFAQ] = useState<number | null>(null);

    // FAQ data
    const faqs = [
        {
            question: "What types of products does New Market offer?",
            answer: `New Market offers a wide range of products, including home and kitchen appliances, 
                clothing, footwear, accessories for men, women, and unisex options, as well as electronics and gadgets. 
                You can explore our products <Link to="/products"><button>here</button></Link>.`,
        },
        {
            question: "How can I contact customer support?",
            answer: "You can reach our customer support team via WhatsApp: +233542059074, email: fgyamfi15@gmail.com, or through the contact page.",
        },
    ];

    // Toggle function to expand/collapse FAQ
    const toggleFAQ = (index: number) => {
        setExpandedFAQ(expandedFAQ === index ? null : index);
    };

    return (
        <>
            <HeaderCopy />

            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="support">
                                <Breadcrumb items={breadcrumbItems} />

                                <h1 className="title-category">FAQ</h1>
                                <div className="row">
                                    {/* Sidebar with FAQ links */}
                                    <div className="col-md-3 hide-mobile">
                                        <div className="all-help-topics">
                                            <Link to="/help-center">
                                                <i className="icon-angle-left"></i>
                                                &nbsp;All Help Topics
                                            </Link>
                                        </div>
                                        <ul className="ul-support-articles">
                                            {faqs.map((faq, index) => (
                                                <li key={index}>
                                                    <button
                                                        onClick={() => toggleFAQ(index)}
                                                        className={`faq-toggle ${expandedFAQ === index ? "active" : ""}`}
                                                    >
                                                        {faq.question}
                                                    </button>
                                                    {expandedFAQ === index && (
                                                        <p className="faq-answer">{faq.answer}</p>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    
                                    <div className="col-sm-12 col-lg-9">
                                        <div className="help-center-collapse">
                                            <a 
                                                href="#related_help_topics" 
                                                data-toggle="collapse" 
                                                className="collapse-title"
                                            >
                                                Related Help Topics                                    
                                                <div className="float-right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                                                    </svg>
                                                </div>
                                            </a>
                                            <div id="related_help_topics" className="collapse">
                                                <ul className="ul-support-articles">
                                                    {faqs.map((faq, index) => (
                                                        <li key={index}>
                                                            <button
                                                                onClick={() => toggleFAQ(index)}
                                                                className={`faq-toggle ${expandedFAQ === index ? "active" : ""}`}
                                                            >
                                                                {faq.question}
                                                            </button>
                                                            {expandedFAQ === index && (
                                                                <p className="faq-answer">{faq.answer}</p>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* FAQ Content */}
                                        <h2 className="article-title">
                                            {expandedFAQ !== null ? faqs[expandedFAQ].question : "Select a question to view the answer"}
                                        </h2>
                                        <div className="article-content">
                                            {expandedFAQ !== null && <p>{faqs[expandedFAQ].answer}</p>}
                                        </div>

                                        <div className="need-more-help need-more-help-article">
                                            <h3 className="title">Still have questions?</h3>
                                            <span className="text-muted">If you still have a question, you can submit a support request here.</span>
                                            <Link to="/contact" className="btn btn-lg btn-custom">
                                                <svg width="14" height="14" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                                                    <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                                                </svg>
                                                &nbsp;&nbsp;Contact Support
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
            <Footer />
        </>
    );
};

export default Help;