import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Icon from "../icon/Icon";

interface WishlistButtonProps {
    productId: number;
}

interface Heart {
    id: number;
    style: React.CSSProperties;
}

const WishlistButton: React.FC<WishlistButtonProps> = ({ productId }) => {
    const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hearts, setHearts] = useState<Heart[]>([]);

     // Get user session id from localStorage
    const getUserSessionId = (): string => {
        let userSessionId = localStorage.getItem('userSessionId');
        if (!userSessionId) {
            userSessionId = uuidv4();
            localStorage.setItem('userSessionId', userSessionId);
        }
        return userSessionId;
    };

    // Check if item is in wishlist table
    useEffect(() => {
        const userSessionId = getUserSessionId();

        const checkWishlistStatus = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_Base_url}/shoppers/check_wishlist/${userSessionId}/${productId}`
                );
                setIsAddedToWishlist(response.data.isInWishlist);
            } catch (error) {
                console.error('Error checking wishlist status:', error);
            }
        };

        checkWishlistStatus();
    }, [productId]);

    // Handles addition and removal of items in wishlist
    const handleToggleWishlist = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (isLoading) return;

            setIsLoading(true);
            const userSessionId = getUserSessionId();

            try {
                if (isAddedToWishlist) {
                    // Remove from wishlist
                    await axios.post(`${process.env.REACT_APP_Base_url}/products/remove_from_wishlist`, {
                        user_session_id: userSessionId,
                        product_id: productId,
                    });
                    setIsAddedToWishlist(false);
                } else {
                    // Add to wishlist
                    await axios.post(`${process.env.REACT_APP_Base_url}/products/add_to_wishlist`, {
                        user_session_id: userSessionId,
                        product_id: productId,
                    });
                    setIsAddedToWishlist(true);

                    // Generate floating hearts
                    const newHearts = Array(5)
                        .fill(null)
                        .map((_, i) => ({
                            id: Date.now() + i,
                            style: {
                                left: `${Math.random() * 60 - 30}px`,
                                top: `${Math.random() * -60 - 30}px`,
                                animationDuration: `${Math.random() * 1 + 1}s`,
                            },
                        }));
                    setHearts(newHearts);

                    // Remove hearts after animation
                    setTimeout(() => setHearts([]), 2000);
                }
            } catch (error) {
                console.error('Error toggling wishlist item:', error);
            } finally {
                setIsLoading(false);
            }
        },
        [isLoading, isAddedToWishlist, productId]
    );

    return (
        <div>
            <a
                href="#"
                role="button"
                onClick={(e) => {
                    if (isLoading) {
                        e.preventDefault(); // Prevent default behavior
                    } else {
                        handleToggleWishlist(e); // Trigger the action
                    }
                }}
                className="tooltip-container"
            >
                {!isLoading && (
                    isAddedToWishlist ? (
                        <Icon name="IoHeartSharp" size="1.5em" style={{ color: 'red' }} />
                    ) : (
                        <Icon name="IoHeartOutline" size="1.5em" style={{ color: 'inherit' }} />
                    )
                )}
                <div className="tooltip">
                    <span className="tooltip-text">
                        {isAddedToWishlist ? 'Added to Wishlist' : 'Wishlist'}
                    </span>
                    <span className="tooltip-arrow"></span>
                </div>
                {isLoading && <span className="loading-spinner"></span>}
            </a>
            
            {hearts.map((heart) => (
                <div
                    key={heart.id}
                    className="position-absolute text-danger animate-float-away"
                    style={heart.style}
                >
                    <Icon name="IoHeartSharp" size="0.8em" />
                </div>
            ))}
        </div>
    );
};

export default WishlistButton;