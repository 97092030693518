import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from '../../components/icon/Icon';
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

const ProfileCompletion = () => {
    const role = sessionStorage.getItem("role");
    const token = role === "seller" ? sessionStorage.getItem("seller_token") : sessionStorage.getItem("shopper_token");

    // Shopper-specific fields
    const [location, setLocation] = useState('');
    const [contact, setContact] = useState('');

    // Seller-specific fields
    const [companyName, setCompanyName] = useState('');
    const [businessCategory, setBusinessCategory] = useState('');
    const [shopDescription, setShopDescription] = useState('');

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formVisible, setFormVisible] = useState(true); // To control form visibility after submission
    
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Complete Your Profile" },
    ];

    // Handle form submission to update shop details
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

         // Validate fields
         if (role === "seller" && (!companyName || !location || !shopDescription || !businessCategory || !contact)) {
            setErrorMessage("All fields are required for sellers.");
            setLoading(false);
            return;
        }
        if (role === "shopper" && (!location || !contact)) {
            setErrorMessage("All fields are required for shoppers.");
            setLoading(false);
            return;
        }

        // Prepare data for submission
        const payload =
            role === "seller"
                ? { company_name: companyName, location, shop_description: shopDescription, business_category: businessCategory, contact }
                : { location, contact };

        try {
            const endpoint = role === "seller" ? "/sellers/update_shop" : "/shoppers/update";
            const response = await axios.post(
                `${process.env.REACT_APP_Base_url}${endpoint}`,
                payload,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                // Update sessionStorage with the new status
                const newStatus =
                    role === "seller"
                        ? response.data.updatedShop?.status
                        : response.data.updatedUser?.status;

                if (newStatus) {
                    sessionStorage.setItem('status', newStatus);
                    setSuccessMessage("Your profile has been completed successfully!");
                    setFormVisible(false); // Hide the form after successful submission
                } else {
                    setErrorMessage("Unexpected response structure. Please contact support.");
                }
            }
        } catch (error) {
            // If the backend sends a custom error message, display it
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("Failed to update profile. Please try again."); // Generic fallback
            }
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                    </div>
                    <div className="auth-container">
                        <div className="auth-box">
                            <div className="row">
                                <div className="col-12">          
                                    {formVisible ? (
                                        <form onSubmit={handleSubmit} className="validate_terms"> 
                                            <h1 className="title">Complete Your Profile</h1>
                                            <div id="result-register">
                                                <div className="m-b-15">
                                                    {/* Show error message only */}
                                                    {errorMessage && (
                                                        <div className="alert alert-danger alert-message">
                                                            <p style={{ color: 'red' }}>{errorMessage}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {role === "seller" && (
                                                <>
                                                    <div className="form-group">
                                                        <input 
                                                            type="text" 
                                                            name="companyName" 
                                                            className="form-control auth-form-input" 
                                                            placeholder="What's the name of your business?" 
                                                            value={companyName}
                                                            onChange={(e) => setCompanyName(e.target.value)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="form-group">                                             
                                                        <select
                                                            name="businessCategory"
                                                            className="form-control auth-form-input"
                                                            placeholder="Which category does your business fall?" 
                                                            required
                                                            value={businessCategory}
                                                            onChange={(e) => setBusinessCategory(e.target.value)}
                                                        >
                                                            <option value="">Choose...</option>
                                                            <option value="General Goods">General Goods</option>
                                                            <option value="Consumer Electronics">Consumer Electronics</option>
                                                            <option value="Fashion & Apparel">Fashion & Apparel</option>
                                                            <option value="Beauty & Personal Care">Beauty & Personal Care</option>
                                                            <option value="Entertainment">Entertainment</option>
                                                            <option value="Household Goods">Household Goods</option>
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <textarea
                                                            name="shopDescription" 
                                                            className="form-control auth-form-input"
                                                            placeholder="Tell us about your business."
                                                            value={shopDescription}
                                                            onChange={(e) => setShopDescription(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </>
                                            )}
                                                
                                            <div className="form-group">
                                                <input 
                                                    type="text" 
                                                    name="location" 
                                                    className="form-control auth-form-input" 
                                                    placeholder="Location" 
                                                    value={location} 
                                                    onChange={(e) => setLocation(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            
                                            <div className="form-group">
                                                <input 
                                                    type="tel" 
                                                    name="contact"
                                                    pattern="^0\d{9}$" 
                                                    className="form-control auth-form-input" 
                                                    placeholder="Enter your mobile number."
                                                    value={contact} 
                                                    onChange={(e) => setContact(e.target.value)}
                                                    required
                                                />
                                            </div>
                                                
                                            <div className="form-group">
                                                <button className="btn btn-custom btn-block" type="submit" disabled={loading}>
                                                    {loading ? "Submitting..." : "Complete Profile"}
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <div className="m-b-15">
                                            {role === "shopper" && (
                                                <>
                                                    <h2 className="title" style={{ color: 'green' }}>
                                                        {successMessage}
                                                    </h2>    
                                                    {/* Shop now button */}
                                                    <Link to="/products" className="btn btn-md btn-custom btn-block m-t-30">
                                                        <Icon name="BiChevronLeft" /> &nbsp;Shop Now
                                                    </Link>
                                                </>
                                            )}

                                            {/* Verification message for sellers */}
                                            {role === "seller" && (
                                                <>
                                                    <h2 className="title" style={{ color: 'green' }}>
                                                        Your shop opening request has been successfully submitted!
                                                    </h2>
                                                    <div className="alert alert-info">
                                                        <p>
                                                            In order to sell your products on this platform, you need to complete a one-time verification process. 
                                                            This is to protect against fraud and spam. During the process, you may be contacted by our team for a few questions.
                                                        </p>
                                                    </div>
                                                    {/* Go to homepage button */}
                                                    <Link to="/" className="btn btn-md btn-custom btn-block m-t-30">
                                                        Go to the Homepage
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default ProfileCompletion;