import "./category.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { PuffLoader } from 'react-spinners';
import Slider from "react-slick";

// types
import { SectionProps, ProductProps } from "../../types/Types";

// Components
import ProductDisplay from "../products/ProductDisplay";
import Icon from "../icon/Icon";

// Helper function to calculate items per page based on viewport
const calculateItemsPerPage = () => {
    const width = window.innerWidth; // Get viewport width  
    if (width >= 992) return 4; // Large devices (lg)
    if (width >= 768) return 3; // Medium devices (md)
    return 2; // Small devices and below (sm)
};

const Sections = () => {
    const [sections, setSections] = useState<SectionProps[]>([]);
    const [itemSection, setItemSection] = useState<string | null>("Savings Spotlight");
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [skeletonCount, setSkeletonCount] = useState<number>(0);
    const sliderRef = useRef<typeof Slider>(null);

    const goToNext = () => sliderRef.current?.slickNext(); // Trigger next slide

    const goToPrev = () => sliderRef.current?.slickPrev(); // Trigger previous slide

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        lazyLoad: "ondemand",
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 15000,
        pauseOnHover: true,
        draggable: true,
        responsive: [
            { breakpoint: 992, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 3 } },
            { breakpoint: 576, settings: { slidesToShow: 2 } },
        ],
    };

    useEffect(() => {
        const handleResize = () => setSkeletonCount(calculateItemsPerPage());
        window.addEventListener("resize", handleResize);
        handleResize(); // Initial calculation

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // calling the product_section api
    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
        .then((res) => {
            if (res.data.status === 200) {
            setSections(res.data.product_sections);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    // calling the  active products by section api
    const fetchProductsBySection = useCallback(async () => {
        if (!itemSection) {
            setProducts([]);
            setSkeletonCount(0);
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Base_url}/utils/get_newest_products_by_section`,
                { params: { product_section: itemSection } }
            );

            if (response.data.status === 200) {
                setProducts(response.data.products);
                setSkeletonCount(calculateItemsPerPage());
            } else {
                setProducts([]);
                setSkeletonCount(0);
                setError("No products found.");
            }
        } catch (error) {
            console.error("Error fetching products:", error);
            setError("Failed to fetch products. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [itemSection]);

    useEffect(() => {
        fetchProductsBySection();
    }, [fetchProductsBySection]);

    // handle section dropdown and select
    const handleSectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemSection(event.target.value);
    };

    return (
        <>
            <div className="col-12 section section-category-products" style={{ marginBottom: '0' }}>
                <div className="section-header section-header-slider">
                    <div className="bycats">
                        <div className="container">
                            <div className="wrap">
                                <div className="heading sort-list tabs">
                                    <span className="grey-color">in</span>
                                    <div className="wrap">
                                        <div className="custom-arrow">
                                            {loading ? (
                                                <div style={loaderContainerStyle}>
                                                    <PuffLoader size={20} color="#000" />
                                                </div>
                                            ) : sections.length > 0 ? (
                                                <select
                                                    id="sectionsDropdown"
                                                    className="custom-dropdown"
                                                    onChange={handleSectionChange}
                                                    value={itemSection || ""}
                                                >
                                                    <option value="" disabled>
                                                        Select a section
                                                    </option>
                                                    {sections.map((section: SectionProps) => (
                                                        <option key={section.id} value={section.product_section_name}>
                                                            {section.product_section_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <p>No sections available. 
                                                    <button 
                                                        onClick={fetchProductsBySection}
                                                    >
                                                        Retry
                                                    </button>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-slider-nav" id="category-products-slider-nav-32">
                        <button 
                            className="prev slick-arrow" 
                            aria-label="Go to previous slide" 
                            onClick={goToPrev}
                        >
                            <i className="icon-arrow-left"></i>
                        </button>
                        <button 
                            className="next slick-arrow" 
                            aria-label="Go to next slide" 
                            onClick={goToNext}
                        >
                            <i className="icon-arrow-right"></i>
                        </button>
                    </div>
                </div>

                {/* Products display */}
                <ProductDisplay
                    products={products}
                    loading={loading}
                    error={error}
                    skeletonCount={skeletonCount}
                    fetchProducts={fetchProductsBySection}
                    setError={setError}
                    renderSlider={true} // Enable slider rendering
                    sliderSettings={settings}
                    sliderRef={sliderRef}
                />
            </div>                                     
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
};

export default Sections;