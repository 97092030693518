import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { messageService } from '../../../services/messageService';
import { ChatListItem, Message as MessageType } from '../../../types/Types';
import styled from 'styled-components';
import Icon from "../../../components/icon/Icon";
import Footer from "../../../components/footer/Footer";
import HeaderCopy from "../../../components/header/HeaderCopy";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";

const Chat = () => {
    const [messages, setMessages] = useState<MessageType[]>([]);
    const [messageText, setMessageText] = useState('');
    const [chatList, setChatList] = useState<ChatListItem[]>([]); // List of chats
    const [selectedChat, setSelectedChat] = useState<ChatListItem | null>(null); // Selected chat
    const [chatSearchTerm, setChatSearchTerm] = useState<string>('');
    const [messageSearchTerm, setMessageSearchTerm] = useState<string>('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Messages" },
    ];

    const getUserRole = (): string => {
        // Assuming roles are stored in session storage
        return sessionStorage.getItem('role') || 'guest'; // Default to 'guest' if no role is found
    };

    // Handle window resize
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch chat list on component load
    useEffect(() => {
        const fetchChatList = async () => {
            try {
                const chatList = await messageService.getChatList();
                setChatList(chatList);
            } catch (error) {
                console.error('Error fetching chat list:', error);
            }
        };
        fetchChatList();
    }, []);

    // Fetch messages when a chat is selected
    useEffect(() => {
        if (!selectedChat) return;

        const fetchMessages = async () => {
            try {
                const messages = await messageService.getMessages(selectedChat.role_id);
                setMessages(messages);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };
        fetchMessages();
    }, [selectedChat]);

    // Send message to selected chat
    const sendMessage = async () => {
        if (!selectedChat || !messageText.trim()) return;
    
        try {
            const newMessage = await messageService.sendMessage(selectedChat.role_id, messageText);
            setMessages(prev => [...prev, newMessage]);
            setMessageText('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    // Filter chats based on search term
    const filteredChats = chatList.filter(chat => 
        (chat.name || '').toLowerCase().includes((chatSearchTerm || '').toLowerCase())
    );
    
    const filteredMessages = messages.filter(message =>
        (message.message_text || '').toLowerCase().includes((messageSearchTerm || '').toLowerCase())
    );

    const highlightText = (text: string, searchTerm: string) => {
        if (!searchTerm) return text;
        
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, i) => 
            part.toLowerCase() === searchTerm.toLowerCase() ? 
                <HighlightedText key={i}>{part}</HighlightedText> : part
        );
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper" style={{ display: 'flex' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                    </div>
                
                <ChatContainer>
                    <ChatListContainer show={!isMobile || (isMobile && !selectedChat)}>
                        <ChatListHeader>Messages</ChatListHeader>
                        <SearchContainer>
                                <SearchWrapper>
                                    <SearchIcon><Icon name="CiSearch" /></SearchIcon>
                                    <SearchInput
                                        type="text"
                                        placeholder="Search chats..."
                                        value={chatSearchTerm}
                                        onChange={(e) => setChatSearchTerm(e.target.value)}
                                    />
                                </SearchWrapper>
                        </SearchContainer>
                        {filteredChats.length === 0 ? (
                                <NoChats>{chatSearchTerm ? "No matching chats found" : "No chats available"}</NoChats>
                        ) : (
                                filteredChats.map((chat) => (
                                    <ChatItem
                                        key={chat.role_id}
                                        onClick={() => setSelectedChat(chat)}
                                        active={selectedChat?.role_id === chat.role_id}
                                    >
                                        <ProfileWrapper>
                                            <ProfileImage src={chat.profile_picture} alt={chat.name} />
                                            <OnlineStatus isOnline={chat.is_online} />
                                        </ProfileWrapper>
                                        <ChatInfo>
                                            <ChatName>{chat.name}</ChatName>
                                            <LastMessage>Last message preview...</LastMessage>
                                        </ChatInfo>
                                    </ChatItem>
                                ))
                        )}
                    </ChatListContainer>

                    <ChatContent show={!isMobile || (isMobile && !!selectedChat)}>
                            {selectedChat ? (
                                <>
                                    <Header>
                                        {isMobile && (
                                            <BackButton onClick={() => setSelectedChat(null)}>
                                                <Icon name="IoArrowBack" />
                                            </BackButton>
                                        )}
                                        <UserDetails>
                                            <ProfileWrapper>
                                                <ProfileImage src={selectedChat.profile_picture} alt={selectedChat.name} />
                                                <OnlineStatus isOnline={selectedChat.is_online} />
                                            </ProfileWrapper>
                                            <UserInfo>
                                                <UserName>{selectedChat.name}</UserName>
                                                <LastSeen>{selectedChat.is_online ? 'Online' : `Last seen ${selectedChat.last_seen}`}</LastSeen>
                                            </UserInfo>
                                        </UserDetails>
                                    </Header>
                                    <SearchContainer>
                                        <SearchWrapper>
                                            <SearchIcon><Icon name="CiSearch" /></SearchIcon>
                                            <SearchInput
                                                type="text"
                                                placeholder="Search in messages..."
                                                value={messageSearchTerm}
                                                onChange={(e) => setMessageSearchTerm(e.target.value)}
                                            />
                                        </SearchWrapper>
                                    </SearchContainer>
                                    <MessageArea>
                                        {filteredMessages.length === 0 ? (
                                            <NoMessages>
                                                {messageSearchTerm ? "No matching messages found" : "No messages in this chat!"}
                                            </NoMessages>
                                        ) : (
                                            filteredMessages.map((msg) => (
                                                <MessageBubble
                                                    key={msg.id}
                                                    isSender={msg.sender_role === getUserRole()}
                                                >
                                                    <MessageText>
                                                        {highlightText(msg.message_text, messageSearchTerm)}
                                                    </MessageText>
                                                    <MessageTime>{msg.timestamp}</MessageTime>
                                                </MessageBubble>
                                            ))
                                        )}
                                    </MessageArea>
                                    <InputArea>
                                        <TextInput
                                            value={messageText}
                                            onChange={(e) => setMessageText(e.target.value)}
                                            placeholder="Type a message..."
                                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                        />
                                        <SendButton onClick={sendMessage} disabled={!messageText.trim()}>
                                            Send
                                        </SendButton>
                                    </InputArea>
                                </>
                            ) : (
                                !isMobile && <NoChatSelected>Select a chat to start messaging</NoChatSelected>
                            )}
                    </ChatContent>
                </ChatContainer>
                
                    
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Chat;

// Styled Components
const ChatContainer = styled.div`
    display: flex;
    height: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
`;

const ChatListContainer = styled.div<{ show: boolean }>`
    width: 300px;
    border-right: 1px solid #e0e0e0;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ChatListHeader = styled.div`
    padding: 20px;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
`;

const ChatContent = styled.div<{ show: boolean }>`
    flex: 1;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column;
`;

const ChatInfo = styled.div`
    flex: 1;
    min-width: 0;
`;

const ChatItem = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background: ${props => props.active ? '#f0f2f5' : 'transparent'};
    border-bottom: 1px solid #e0e0e0;
    
    &:hover {
        background: #f5f5f5;
    }
`;

const ProfileImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
`;

const ChatName = styled.div`
    font-weight: 500;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LastMessage = styled.div`
    color: #666;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    background: #f0f2f5;
`;

const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #666;
    
    &:hover {
        color: #000;
    }
`;

const UserDetails = styled.div`
    display: flex;
    align-items: center;
`;

const UserName = styled.div`
    font-weight: 500;
    margin-left: 10px;
`;

const MessageArea = styled.div`
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background: #e5ddd5;
    display: flex;
    flex-direction: column;
`;

const MessageBubble = styled.div<{ isSender: boolean }>`
    max-width: 60%;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 10px;
    align-self: ${props => props.isSender ? 'flex-end' : 'flex-start'};
    background: ${props => props.isSender ? '#dcf8c6' : '#fff'};
`;

const MessageText = styled.div`
    word-wrap: break-word;
`;

const InputArea = styled.div`
    display: flex;
    padding: 15px;
    background: #f0f2f5;
    border-top: 1px solid #e0e0e0;
`;

const TextInput = styled.input`
    flex: 1;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 15px;
    
    &:focus {
        outline: none;
        border-color: #128c7e;
    }
`;

const SendButton = styled.button`
    padding: 10px 20px;
    background: #128c7e;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    
    &:disabled {
        background: #cccccc;
        cursor: not-allowed;
    }
    
    &:hover:not(:disabled) {
        background: #075e54;
    }
`;

const NoChats = styled.div`
    padding: 20px;
    text-align: center;
    color: #666;
`;

const NoMessages = styled.div`
    text-align: center;
    color: #666;
    margin-top: 20px;
`;

const NoChatSelected = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 1.2em;
`;

const SearchContainer = styled.div`
    padding: 8px 16px;
    background-color: #f0f2f5;
    border-bottom: 1px solid #e0e0e0;
`;

const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 6px 12px;
`;

const SearchIcon = styled.div`
    display: flex;
    align-items: center;
    color: #666;
    margin-right: 8px;
    font-size: 1.2em;
`;

const SearchInput = styled.input`
    flex: 1;
    border: none;
    padding: 8px 0;
    font-size: 15px;
    background: transparent;
    
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #999;
    }
`;

const OnlineStatus = styled.div<{ isOnline: boolean }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${props => props.isOnline ? '#44b700' : '#ccc'};
    border: 2px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
`;

const ProfileWrapper = styled.div`
    position: relative;
    margin-right: 15px;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const LastSeen = styled.div`
    font-size: 0.8em;
    color: #666;
`;

const MessageTime = styled.div`
    font-size: 0.7em;
    color: #666;
    margin-top: 4px;
    text-align: right;
`;

const HighlightedText = styled.span`
    background-color: #fff3cd;
    padding: 0 2px;
    border-radius: 2px;
`;