import React, { useEffect, useState, ChangeEvent } from "react";
import axios from 'axios';
import { RingLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from '../../components/icon/Icon';
import ErrorAlert from "../../components/error alert/ErrorAlert";
import useDebounce from '../../hooks/useDebounce';

interface Shop {
    seller_id: number;
    company_name: string;
    profile_picture: string;
    product_count: number;
    id: number;
}

const Shops: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [shops, setShops] = useState<Shop[]>([]);  // Specify that `shops` is an array of `Shop`
    const [searchQuery, setSearchQuery] = useState<string>('');  // Specify that searchQuery is a string
    const [currentPage, setCurrentPage] = useState<number>(1);  // Track current page
    const [totalPages, setTotalPages] = useState<number>(1);    // Track total pages
    const [error, setError] = useState<string | null>(null);
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Shops" },
    ];

    const fetchShops = async (page: number = 1) => {
        setLoading(true);
    
        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/all_shops?q=${debouncedSearchQuery}&page=${page}&limit=32`);
          
            setShops(response.data.shops);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);
        } catch (error) {
            console.error("Error fetching shops:", error);
            setError('Error fetching shops. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchShops(currentPage);
    }, [debouncedSearchQuery, currentPage]);
    
    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
          setCurrentPage(page);
        }
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb items={breadcrumbItems} />
                            
                            <div className="row m-b-15">
                                <div className="col-6">
                                    <h1 className="page-title">Shops</h1>
                                </div>
                                <div className="col-6">
                                    <div className="shops-search-container">
                                        <div className="search">
                                            <form action="#">
                                                <input 
                                                    type="text" 
                                                    name="q" 
                                                    className="form-control form-input" 
                                                    placeholder="Search for a shop"
                                                    value={searchQuery}
                                                    onChange={(e) => {
                                                        setSearchQuery(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                />
                                                <button type="submit">
                                                    <Icon name="CiSearch" />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {loading ? (
                                <div style={loaderContainerStyle}>
                                    <RingLoader color="var(--vr-color-main)" loading={loading} />
                                </div>
                            ) : error ? (
                                <ErrorAlert 
                                    error={error} 
                                    fetchProducts={fetchShops} 
                                    setError={setError} 
                                />
                            ) : !shops || shops.length === 0 ? ( // Handle undefined or empty array
                                <div className="col-12">
                                    <p className="no-records-found">No shops found!</p>
                                </div>
                            ) : (
                                <div className="row">
                                    {shops.map(shop => (
                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={shop.seller_id}>
                                            <div className="member-list-item">
                                                <div className="left">
                                                    <Link 
                                                        to="/profile/products"
                                                        state={{ sellerId: shop.seller_id }}
                                                    >
                                                        <img 
                                                            src={shop.profile_picture} 
                                                            alt={`${shop.company_name} logo`}
                                                            className="img-fluid img-profile ls-is-cached lazyloaded"
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="right">
                                                    <Link 
                                                        to="/profile"
                                                        state={{ sellerId: shop.seller_id }}
                                                    >
                                                        <p className="username">{shop.company_name}</p>
                                                    </Link>
                                                    <p className="text-muted m-b-10">Products: {shop.product_count}</p>
                                                    <p>
                                                        <button className="btn btn-md btn-outline" data-toggle="modal" data-target="#loginModal">
                                                            <Icon name="FaUserPlus" style={{ marginRight: '8px', position: 'relative', top: '-2px' }} />
                                                            Follow
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> 
                                    ))}
                                </div>
                            )}                     
                           {/* Pagination */}
                            <div className="col-12 m-t-15">
                                <div className="float-right">
                                    <div className="pagination-buttons-container">
                                        <button
                                            className="pagination-button" 
                                            disabled={currentPage === 1} 
                                            onClick={() => handlePageChange(currentPage - 1)}
                                        >
                                            <Icon name="BiChevronLeft" /> Prev
                                        </button>
                                        <span>{currentPage} of {totalPages}</span>
                                        <button
                                            className="pagination-button" 
                                            disabled={currentPage === totalPages} 
                                            onClick={() => handlePageChange(currentPage + 1)}
                                        >
                                            Next <Icon name="BiChevronRight" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};
    
export default Shops;