import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { PropagateLoader } from "react-spinners";
import WishlistButton from "../../../../components/wishlist button/WishlistButton";
import WishlistTotal from "../../../../components/wishlist button/WishlistTotal";
import Stars from '../../../../components/stars/Stars';
import Icon from '../../../../components/icon/Icon';
import PriceFormatter from '../../../../components/product filter/PriceFormatter';


const SellerWishlistProducts = ({ wishlistProducts, totalProducts, loading, pageSize, wishlistPage, setWishlistPage }) => {

    // Handle pagination
    const handleNextPage = () => {
        if (wishlistPage * pageSize < totalProducts) {
            setWishlistPage(wishlistPage + 1);
        }   
    };

    const handlePreviousPage = () => {
        if (wishlistPage > 1) {
            setWishlistPage(wishlistPage - 1);
        }
    };

    // Disable the "Next" button when there are no more products to show
    const isNextDisabled = wishlistPage * pageSize >= totalProducts;

    // render loading states
    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <PropagateLoader color="var(--vr-color-main)" loading={loading} />
            </div>
        );
    }

    return (
        <>
            <div className="row row-product-items row-product">
                {wishlistProducts.length === 0 ? (
                    <div className="col-12">
                        <p className="text-center text-muted">No products found!</p>
                    </div>
                ) : (        
                    wishlistProducts.map((product) => (
                        <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={product.id}>
                            <div className="product-item">
                                <div className="row-custom product-multiple-image">
                                    <div className="img-product-container">
                                        <Link to={`/details`} state={{ data: product.id }}>    
                                            <img 
                                                src={product.main_image} 
                                                alt={product.product_name} 
                                                className="img-fluid img-product ls-is-cached lazyloaded" 
                                                loading="lazy"
                                            />
                                            <div classNameName="thumbnail hover">
                                                <img 
                                                    src={product.main_image} 
                                                    alt={product.product_name} 
                                                    className="img-fluid img-product img-second ls-is-cached lazyloaded" 
                                                    loading="lazy"
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row-custom item-details">
                                    <h3 className="product-title">
                                        <Link to={`/details`} state={{ data: product.id }}>
                                            {product.product_name}
                                        </Link>
                                    </h3>
                                    <p className="product-user text-truncate">
                                        <a href="#">{product.company_name}</a>
                                    </p>
                                    <div className="product-item-rating">
                                        <div className="rating">
                                            <Stars productId={product.id} />
                                        </div> 
                                        <WishlistTotal productId={product.id} />
                                    </div>
                                    <div className="item-meta">
                                        {/*<del className="discount-original-price">
                                            <span>¢</span>270           
                                        </del>*/}
                                        <PriceFormatter price={product.product_price} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}

                {/* pagination */}
                <div className="col-12 m-t-15">
                    {wishlistProducts.length > 0 ? (
                        <div className="float-right">
                            <div className="pagination-buttons-container">
                                <button
                                    className="pagination-button"
                                    disabled={wishlistPage === 1 || loading}
                                    onClick={handlePreviousPage}
                                >
                                    <Icon name="BiChevronLeft" /> Prev
                                </button>
                                <button
                                    className="pagination-button"
                                    disabled={isNextDisabled || loading}
                                    onClick={handleNextPage}
                                >
                                    Next <Icon name="BiChevronRight" />
                                </button>
                            </div>
                            <div className="row-custom">
                                {/* User-friendly message when no more reviews */}
                                {isNextDisabled && <p>No more products in wishlists.</p>}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default SellerWishlistProducts;