import React from 'react';
import styled, { keyframes, css } from 'styled-components';

interface SkeletonListProps {
    count: number;
}

// Keyframe animation for the shimmer effect
const shimmer = keyframes`
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
`;

// Reusable shimmer style
const shimmerStyles = css`
    background: linear-gradient(
        to right,
        #f6f7f8 0%,
        #eaeaea 20%,
        #f6f7f8 40%,
        #f6f7f8 100%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 1.5s linear infinite;
`;

// Styled components for skeleton shimmer
const SkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SkeletonItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
`;

/*const SkeletonCircle = styled.div`
    width: 48px;
    height: 8px;
    border-radius: 50%;
    ${shimmerStyles}
`;*/

const SkeletonText = styled.div`
    height: 6px;
    width: 65%;
    border-radius: 4px;
    ${shimmerStyles}
`;

const SkeletonList: React.FC<SkeletonListProps> = ({ count }) => {
    return (
        <SkeletonContainer>
            {Array.from({ length: count }).map((_, index) => (
                <SkeletonItem key={index}>
                    {/*<SkeletonCircle />*/}
                    <SkeletonText />
                </SkeletonItem>
            ))}
        </SkeletonContainer>
    );
};

export default SkeletonList;