import React from 'react';
import Icon from "../icon/Icon";

interface AddToCartProps {
    className?: string;
}
const AddToCart: React.FC<AddToCartProps> = ({ className }) => {
    return (
        <>
            <a href="/cart" className={`tooltip-container ${className || ""}`}>
                <Icon name="IoCartOutline" size="1.5em" />
                <div className="tooltip">
                    <span className="tooltip-text">Add to Cart</span>
                    <span className="tooltip-arrow"></span>
                </div>
            </a>               
        </>
    );
};

export default AddToCart;