import React from 'react';
import { Link } from 'react-router-dom';

// types
import { ProductProps } from '../../types/Types';

// components
import Stars from '../stars/Stars';
import WishlistTotal from "../wishlist button/WishlistTotal";
import PriceFormatter from '../product filter/PriceFormatter';
import AddToCart from "../cart/AddToCart";
import WishlistButton from "../wishlist button/WishlistButton";
import ViewProductButton from "../misc/ViewProductButton";

interface ProductCardProps {
    product: ProductProps;
    className?: string; // Optional className for the outer div
}

const ProductCard: React.FC<ProductCardProps> = ({ product, className }) => {
    return (
        <div className={className || "col-6 col-sm-4 col-md-3 col-mds-5 col-product"}>
            <div className="product-item">
                <div className="row-custom product-multiple-image">
                    <div className="img-product-container">
                        <Link to={`/details`} state={{ productId: product.id }}>
                            <img
                                src={product.main_image}
                                alt={product.product_name}
                                className="img-fluid img-product"
                                loading="lazy"
                            />
                            <div className="thumbnail hover">
                                <img
                                    src={product.product_images[0]}
                                    alt={product.product_name}
                                    className="img-fluid img-product img-second"
                                    loading="lazy"
                                />
                            </div>
                        </Link>
                        <div className="actions">
                            <ul>
                                <li>
                                    <WishlistButton productId={product.id} />
                                </li>
                                <li>
                                    <AddToCart />
                                </li>
                                <ViewProductButton productId={product.id} />
                            </ul>
                        </div>
                        <div className="sub-actions">
                            <ul>
                                <li>
                                    <a className="item-option btn-add-remove-wishlist">
                                        <WishlistButton productId={product.id} />
                                    </a>
                                </li>
                                <li>
                                    <AddToCart className="item-option" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row-custom item-details">
                    <h3 className="product-title">
                        <Link to={`/details`} state={{ productId: product.id }}>
                            {product.product_name}
                        </Link>
                    </h3>
                    <p className="product-user text-truncate">
                        <Link to="/profile/products" state={{ sellerId: product.seller_id }}>
                            {product.company_name}
                        </Link>
                    </p>
                    <div className="product-item-rating">
                        <div className="rating">
                            <Stars productId={product.id} />
                        </div>
                        <WishlistTotal productId={product.id} />
                    </div>
                    <Link to={`/details`} state={{ productId: product.id }}>
                        <div className="item-meta">
                            <PriceFormatter price={product.product_price} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;