import axios from "axios";
import React, { useEffect, useState } from "react";
import Icon from "../icon/Icon";

interface WishlistTotalProps {
    productId: number; // Define the expected prop type
}

const WishlistTotal: React.FC<WishlistTotalProps> = ({ productId }) => {
    const [wishlistCount, setWishlistCount] = useState<number>(0);

    // Fetches wishlist products
    const fetchWishlistCount = async () => {
        try {
            const res = await axios.get<{ count: string }>(
                `${process.env.REACT_APP_Base_url}/products/count_wishlist/${productId}`
            );
            setWishlistCount(parseInt(res.data.count, 10)); // Ensure it's a number
        } catch (error) {
            console.error("Error fetching wishlist count:", error);
        }
    };

    useEffect(() => {
        fetchWishlistCount();
    }, [productId]);

    return (
        <span className="item-wishlist">
            <Icon
                name={wishlistCount > 0 ? "BsHeartFill" : "BsHeart"}
                style={{ marginRight: "5px" }}
            />
            {wishlistCount} {/* Always display the count */}
        </span>
    );
};

export default WishlistTotal;