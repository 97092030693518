import React from 'react';
import './Pagination.css';
import Icon from "../icon/Icon";

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
    if (totalPages <= 1) return null;

    // Helper to generate the dynamic range
    const getPageNumbers = () => {
        const delta = 2; // Number of pages to show on either side of the current page
        const range = [];
        const left = Math.max(1, currentPage - delta);
        const right = Math.min(totalPages, currentPage + delta);

        for (let i = left; i <= right; i++) {
            range.push(i);
        }

        // Add ellipses and boundaries if necessary
        if (left > 1) {
            if (left > 2) range.unshift('...');
            range.unshift(1);
        }
        if (right < totalPages) {
            if (right < totalPages - 1) range.push('...');
            range.push(totalPages);
        }

        return range;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="modern-pagination">
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                    {/* Previous Button */}
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                            aria-label="Previous"
                        >
                            <Icon name="BiChevronLeft" />
                        </button>
                    </li>

                    {/* Dynamic Page Numbers */}
                    {pageNumbers.map((page, index) =>
                        page === '...' ? (
                            <li key={index} className="page-item disabled">
                                <span className="page-link">...</span>
                            </li>
                        ) : (
                            <li
                                key={page}
                                className={`page-item ${currentPage === page ? 'active' : ''}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => onPageChange(page as number)}
                                >
                                    {page}
                                </button>
                            </li>
                        )
                    )}

                    {/* Next Button */}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button
                            className="page-link"
                            onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
                            aria-label="Next"
                        >
                            <Icon name="BiChevronRight" />
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Pagination;