import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';

// types
import { ProductProps } from '../../types/Types';

// components
import ProductCardThrift from './ProductCardThrift';
import SkeletonShimmer from '../loader/SkeletonShimmer';
import ErrorAlert from "../error alert/ErrorAlert";

interface ProductDisplayThriftProps {
    products: ProductProps[];
    loading: boolean;
    error: string | null;
    skeletonCount: number;
    fetchProducts: () => void;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    renderSlider?: boolean; // New prop to determine slider rendering
    sliderSettings?: any;   // Pass slider settings for customization
}

const ProductDisplayThrift: React.FC<ProductDisplayThriftProps> = ({
    products,
    loading,
    error,
    skeletonCount,
    fetchProducts,
    setError,
    renderSlider = false,
    sliderSettings,
}) => {
    if (loading) {
        return <SkeletonShimmer count={skeletonCount} layout="product" />;
    }

    if (error) {
        return (
            <ErrorAlert
                error={error}
                fetchProducts={fetchProducts}
                setError={setError}
            />
        );
    }

    if (!products || products.length === 0) {
        return (
            <div className="col-12">
                <p className="no-records-found">No products found. Check back later!</p>
            </div>
        );
    }

    return (
        <div className="dotgrid carouselbox swiper swiper-container">
            <div className="wrapper swiper-wrapper">
                {products.map((product) => (
                    <ProductCardThrift key={product.id} product={product} />
                ))}
            </div>
        </div>
    );
};

export default ProductDisplayThrift;