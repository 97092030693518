import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from "react";
import { GridLoader } from "react-spinners";
import { sellerService } from "../../../services/sellerService";
import { ShopDetailsProps } from "../../../types/Types";
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';

const ShopSettings = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [formData, setFormData] = useState<ShopDetailsProps>({
        company_name: '',
        location: '',
        business_category: '',
        shop_description: '',
        status: '',
    });
  
    // Fetch the current shop settings based on sellerId
    useEffect(() => {
        const fetchShopDetails = async () => {
            setIsLoading(true);
            try {
                const shop = await sellerService.getShopDetails();
                setFormData(shop);  // Set the form data with the fetched shop details
            } catch (error) {
                setErrorMessage('Error fetching shop details');
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchShopDetails();
    }, []);

    // Handle form field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission to update shop details
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage(''); // Reset error message
        setSuccessMessage(''); // Reset success message

        // Validate form fields
        const requiredFields: { field: keyof ShopDetailsProps; message: string }[] = [
            { field: 'company_name', message: 'Company name is required' },
            { field: 'location', message: 'Shop location is required' },
            { field: 'shop_description', message: 'Shop description is required' },
            { field: 'business_category', message: 'Business category is required' }
        ];

        for (const { field, message } of requiredFields) {
            if (!formData[field]) {
                setErrorMessage(message);
                setLoading(false);
                return;
            }
        }

        try {
            // Send relevant shop fields to the backend
            const response = await sellerService.updateShopDetails(formData);
            setSuccessMessage('Shop settings updated successfully!');
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                // Now TypeScript knows that error is an AxiosError
                if (error.response) {
                    setErrorMessage(error.response.data.message || 'Error fetching shop settings');
                } else if (error.request) {
                    setErrorMessage('Network Error: Unable to reach the server. Please check your internet connection.');
                } else {
                    setErrorMessage(error.message || 'An unexpected error occurred');
                }
            } else {
                // Handle the case where the error is not an AxiosError
                setErrorMessage('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    if (isLoading) {
        return (
            <div style={loaderContainerStyle}>
                <GridLoader color="var(--vr-color-main)" loading={isLoading} />
            </div>
        );
    }

    return (
        <>
            {/* Conditionally render error/success messages */}
            {errorMessage && (
                <ErrorMessage>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MessageIcon as={MdErrorOutline} />
                        <h4>{errorMessage}</h4>
                    </div>
                <button aria-hidden="true" className="close" type="button" onClick={() => setErrorMessage(null)}>
                    ×
                </button>
                </ErrorMessage>
            )}

            {successMessage && (
                <SuccessMessage>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MessageIcon as={FaCheck} />
                    <h4>{successMessage}</h4>
                </div>
                <button aria-hidden="true" className="close" type="button" onClick={() => setSuccessMessage(null)}>
                    ×
                </button>
                </SuccessMessage>
            )}

            {/* Update Shop information start */}
            <Row>
                <Column>
                    <FormContainer>
                        <h3 style={{ marginBottom: '20px' }}>Shop Settings</h3>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <FormLabel>Shop Name</FormLabel>
                                <FormInput
                                    type="text"
                                    name="company_name"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    placeholder="Shop Name"
                                    required
                                />
                            </div>
                            <div>
                                <FormLabel>Business Category</FormLabel>
                                <FormInput
                                    type="text"
                                    name="business_category"
                                    value={formData.business_category}
                                    onChange={handleChange}
                                    placeholder="Business Category"
                                    required
                                />
                            </div>
                            <div>
                                <FormLabel>Shop Description</FormLabel>
                                <FormTextArea
                                    name="shop_description"
                                    value={formData.shop_description}
                                    onChange={handleChange}
                                    placeholder="Shop Description"
                                    required
                                />
                            </div>
                            <div>
                                <FormLabel>Shop Location</FormLabel>
                                <FormInput
                                    type="text"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}
                                    placeholder="Shop Location"
                                    required
                                />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <SubmitButton 
                                    type="submit" 
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save Changes'}
                                </SubmitButton>
                            </div>
                        </form>
                    </FormContainer>
                </Column>
            </Row>                              
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default ShopSettings;

// Error Message Component
const ErrorMessage = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SuccessMessage = styled(ErrorMessage)`
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
`;

// Icon Style
const MessageIcon = styled.div`
  margin-right: 10px;
  font-size: 1.5rem;
`;

// Form Container and Input Fields
const FormContainer = styled.div`
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormLabel = styled.label`
  font-size: 0.875rem;
  color: #333;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  
  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const FormTextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  
  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
  max-width: 100%;
  @media (min-width: 768px) {
    max-width: 48%;
  }
`;