import React from 'react';
import styled, { keyframes, css } from 'styled-components';

interface SkeletonShimmerProps {
    count: number;
    layout: 'product' | 'list';
}

// Keyframe animation for the shimmer effect
const shimmer = keyframes`
    0% {
        background-position: 150% 0;
    }
    100% {
        background-position: -150% 0;
    }
`;

const shimmerStyles = css`
    background: linear-gradient(
        to right,
        #f0f0f0 0%,
        #e0e0e0 20%,
        #f0f0f0 40%,
        #f0f0f0 100%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 1.5s linear infinite;
`;

const SkeletonWrapper = styled.div`
    // padding: 15px 0;
`;

const SkeletonItem = styled.div`
    margin-bottom: 30px;
`;

const ProductItem = styled.div`
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
`;

const ImageContainer = styled.div`
    position: relative;
    height: 260px;
    width: 232px;
    overflow: hidden;
    ${shimmerStyles}
`;

const ItemDetails = styled.div`
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const SkeletonText = styled.div<{ width?: string; height?: string }>`
    border-radius: 4px;
    height: ${props => props.height || '20px'};
    width: ${props => props.width || '100%'};
    position: relative;
    overflow: hidden;
    ${shimmerStyles}
`;

const RatingContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 0;
    justify-content: space-between; // This will push items to edges
`;

const StarsWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

const StarSkeleton = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    ${shimmerStyles}
`;

const SkeletonShimmer: React.FC<SkeletonShimmerProps> = ({ count, layout }) => {
    return (
        <SkeletonWrapper className="row">
            {Array.from({ length: count }).map((_, index) => (
                <div
                    key={index}
                    className={`${
                        layout === 'product' 
                            ? 'col-6 col-sm-4 col-md-4 col-lg-3' 
                            : 'col-12'
                    }`}
                >
                    <SkeletonItem>
                        {layout === 'product' ? (
                            <ProductItem>
                                <ImageContainer />
                                <ItemDetails>
                                    {/* Title */}
                                    <SkeletonText height="18px" width="85%" />

                                    {/* Description */}
                                    <SkeletonText height="16px" width="100%" />

                                    {/* Rating */}
                                    <RatingContainer>
                                        <StarsWrapper>
                                            {Array.from({ length: 5 }).map((_, i) => (
                                                <StarSkeleton key={i} />
                                            ))}
                                        </StarsWrapper>
                                        <SkeletonText 
                                            width="40px" 
                                            height="16px"
                                        />
                                    </RatingContainer>

                                    {/* Price */}
                                    <SkeletonText height="15px" width="40%" />
                                </ItemDetails>
                            </ProductItem>
                        ) : (
                            // List layout
                            <div className="d-flex align-items-start">
                                <ImageContainer style={{ width: '150px', height: '100px', marginRight: '15px' }} />
                                <div style={{ flex: 1 }}>
                                    {/* Title */}
                                    <SkeletonText height="20px" width="70%" />
                                    <SkeletonText height="18px" width="100%" />
                                    
                                    {/* Rating */}
                                    <div className="d-flex align-items-center mt-2">
                                        <StarsWrapper>
                                            {Array.from({ length: 5 }).map((_, i) => (
                                                <StarSkeleton key={i} />
                                            ))}
                                        </StarsWrapper>
                                        <SkeletonText width="50px" height="16px" style={{ marginLeft: '10px' }} />
                                    </div>
                                    
                                    {/* Price */}
                                    <SkeletonText height="20px" width="30%" style={{ marginTop: '10px' }} />
                                </div>
                            </div>
                        )}
                    </SkeletonItem>
                </div>
            ))}
        </SkeletonWrapper>
    );
};

export default SkeletonShimmer;