import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PulseLoader } from "react-spinners";
import axios from "axios";

// Components
import Icon from "../../../components/icon/Icon";
import ConfirmButton from "../../../components/admin/ConfirmButton";
import UserCard from "../../../components/admin/UserCard";
import usePagination from "../../../hooks/usePagination";
import DetailsModal from "../../../components/modals/DetailsModal";
import MessageModal from "../../../components/modals/MessageModal";

// hooks
import useDebounce from '../../../hooks/useDebounce';

// API services and type definition imports
import { adminService } from "../../../services/adminService";
import { messageService } from "../../../services/messageService";
import { UserProps } from "../../../types/Types";

const UserManagement: React.FC = () => {
    const [activeTab, setActiveTab] = useState<"sellers" | "shoppers">("sellers");
    const [searchTerm, setSearchTerm] = useState("");
    const [filter, setFilter] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<UserProps | null>(null);
    const [isMessageModalOpen, setMessageModalOpen] = useState(false);
    const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const [loadingStates, setLoadingStates] = useState<Record<string | number, boolean>>({}); // Add loading and error states

    const {
        data: users,
        setData: setUsers,
        currentPage,
        totalPages,
        totalItems,
        loading,
        error,
        handlePrev,
        handleNext,
        setPage,
        startItem,
        endItem
    } = usePagination<UserProps>(
        (page, limit) => adminService.getUsers(activeTab, page, limit, debouncedSearchTerm, filter ?? undefined ), 
        5, 
        [debouncedSearchTerm, filter, activeTab]
    );

    // handles confirmation of sellers
    const confirmSeller = async (id: number) => {
        try {
            const updatedSeller = await adminService.confirmSeller(id);
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.id === id ? { ...user, status: "active", ...updatedSeller } : user
                )
            );
        } catch (error) {
            console.error(`Error confirming seller with ID ${id}:`, error instanceof Error ? error.message : error);
        }
    };
    
    // handles toggling user status
    const toggleUserStatus = async (id: number, type: "sellers" | "shoppers") => {
        const user = users.find((u) => u.id === id);
        if (!user) {
            alert("User not found.");
            return;
        }
    
        // Determine the new status
        const newStatus = user.status === "blocked" ? "active" : "blocked";

        // Optimistically update the UI immediately
        setUsers((prevUsers) => 
            prevUsers.map((u) => 
                u.id === id ? { ...u, status: newStatus } : u
            )
        );
    
        // Set loading state for this specific user
        setLoadingStates((prev) => ({ ...prev, [id]: true }));
    
        try {
            // Attempt to update the status on the server
            const updatedUser = await adminService.updateUserStatus(id, type, newStatus);
            
            // If the server returns a different status, update to the server's response
            setUsers((prevUsers) => 
                prevUsers.map((u) => 
                    u.id === id ? { ...u, ...updatedUser } : u
                )
            );
        } catch (err) {
            // If the update fails, revert the local state
            setUsers((prevUsers) =>
                prevUsers.map((u) => 
                    u.id === id ? { ...u, status: user.status } : u
                )
            );

            // Show an error message
            alert(`Failed to update user status: ${err instanceof Error ? err.message : 'Unknown error'}`);

            console.error(`Error updating status for user ID ${id}:`, err instanceof Error ? err.message : err);
        } finally {
            // Always remove the loading state
            setLoadingStates((prev) => {
                const newStates = { ...prev };
                delete newStates[id];
                return newStates;
            });
        }
    };

    // handles sending of messages
    const handleMessage = async (receiverId: number, messageText: string): Promise<{ success: boolean }> => {
        if (!selectedUser || !messageText.trim()) {
            alert("Please select a user and enter a message.");
            return { success: false };
        }
    
        try {
            const response = await messageService.sendMessage(receiverId, messageText);
    
            if (response) {
                console.log("Message sent successfully:", response);
                return { success: true }; // Explicitly return success
            } else {
                throw new Error("No data returned from the API.");
            }
        } catch (error) {
            console.error("Error sending message:", error instanceof Error ? error.message : error);
            return { success: false }; // Explicitly return failure
        }
    };

    // handles formatting date
    const formatCreatedAt = (dateString: string): string => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <Container>
            <Tabs>
                <Tab active={activeTab === "sellers"} onClick={() => setActiveTab("sellers")}>
                    Sellers
                </Tab>
                <Tab active={activeTab === "shoppers"} onClick={() => setActiveTab("shoppers")}>
                    Shoppers
                </Tab>
            </Tabs>

            {/* Search button */}
            <SearchBar>
                <Input
                    id="search_input"
                    name="search"
                    type="text"
                    placeholder={`Search ${activeTab}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button onClick={() => setSearchTerm("")}>Clear</Button>
            </SearchBar>

            {/* Filter */}
            <FilterBar>
                <FilterDropdown
                    id="statusFilter"
                    name="statusFilter"
                    value={filter || ""} 
                    onChange={(e) => setFilter(e.target.value || null)}>
                    <option value="">All Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="pending">Pending</option>
                    <option value="blocked">Blocked</option>
                </FilterDropdown>
                {filter && (
                    <ClearFilterButton onClick={() => setFilter(null)}>Clear Filter</ClearFilterButton>
                )}
            </FilterBar>

            {/* Errors and a retry button */}
            {error && (
                <ErrorMessage>
                    {error}
                    <Button onClick={() => setPage(currentPage)}>Retry</Button>
                </ErrorMessage>
            )}

            {/* Loading State */}
            {loading ? (
                <LoadingOverlay>
                    <PulseLoader 
                        color="#4A90E2" 
                        size={15}
                        margin={2}
                        speedMultiplier={0.8}
                    />
                    <LoadingText>Loading...</LoadingText>
                </LoadingOverlay>
            ) : (
                <>
                    {/* User List */}
                    {users?.length > 0 ? (
                        users.map((user) => (
                            <UserCard
                                key={user.id}
                                user={user}
                                type={activeTab}
                                onToggleStatus={toggleUserStatus}
                                onMessage={() => {
                                    setSelectedUser(user);
                                    setMessageModalOpen(true);
                                }}
                                onClick={() => {
                                    setSelectedUser(user);
                                    setDetailsModalOpen(true);
                                }}
                                isLoading={loadingStates[user.id]} // Pass the specific user's loading state
                            />
                        ))
                    ) : (
                        <p>No users found</p>
                    )}

                    {/* Pagination Controls */}
                    <PaginationControls>
                        <PaginationInfo>
                            {totalItems === 0 ? (
                                `No ${activeTab === "shoppers" ? "shoppers" : "sellers"} found`
                            ) : (
                                <>
                                    Showing{" "}
                                    <span>
                                        <strong>
                                            {startItem}&nbsp;-&nbsp;{endItem}
                                        </strong>
                                    </span>{" "}
                                    of <strong>{totalItems}</strong>{" "}
                                    {activeTab === "shoppers"
                                        ? totalItems === 1
                                            ? "shopper"
                                            : "shoppers"
                                        : totalItems === 1
                                        ? "seller"
                                        : "sellers"}
                                </>
                            )}
                        </PaginationInfo>
                        <PaginationButtonContainer>
                            <ButtonContainer>
                                <Button onClick={handlePrev} disabled={currentPage === 1}>
                                    <Icon name="BiChevronLeft" /> Prev
                                </Button>
                                <Button onClick={handleNext} disabled={currentPage === totalPages || totalPages === 0}>
                                    Next <Icon name="BiChevronRight" />
                                </Button>
                            </ButtonContainer>
                        </PaginationButtonContainer>
                    </PaginationControls>
                </>
            )}


            {/* Details Modal */}
            {isDetailsModalOpen && selectedUser && (
                <DetailsModal
                    user={selectedUser}
                    isOpen={isDetailsModalOpen}
                    onClose={() => setDetailsModalOpen(false)}
                    formatCreatedAt={formatCreatedAt}
                    activeTab={activeTab}
                    confirmSeller={confirmSeller}
                />
            )}

            {/* Message Modal */}
            {isMessageModalOpen && selectedUser && (
                <MessageModal
                    user={selectedUser}
                    receiverId={Number(selectedUser.id)}
                    onClose={() => {
                        setMessageModalOpen(false);
                        setSelectedUser(null);
                    }}
                    onSend={handleMessage}
                    isOpen={isMessageModalOpen}
                />
            )}
            
        </Container>
    );
};

export default UserManagement;


// Styled components
const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem;
`;

const Tabs = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const Tab = styled.button<{ active: boolean }>`
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 12px;
    background-color: ${(props) => (props.active ? "#f0f0f0" : "transparent")};
    color: ${(props) => (props.active ? "#333" : "#555")};
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: ${(props) => (props.active ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none")};

    &:hover {
        background-color: #f0f0f0;
    }
`;

const SearchBar = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

const Input = styled.input`
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    margin-right: 0.5rem;
`;

const Button = styled.button<{ variant?: "red" | "modern" }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: ${(props) => (props.variant === "red" ? "none" : "1px solid #ddd")};
    border-radius: 12px;
    background-color: ${(props) =>
        props.variant === "red" ? "#dc3545" : "#f9f9f9"};
    color: ${(props) => (props.variant === "red" ? "#fff" : "#333")};
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) =>
        props.variant === "red" ? "#c82333" : "#f0f0f0"};
    }
`;

const LoadingOverlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    gap: 1rem;
`;

const LoadingText = styled.p`
    color: #666;
    margin-top: 0.5rem;
    font-size: 0.875rem;
`;

const ErrorMessage = styled.div`
    color: #dc3545;
    padding: 1rem;
    margin: 1rem 0;
    background-color: #f8d7da;
    border-radius: 4px;
    text-align: center;
`;

const FilterBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
`;

const FilterDropdown = styled.select`
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        border-color: #aaa;
    }
`;

const ClearFilterButton = styled(Button)`
    background: #f0f0f0;
    color: #333;

    &:hover {
        background: #ddd;
    }
`;

const PaginationInfo = styled.div`
  text-align: left;
  flex: 1;
  font-weight: normal;

  span strong {
    font-weight: bold;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`;

const PaginationButtonContainer = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  display: flex; /* Keep buttons visible all the time */
  gap: 10px;

  button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 8px 16px;
    border: 1px solid transparent; /* Add default border */
    background-color: #f1f1f1;
    cursor: pointer;

    /* Hover effect */
    &:hover {
      border: 1px solid #4a90e2; /* Border appears on hover */
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      border: 1px solid #dcdcdc; /* Disabled state border */
    }
  }
`;