import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { PulseLoader } from "react-spinners";

// services
import { adminService } from "../../../services/adminService";

// hooks
import useDebounce from '../../../hooks/useDebounce';

// types
import { ProductProps, CategoryProps, SectionProps } from "../../../types/Types";

// components
import PriceFormatter from "../../../components/product filter/PriceFormatter";
import AddProductModal from "../../../components/modals/AddProductModal";
import ViewProductModal from "../../../components/modals/ViewProductModal";
import EditProductModal from "../../../components/modals/EditProductModal";
import DeleteProductModal from "../../../components/modals/DeleteProductModal";
import ActionsDropdown from "../../../components/misc/ActionsDropdown";
import Icon from "../../../components/icon/Icon";


const ProductManagement: React.FC = () => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [filteredProducts, setFilteredProducts] = useState<ProductProps[]>([]);
    const [categories, setCategories] = useState<CategoryProps[]>([]);
    const [sections, setSections] = useState<SectionProps[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const [statusFilter, setStatusFilter] = useState<string>('all');
    const [loading, setLoading] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const limit = 10; // Items per page
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductProps | null>(null);

    const fetchProducts = async () => {
        setLoading(true);
        setError(null);
      
        try {
            const response = await adminService.getProducts(
                currentPage,
                limit,
                debouncedSearchTerm,
                statusFilter !== "all" ? statusFilter : undefined
            );
            const { data, totalPages: apiTotalPages } = response;
    
            setProducts(data);
            setFilteredProducts(data);
            setTotalPages(apiTotalPages);
        } catch (err: any) {
            console.error("Error fetching products:", err);
            setError(err.response?.data?.message || "Failed to fetch products.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, limit, debouncedSearchTerm, statusFilter]);

    // fetch the product sections and categories from database
    useEffect(() => {
        const fetchCategoriesAndSections = async () => {
            try {
                const [categoriesResponse, sectionsResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_Base_url}/utils/get_product_categories`),
                    axios.get(`${process.env.REACT_APP_Base_url}/utils/get_product_sections`)
                ]);
    
                if (categoriesResponse.data.status === 200) {
                    setCategories(categoriesResponse.data.product_categories);
                }
    
                if (sectionsResponse.data.status === 200) {
                    setSections(sectionsResponse.data.product_sections);
                }
            } catch (error) {
                console.error('Error fetching categories and sections:', error);
            }
        };
    
        fetchCategoriesAndSections();
    }, []);

    useEffect(() => {
        setCurrentPage(1); // Reset to the first page
    }, [statusFilter, debouncedSearchTerm]);

    // Render product status colors 
    const renderStatusBadge = (product: ProductProps) => {
        // Determine status based on product_activated and product_quantity
        let status: "active" | "pending" | "inactive";

        if (product.product_quantity === 0) {
            status = "inactive"; // If quantity is 0, set status to inactive
        } else {
            status = product.product_activated ? "active" : "pending"; // Check product_activated for active or pending
        }

        // Set color based on status
        const color = status === "active" ? '#28a745' : status === "pending" ? '#ffc107' : '#dc3545'; 
    
        return (
            <StatusBadge color={color}>
                {status.charAt(0).toUpperCase() + status.slice(1)} {/* Capitalize the first letter */}
            </StatusBadge>
        );
    };

    const handleView = (product: ProductProps) => {
        setSelectedProduct(product);
        setViewModalOpen(true);
    };

    const handleEdit = (product: ProductProps) => {
        setSelectedProduct(product);
        setEditModalOpen(true);
    };

    const handleDelete = (product: ProductProps) => {
        setSelectedProduct(product);
        setDeleteModalOpen(true);
    };

    const closeModal = () => {
        setViewModalOpen(false);
        setEditModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedProduct(null);
    };

    // Function to format created_at date
    const formatCreatedAt = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = { 
            month: 'short', // Use 'short' for abbreviated month (e.g., Jan)
            day: 'numeric', // Numeric day (e.g., 2)
            year: 'numeric'  // Numeric year (e.g., 2025)
        };
        return date.toLocaleDateString(undefined, options);
    };

    return (
        <>
            <ProductsContainer>
                <HeaderSection>
                    <PageTitle>Product Management</PageTitle>
                    <SearchInput
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <ActionBar>
                        <FilterSelect
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                        >
                            <option value="all">All Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                            <option value="pending">Pending</option>
                        </FilterSelect>
                        <AddProductButton onClick={() => setShowAddProductModal(true)}>
                            <Icon name="IoAdd" size="1.2em" style={{ position: 'relative', top: '-1px' }}/>
                            <span className="add-product-text">Add Product</span>
                        </AddProductButton>
                    </ActionBar>
                </HeaderSection>
                
                {/* Main Content */}
                {loading ? (
                    <LoadingOverlay>
                        <PulseLoader 
                            color="#4A90E2" 
                            size={15}
                            margin={2}
                            speedMultiplier={0.8}
                        />
                        <LoadingMessage>Loading products...</LoadingMessage>
                    </LoadingOverlay>
                ) : error ? (
                    <ErrorMessage>{error}</ErrorMessage>
                ) : filteredProducts.length > 0 ? (
                    <>
                        <ProductTable>
                            <thead>
                                <tr>
                                    <th className="hide-sm"></th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th className="hide-sm">Price</th>
                                    <th className="hide-sm">Stock</th>
                                    <th className="hide-sm">Created at</th>
                                    <th className="hide-sm">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map((product) => (
                                    <tr key={product.id}>
                                        <td className="hide-sm">
                                            <img 
                                                src={product.main_image}
                                                alt={product.product_name}
                                                style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px', maxWidth: '50px' }}
                                            />
                                        </td>
                                        <td>{product.product_name}</td>
                                        <td>{renderStatusBadge(product)}</td>
                                        <td className="hide-sm"><PriceFormatter price={product.product_price} /></td>
                                        <td className="hide-sm">{product.product_quantity}</td>
                                        <td className="hide-sm">{formatCreatedAt(product.created_at)}</td>
                                        <td>
                                            <Actions>
                                                <div className="actions-lg">
                                                    <ActionButton 
                                                        className="info" 
                                                        data-toggle="tooltip" 
                                                        title="View"
                                                        onClick={() => handleView(product)}
                                                    >
                                                        <Icon name="FaEye" />
                                                    </ActionButton>
                                                    <ActionButton 
                                                        className="warning" 
                                                        data-toggle="tooltip" 
                                                        title="Edit"
                                                        onClick={() => handleEdit(product)}
                                                    >
                                                        <Icon name="FaEdit" />
                                                    </ActionButton>
                                                    <ActionButton 
                                                        className="danger" 
                                                        data-toggle="tooltip" 
                                                        title="Delete"
                                                        onClick={() => handleDelete(product)}
                                                    >
                                                        <Icon name="FaTrash" />
                                                    </ActionButton>
                                                </div>
                                                <div className="actions-sm">
                                                    <ActionsDropdown
                                                        onView={() => handleView(product)}
                                                        onEdit={() => handleEdit(product)}
                                                        onDelete={() => handleDelete(product)}
                                                    />
                                                </div>
                                            </Actions>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </ProductTable>
                
                        <Pagination>
                            <PageButton
                                disabled={currentPage === 1}
                                onClick={() => setCurrentPage((prev) => prev - 1)}
                            >
                                Previous
                            </PageButton>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <PageButton
                                disabled={currentPage === totalPages}
                                onClick={() => setCurrentPage((prev) => prev + 1)}
                            >
                                Next
                            </PageButton>
                        </Pagination>
                    </>
                ) : (
                    <NoResultsMessage>No products found matching your search or filter.</NoResultsMessage>
                )}
            </ProductsContainer>

            <AddProductModal 
                isOpen={showAddProductModal} 
                onClose={() => setShowAddProductModal(false)} 
            />
            
            {selectedProduct && (
                <ViewProductModal 
                    isOpen={isViewModalOpen} 
                    onClose={() => setViewModalOpen(false)} 
                    product={selectedProduct}
                    formatCreatedAt={formatCreatedAt} 
                />
            )}

            {selectedProduct && (
                <EditProductModal 
                    isOpen={isEditModalOpen} 
                    onClose={() => setEditModalOpen(false)} 
                    product={selectedProduct}
                    categories={categories}
                    sections={sections}
                />
            )}

            {selectedProduct && (
                <DeleteProductModal 
                    isOpen={isDeleteModalOpen} 
                    onClose={() => setDeleteModalOpen(false)} 
                    product={selectedProduct} 
                    onDelete={() => handleDelete(selectedProduct)} 
                />
            )}
        </>
    );
};

export default ProductManagement;

// Styled Components
const ProductsContainer = styled.div`
    background-color: #f4f7f6;
    padding: 20px;
    border-radius: 10px;

    @media (max-width: 640px) {
        padding: 10px;
    }
`;

const HeaderSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    align-items: flex-start;

    @media (max-width: 640px) {
        flex-direction: column;
    }
`;

const PageTitle = styled.h1`
    flex: 1 1 100%;
    margin-bottom: 10px;
    color: #333;
    font-size: 1.8rem;

    @media (min-width: 641px) {
        margin-bottom: 0;
    }
`;

const ActionBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 640px) {
        flex-direction: row;
        width: 100%;
        justify-content: space-between; /* Spread items horizontally */
    }
`;

const SearchInput = styled.input`
    flex: 1;
    padding: 7.5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  
    &:focus {
        outline: none;
        border-color: #28a745;;
    }
    
    @media (max-width: 640px) {
        width: 100%;
    }
`;

const FilterSelect = styled.select`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
`;

const AddProductButton = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    justify-content: center;
    font-size: 14px;

    &:hover {
        background-color: #218838;
    }

    .add-text {
        display: inline; /* Show text by default */
    }

    @media (max-width: 640px) {
        width: 38px;
        height: 2rem;
        gap: 0;

        .add-product-text {
            display: none; /* Hide text on smaller screens */
        }
    }
`;

const ProductTable = styled.table`
    width: 100%;
    /* background-color: white; */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-collapse: collapse;
    border-spacing: 0;
    overflow-x: auto;

    thead {
        background-color: #f8f9fa;
    }
    
    tbody {
        background-color: white;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #e9ecef;

        &.hide-sm {
            @media (max-width: 640px) {
                display: none;
            }
        }
    }

    tbody tr:hover {
        background-color: #f1f3f5;
    }
`;

const StatusBadge = styled.span<{ color: string }>`
    display: inline-block;
    background-color: ${props => props.color || "#6c757d"};
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: bold;
`;

// Actions Section
const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .actions-lg {
        display: flex;
        gap: 10px;
    }

    .actions-sm {
        display: none;
    }

    @media (max-width: 640px) {
        .actions-lg {
        display: none;
        }
        .actions-sm {
        display: block;
        }
    }
`;

const ActionButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;

    &.info {
        color: #17a2b8;
    }
    &.warning {
        color: #ffc107;
    }
    &.danger {
        color: #dc3545;
    }
`;

const NoResultsMessage = styled.div`
    text-align: center;
    color: #6c757d;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
`;

const LoadingOverlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    gap: 1rem;
`;

const LoadingMessage = styled.div`
    text-align: center;
    color: #007bff;
    margin-top: 20px;
    margin-top: 0.5rem;
    font-size: 0.875rem;
`;

const ErrorMessage = styled.div`
    text-align: center;
    color: #dc3545;
    margin-top: 20px;
`;

const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const PageButton = styled.button`
    padding: 5px 15px;
    border: 1px solid #ddd;
    background-color: #f8f9fa;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &:hover {
        background-color: #e2e6ea; /* Change background color on hover */
        border-color: #adb5bd; /* Change border color on hover */
    }
`;