import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface ActionsDropdownProps {
    onView: () => void;
    onEdit: () => void;
    onDelete: () => void;
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
    onView,
    onEdit,
    onDelete,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Toggle dropdown visibility
    const toggleDropdown = () => setIsOpen((prev) => !prev);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

  return (
        <DropdownWrapper ref={dropdownRef}>
            <EllipsisButton onClick={toggleDropdown}>...</EllipsisButton>
            <DropdownList isOpen={isOpen}>
                <Title>Actions</Title>
                <DropdownItem onClick={onView}>View</DropdownItem>
                <DropdownItem onClick={onEdit}>Edit</DropdownItem>
                <DropdownItem onClick={onDelete}>Delete</DropdownItem>
            </DropdownList>
        </DropdownWrapper>
    );
};

export default ActionsDropdown;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const EllipsisButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  position: relative;
  top: -5px;
`;

const Title = styled.div`
    padding: 6px 8px;
    font-weight: bold;
    border-bottom: 1px solid #ddd; /* Optional: border below the title */
    pointer-events: none;
`;

const DropdownList = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  z-index: 10;
  min-width: 120px;
  padding: 4px;

  /* Animation */
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.isOpen ? "0" : "-10px")});
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${(props) => (props.isOpen ? "auto" : "none")}; /* Disable interaction when hidden */
`;

const DropdownItem = styled.button`
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 6px 8px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #333;
  border-radius: 0;

  &:hover {
    background-color: #f8f9fa;
  }

  /* Remove gap and icons */
  display: block;
`;
