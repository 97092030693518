import React, { useState, useImperativeHandle, forwardRef } from 'react';

interface PriceFilterProps {
    onFilter: (minPrice: number, maxPrice: number) => void;
}

export interface PriceFilterRef {
    resetMinPrice: () => void;
    resetMaxPrice: () => void;
    minPrice: number;
    maxPrice: number;
}

const PriceFilter = forwardRef<PriceFilterRef, PriceFilterProps>(({ onFilter }, ref) => {
    const [minPrice, setMinPrice] = useState<string>('');
    const [maxPrice, setMaxPrice] = useState<string>('');
    const [isInvalid, setIsInvalid] = useState<boolean>(false); // To track if input is invalid
    const [errorMessage, setErrorMessage] = useState<string>('');

    useImperativeHandle(ref, () => ({
        minPrice: parseFloat(minPrice) || 0,
        maxPrice: parseFloat(maxPrice) || 1000000,
        resetMinPrice: () => setMinPrice(''),
        resetMaxPrice: () => setMaxPrice(''),
    }));

    const handlePriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
    
        // Allow only numeric or empty values
        if (!/^\d*\.?\d*$/.test(value)) return;
    
        if (id === 'price_min') {
            setMinPrice(value);
        } else if (id === 'price_max') {
            setMaxPrice(value);
        }
    
        // Check if min and max prices are invalid
        const min = parseFloat(id === 'price_min' ? value : minPrice) || 0;
        const max = parseFloat(id === 'price_max' ? value : maxPrice) || 1000000;

        // Check for invalid conditions
        if (min === max) {
            setIsInvalid(true);
            setErrorMessage('Min price and max price cannot be the same.');
        } else if (min > max) {
            setIsInvalid(true);
            setErrorMessage('Min price cannot be greater than max price.');
        } else {
            setIsInvalid(false);
            setErrorMessage('');
        }
    };

    const handleFilterClick = () => {
        const min = parseFloat(minPrice) || 0;
        const max = parseFloat(maxPrice) || 1000000;

        if (min !== max) {
            onFilter(min, max);
        }
    };

    return ( 
        <div className="filter-item border-0">
            <h4 className="title">Price</h4>
            <div className="price-filter-inputs">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between inputs-filter-price">
                            <input 
                                type="number" 
                                id="price_min" 
                                value={minPrice}
                                className={`min-price form-control form-input ${isInvalid ? 'input-error' : ''}`}
                                placeholder="Min"
                                min="0" 
                                step="0.1"
                                onChange={handlePriceInput}
                            />  
                            <span className="align-self-center">-</span>    
                            <input 
                                type="number" 
                                id="price_max" 
                                value={maxPrice}
                                className={`max-price form-control form-input ${isInvalid ? 'input-error' : ''}`}
                                placeholder="Max"
                                min="0" 
                                step="0.1"
                                onChange={handlePriceInput}
                            />
                        </div>
                        {isInvalid && <p className="text-danger mt-1">{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PriceFilter;