import React from 'react';
import { sortOptions, SortCriteria } from '../../types/Types';

// Infer the type of sortOptions using `typeof`
type SortOptions = typeof sortOptions;

interface ProductSortProps {
    sortOptions: SortOptions;
    sortCriteria: SortCriteria;
    handleSortChange: (sortValue: SortCriteria) => void;
}

const ProductSort: React.FC<ProductSortProps> = ({ sortOptions, sortCriteria, handleSortChange }) => {
    return (
        <div className="product-sort-by">
            <div className="dropdown">
                <button 
                    className="btn btn-md dropdown-toggle" 
                    type="button" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                        <path fill="#666666" d="M13.47 7.53a.75.75 0 0 0 1.06 0l.72-.72V17a.75.75 0 0 0 1.5 0V6.81l.72.72a.75.75 0 1 0 1.06-1.06l-2-2a.75.75 0 0 0-1.06 0l-2 2a.75.75 0 0 0 0 1.06m-4.72 9.66l.72-.72a.75.75 0 1 1 1.06 1.06l-2 2a.75.75 0 0 1-1.06 0l-2-2a.75.75 0 1 1 1.06-1.06l.72.72V7a.75.75 0 0 1 1.5 0z"></path>
                    </svg>
                    {sortOptions[sortCriteria]}
                    <i className="icon-arrow-down"></i>
                </button>
                <div className="dropdown-menu dropdownSortOptions">
                    {Object.entries(sortOptions).map(([value, label]) => (
                        <button
                            key={value}
                            type="button"
                            className="dropdown-item"
                            data-action={value}
                            onClick={() => handleSortChange(value as SortCriteria)}
                        >
                            {label} {/* Display the user-friendly label */}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductSort;