import React from 'react';
import Icon from "../icon/Icon";
import { Link } from 'react-router-dom';

interface ViewProductButtonProps {
    productId: number;
}

const ViewProductButton: React.FC<ViewProductButtonProps> = ({ productId }) => {
    return (
        <>
            <Link to={`/details`} state={{ productId }}>
                <li>
                    <a href="/details" className="tooltip-container">
                        <Icon name="FaEye" size="1.5em" />
                        <div className="tooltip">
                            <span className="tooltip-text">View Details</span>
                            <span className="tooltip-arrow"></span>
                        </div>
                    </a>
                </li>
            </Link>               
        </>
    );
};

export default ViewProductButton;