import { AxiosError } from "axios";

// Centralized error handler
export const handleError = (error: unknown, defaultMessage: string): void => {
    if (error instanceof AxiosError) {
        // Handle Axios error
        console.error(`${defaultMessage}:`, error.response?.data?.message || error.message);
    } else if (error instanceof Error) {
        // Handle general JavaScript errors
        console.error(`${defaultMessage}:`, error.message);
    } else {
        // Handle unknown error type
        console.error(`${defaultMessage}:`, 'An unknown error occurred');
    }
};
