import { apiService } from "./api.types";
import { Message, ChatListItem } from "../types/Types";
import { handleError } from "./errorHandler";

export const messageService = {
    // Send a message
    sendMessage: async (receiverId: number, messageText: string): Promise<Message> => {
        try {
            // Explicitly type the response to include the expected data structure
            const response = await apiService.post<{ data: Message }>('/utils/messages', {
                receiverId,
                messageText,
            });

            return response.data; // TypeScript now recognizes `.data` as Message
        } catch (error) {
            handleError(error, 'Failed to send message.');
            throw error; // Rethrow the error after handling
        }
    },

    // Get chat list
    getChatList: async (): Promise<ChatListItem[]> => {
        try {
            // Explicitly type the API response
            const response = await apiService.get<{ data: ChatListItem[] }>('/utils/chat-list');

            return response.data; // Now `response.data` is recognized as ChatListItem[]
        } catch (error) {
            handleError(error, 'Failed to fetch chat list.');
            throw error; // Rethrow the error after handling
        }
    },

    // Get messages by role ID
    getMessages: async (roleId: number): Promise<Message[]> => {
        try {
            const response = await apiService.get<{ data: Message[] }>(`/utils/messages?otherRoleId=${roleId}&limit=20&offset=0`);
            return response.data; // Return only the array of messages
        } catch (error) {
            handleError(error, 'Failed to fetch messages.');
            throw error; // Rethrow the error after handling
        }
    },
};