import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductProps } from "../../types/Types";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ProductDisplay from "./ProductDisplay";

// styles and icons
import "./product.css"
import Icon from "../icon/Icon";

const Products = () => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [skeletonCount, setSkeletonCount] = useState(20);

    const fetchNewestProducts = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_Base_url}/products/get_newest_products`);
                if (res.data.status === 200) {
                    setProducts(res.data.products);
                } else {
                    setError("Oops! Something went wrong. Please try again.");
                }
            } catch (err) {
                setError("Oops! Something went wrong. Please try again.");
                console.error("Error fetching newest products:", err);
            } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewestProducts();
    }, []);

    return (
        <>
            <div className="col-12 section section-latest-products">
                <div className="section-header display-flex justify-content-between">
                    <h3 className="title">
                        <Link to="/products">
                            New Arrivals
                        </Link>
                    </h3>
                    <Link to="/products" className="font-600">
                        View All&nbsp;
                        <Icon name="FaArrowRight" style={{ position: 'relative', top: '-1.5px' }}/>
                    </Link>
                </div>                   
                                    
                {/* products display */}  
                <ProductDisplay
                    products={products}
                    loading={loading}
                    error={error}
                    skeletonCount={skeletonCount}
                    fetchProducts={fetchNewestProducts}
                    setError={setError}
                />
            </div>  
        </>
    );
};

export default Products;