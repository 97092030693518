import React from 'react';
import Icon from "../../../components/icon/Icon";

const SellerShopDetails = ({ shopInfo }) => {
    if (!shopInfo) {
        return <p>Loading shop details...</p>;
    }

    return (
        <>
            <div className="shop-container"> 
                <ul className="shop-info-list">
                    <li><strong>Shop Name:</strong> {shopInfo.company_name}</li>
                    <li><strong>Shop Location:</strong> {shopInfo.location}</li>
                    <li><strong>Business Category:</strong> {shopInfo.business_category}</li>
                    <li><strong>Shop Description:</strong> {shopInfo.shop_description}</li>
                    <li>
                        <strong>Verified:</strong> 
                        {shopInfo.status === "active" ? (
                            <Icon 
                                name="FcApproval" 
                                size="1.5em" 
                                color="#09b1ba" 
                                style={{ position: 'relative', top: '-1.5px' }} 
                            />
                        ) : (
                            <Icon 
                                name="FcCancel" 
                                size="1.5em" 
                                color="red" 
                                style={{ position: 'relative', top: '-1.5px' }} 
                            />
                        )}
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SellerShopDetails;