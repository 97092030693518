import React from 'react';
import { Link } from "react-router-dom";
import Slider from "react-slick";

// types
import { ProductProps } from '../../types/Types';

// components
import ProductCard from './ProductCard';
import SkeletonShimmer from '../loader/SkeletonShimmer';
import ErrorAlert from "../error alert/ErrorAlert";
import Icon from "../icon/Icon";

interface ProductDisplayProps {
    products: ProductProps[];
    loading: boolean;
    error: string | null;
    skeletonCount: number;
    fetchProducts: () => void;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    renderSlider?: boolean; // New prop to determine slider rendering
    sliderSettings?: any;   // Pass slider settings for customization
    sliderRef?: React.RefObject<typeof Slider>; // Pass the slider ref
    className?: string; // Add this for custom className
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({
    products,
    loading,
    error,
    skeletonCount,
    fetchProducts,
    setError,
    renderSlider = false,
    sliderSettings,
    sliderRef,
    className,
}) => {
    if (loading) {
        return <SkeletonShimmer count={skeletonCount} layout="product" />;
    }

    if (error) {
        return (
            <ErrorAlert
                error={error}
                fetchProducts={fetchProducts}
                setError={setError}
            />
        );
    }

    if (!products || products.length === 0) {
        return (
            <div id="productListContent" className="product-list-content">
                <div id="productListResultContainer" className="row row-product">
                    <div className="col-12">
                        <p className="no-records-found">No products found. Check back later!</p>
                    </div>
                </div>
            </div>
        );
    }

    const getClassName = (product: ProductProps) => {
        if (renderSlider) {
            return "col-product slick slide";
        }
        if (className) {
            return className;
        }
        return "col-6 col-sm-4 col-md-3 col-mds-5 col-product"; // default className
    };

    const productCards = products.map((product) => (
        <ProductCard
            key={product.id}
            product={product}
            className={getClassName(product)} // Dynamic class name
        />
    ));

    return renderSlider ? (
        <div className="slider-container">
            <Slider {...sliderSettings} ref={sliderRef}>
                {productCards}

                {/* View All Button */}
                {products.length > 10 && (
                    <div className="col-product slick slide">
                        <div className="view-all-container">
                            <Link
                                to="/product-section"
                                className="view-all-link"
                                style={{ position: "relative", top: "120px" }}
                                state={{ data: products[0]?.product_section }}
                            >
                                <span>View All</span>
                                <Icon name="FaArrowRight" style={{ position: "relative", top: "-1.5px" }} />
                            </Link>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    ) : (
        <div id="productListContent" className="product-list-content">
            <div id="productListResultContainer" className="row row-product">
                {productCards}
            </div>
        </div>
    );
};

export default ProductDisplay;