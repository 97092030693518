import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { PuffLoader } from "react-spinners";
import Icon from "../icon/Icon";

interface WishlistButtonProps {
    productId: number;
}

const WishlistButton: React.FC<WishlistButtonProps> = ({ productId }) => {
    const [isAddedToWishlist, setIsAddedToWishlist] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Get user session id from local storage
    const getUserSessionId = (): string => {
        let userSessionId = localStorage.getItem("userSessionId");
        if (!userSessionId) {
            userSessionId = uuidv4();
            localStorage.setItem("userSessionId", userSessionId);
        }
        return userSessionId;
    };

    // Check if the product is in the wishlist
    useEffect(() => {
        const userSessionId = getUserSessionId();

        const checkWishlistStatus = async () => {
            try {
                const response = await axios.get<{ isInWishlist: boolean }>(
                    `${process.env.REACT_APP_Base_url}/shoppers/check_wishlist/${userSessionId}/${productId}`
                );
                setIsAddedToWishlist(response.data.isInWishlist);
            } catch (error) {
                console.error("Error checking wishlist status:", error);
            }
        };

        checkWishlistStatus();
    }, [productId]);

    // Handle addition/removal of items in the wishlist
    const handleToggleWishlist = async () => {
        setIsLoading(true);
        const userSessionId = getUserSessionId();

        try {
            if (isAddedToWishlist) {
                // Remove from wishlist
                await axios.post(`${process.env.REACT_APP_Base_url}/products/remove_from_wishlist`, {
                    user_session_id: userSessionId,
                    product_id: productId,
                });
                setIsAddedToWishlist(false);
            } else {
                // Add to wishlist
                await axios.post(`${process.env.REACT_APP_Base_url}/products/add_to_wishlist`, {
                    user_session_id: userSessionId,
                    product_id: productId,
                });
                setIsAddedToWishlist(true);
            }
        } catch (error) {
            console.error("Error toggling wishlist item:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="button-container button-container-wishlist">
            <a
                href="#"
                onClick={handleToggleWishlist}
                className="btn-wishlist btn-add-remove-wishlist"
                aria-disabled={isLoading}
            >
                {isLoading ? (
                    <PuffLoader size={20} color="#000" />
                ) : (
                    <>
                        <Icon
                            name={isAddedToWishlist ? "BsHeartFill" : "BsHeart"}
                            style={{
                                fontSize: "26px",
                                marginRight: "8px",
                                color: isAddedToWishlist ? "red" : "inherit",
                            }}
                        />
                        <span>{isAddedToWishlist ? "Remove from wishlist" : "Add to wishlist"}</span>
                    </>
                )}
            </a>
        </div>
    );
};

export default WishlistButton;