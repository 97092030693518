import styled, { keyframes } from "styled-components";

interface ModalProps {
    isVisible?: boolean;
    isClosing?: boolean;
}

export const Modal = styled.div<ModalProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: ${({ isVisible, isClosing }) => 
        isClosing ? 0 : isVisible ? 1 : 0};
    visibility: ${({ isVisible, isClosing }) => 
        isClosing || isVisible ? 'visible' : 'hidden'};
    transition: opacity 0.3s ease, visibility 0.3s ease;
`;

export const ModalContent = styled.div<ModalProps>`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: ${({ isVisible, isClosing }) => 
        isClosing ? 'scale(0.9)' : isVisible ? 'scale(1)' : 'scale(0.9)'};
    opacity: ${({ isVisible, isClosing }) => 
        isClosing ? 0 : isVisible ? 1 : 0};
    transition: transform 0.3s ease, opacity 0.3s ease;
`;

// Button Styles
export const CancelButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
        background-color: #d32f2f;
        transform: scale(1.05);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
    }
`;