import React from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
    items: {
        label: string; // Display text for the breadcrumb
        to?: string; // Optional path for the breadcrumb, `undefined` for the active item
    }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    return (
        <nav className="nav-breadcrumb" aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, index) => (
                    <li
                        key={index}
                        className={`breadcrumb-item ${!item.to ? "active" : ""}`}
                    >
                        {item.to ? <Link to={item.to}>{item.label}</Link> : item.label}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;