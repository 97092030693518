import React, { useEffect, useState, useRef } from "react";
import { PuffLoader } from "react-spinners";

// types
import { ProductProps, sortOptions, SortCriteria } from '../../../../types/Types';

// hooks
import useDebounce from '../../../../hooks/useDebounce';

// services
import { sellerService } from "../../../../services/sellerService";

// Components
import Pagination from '../../../../components/pagination/Pagination';
import PriceFilter, { PriceFilterRef } from '../../../../components/product filter/PriceFilter';
import ProductSort from '../../../../components/product filter/ProductSort';
import PriceReset from '../../../../components/product filter/PriceReset';
import Icon from '../../../../components/icon/Icon';
import ProductDisplay from "../../../../components/products/ProductDisplay";

type TotalProductProp = {
    setTotalProducts: (count: number) => void;
};


const SellerProducts: React.FC<TotalProductProp> = ({ setTotalProducts }) => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<SortCriteria>("most_recent");
    const [minPrice, setMinPrice] = useState<number | null>(null);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const priceFilterRef = useRef<PriceFilterRef>(null);
    const [error, setError] = useState<string | null>(null);
    const [productName, setProductName] = useState(''); // State to store the search input
    const debouncedProductName = useDebounce(productName, 300); // Debounced value with a delay of 300ms
    const [skeletonCount, setSkeletonCount] = useState(24);

    // Fetch seller products
    const fetchProducts = async () => {
        setLoading(true);

        try {
            const { products, totalPages, totalProducts } = await sellerService.fetchSellerProducts({
                debouncedProductName,
                currentPage,
                sortCriteria,
                minPrice,
                maxPrice,
            });
            setProducts(products);
            setTotalPages(totalPages);
            setTotalProducts(totalProducts);
            setSkeletonCount(totalProducts);

            // Scroll to the top after successfully fetching products
            window.scrollTo(0, 0);
        } catch (error) {
            console.error("Error fetching products:", error);
            setError('Failed to fetch products. Please try again.');
            setSkeletonCount(0); // Hide skeletons if an error occurs
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, debouncedProductName, sortCriteria, minPrice, maxPrice]);

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Function to sort products by highest and lowest prices, most recent and rating
    const handleSortChange = (sortValue: SortCriteria) => {
        setSortCriteria(sortValue); // Update the sort criteria
        setCurrentPage(1); // Reset to the first page
    };

    // Function to handle the filtering of products by price range
    const handleFilter = () => {
        if (!priceFilterRef.current) return; // Ensure the ref exists
    
        const min = parseFloat(String(priceFilterRef.current.minPrice)) || 0;
        const max = parseFloat(String(priceFilterRef.current.maxPrice)) || 1000000;
    
        // Apply the filter logic using min and max prices
        setMinPrice(min);
        setMaxPrice(max);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Functions to clear the minimum and maximum price inputs
    const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMinPrice(null); // Reset minPrice to null
        priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMaxPrice(null); // Reset maxPrice to null
        priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetSearch = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setProductName('');
        setCurrentPage(1);
    };

    const handleResetAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        // Call individual reset handlers
        handleResetMinPrice(e); 
        handleResetMaxPrice(e);
        handleResetSearch(e);
        setCurrentPage(1); // Reset page to 1
    };

    return (
        <>
            {/* Seller's Product Section Begin */}
            <div className="row">
                {/* product filtering on mobile devices */}
                <div className="col-12 m-b-20 container-filter-products-mobile">
                    <div className="btn-filter-products-mobile">
                        <button 
                            className="btn btn-md" 
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#collapseFilters" 
                            aria-expanded="false" 
                            aria-controls="collapseFilters"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#666666" viewBox="0 0 256 256">
                                <path d="M200,136a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,136Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z"></path>
                            </svg>
                            &nbsp;&nbsp;
                            <span className="text">Filter Products</span>
                        </button>
                    </div>
                    {/* product sorting on smaller devices*/}
                    <ProductSort
                        sortOptions={sortOptions}
                        sortCriteria={sortCriteria}
                        handleSortChange={handleSortChange}
                    />
                </div>

                <div className="col-12 col-md-3 col-sidebar-products">
                    <div className="sticky-lg-top">
                        <div id="collapseFilters" className="product-filters collapse d-md-block">
                            {/* price filter */}
                            <PriceFilter 
                                ref={priceFilterRef} 
                                onFilter={handleFilter}
                            />
                            {/* search products */}
                            <div className="filter-item">
                                <h4 className="title">Filter by keyword</h4>
                                <div className="product-search">
                                    <input 
                                        type="text" 
                                        id="input_filter_keyword" 
                                        value={productName} // Bind state to the input value 
                                        className="form-control form-input" 
                                        placeholder="Keyword"
                                        onChange={(e) => {
                                            setProductName(e.target.value);
                                            setCurrentPage(1);
                                        }}
                                    />                                   
                                    <button 
                                        type="button" 
                                        id="btnFilterByKeyword" 
                                        className="btn btn-md btn-filter-product"
                                        onClick={handleFilter}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <div style={loaderContainerStyle}>
                                                <PuffLoader size={20} color="#000" />
                                            </div>
                                        ) : (
                                            <>
                                                <Icon name="CiSearch" style={{ top: '-1.5px', position: 'relative' }} />
                                                 &nbsp;Filter
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row-custom"></div>
                    
                    </div>
                </div>
                
                <div id="productListProfile" className="col-12 col-md-9 col-content-products">
                    {/* product sorting on large devices*/}
                    <div className="clearfix container-filter-products m-b-20">
                        <ProductSort
                            sortOptions={sortOptions}
                            sortCriteria={sortCriteria}
                            handleSortChange={handleSortChange}
                        />
                    </div>

                    {/* resetting product filter */}
                    <div className="row">
                        <div className="col-12">
                            <PriceReset
                                minPrice={minPrice}
                                maxPrice={maxPrice}
                                searchKeyword={productName}
                                handleResetMinPrice={handleResetMinPrice}
                                handleResetMaxPrice={handleResetMaxPrice}
                                handleResetSearch={handleResetSearch}
                                handleResetAll={handleResetAll}
                            />
                        </div>
                    </div>

                    {/* products display */}           
                    <ProductDisplay
                        products={products}
                        loading={loading}
                        error={error}
                        skeletonCount={skeletonCount}
                        fetchProducts={fetchProducts}
                        setError={setError}
                        className="col-6 col-sm-4 col-md-4 col-lg-3 col-product"
                    />

                    {/* Pagination */}
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
};

export default SellerProducts;