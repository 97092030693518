import React, { useState } from "react";
import styled from "styled-components";
import ModalWrapper from "./ModalWrapper";
import Icon from "../icon/Icon";
import { CancelButton } from "../../utils/ModalStyles";
import { UserProps } from "../../types/Types";

interface MessageModalProps {
    user: UserProps;
    receiverId: number;
    onClose: () => void;
    onSend: (receiverId: number, messageText: string) => Promise<{ success: boolean }>;
    isOpen: boolean;
}

const MessageModal: React.FC<MessageModalProps> = ({ user, receiverId, onClose, onSend, isOpen }) => {
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSend = async () => {
        if (!message.trim()) {
            setErrorMessage("Message cannot be empty.");
            setTimeout(() => setErrorMessage(null), 3000);
            return;
        }

        setLoading(true);
    
        try {
            const response = await onSend(receiverId, message); // Await and handle the response
            if (response.success) { // Ensure success is explicitly checked
                setMessage("");
                setSuccessMessage("Message sent successfully!");
                setErrorMessage(null); // Clear any previous error
    
                // Clear the success message after 3 seconds
                setTimeout(() => setSuccessMessage(null), 3000);
            } else {
                throw new Error("Unexpected response structure or failure.");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setErrorMessage(
                error instanceof Error ? error.message : "Failed to send the message. Please try again."
            );
    
            // Clear the error message after 3 seconds
            setTimeout(() => setErrorMessage(null), 3000);
        } finally {
            setLoading(false); // Set loading to false when sending finishes
        }
    };

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
            <HeaderContainer>
                <MessageHeader>
                    Message {user.first_name} {user.last_name}
                </MessageHeader>
            </HeaderContainer>

            <TextArea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write your message here"
            />
                    
            <Button onClick={handleSend} disabled={loading}>
                {loading ? (
                    <Icon name="FaSpinner" size="1.2em" className="spin-icon" />
                ) : (
                    <>
                        <Icon name="FaPaperPlane" size="1.2em" style={{ position: "relative", top: "-2px" }} /> Send
                    </>
                )}
            </Button>
            <CancelButton type="button"onClick={onClose}>Cancel</CancelButton>

            {/* Conditionally render success or error messages */}
            {successMessage && <div style={{ marginTop: '10px', color: 'green' }}>{successMessage}</div>}
            {errorMessage && <div style={{ marginTop: '10px', color: 'red' }}>{errorMessage}</div>}
        </ModalWrapper>
    );
};

export default MessageModal;

const Button = styled.button<{ variant?: "red" | "modern" }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: ${(props) => (props.variant === "red" ? "none" : "1px solid #ddd")};
    border-radius: 12px;
    background-color: ${(props) =>
        props.variant === "red" ? "#dc3545" : "#f9f9f9"};
    color: ${(props) => (props.variant === "red" ? "#fff" : "#333")};
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) =>
        props.variant === "red" ? "#c82333" : "#f0f0f0"};
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
`;

const MessageHeader = styled.h3`
    font-size: 1.5rem; /* Default size for larger screens */
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.25rem; /* Medium screens */
    }

    @media (max-width: 480px) {
        font-size: 1rem; /* Small screens */
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem; /* Add spacing below the header */
`;