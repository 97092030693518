import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import styled from "styled-components";

// Components
import Sidebar from "../../components/admin/Sidebar";
import Header from "../../components/admin/Header";

const AdminLayout: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
        // Persist sidebar state based on screen size
        window.innerWidth > 768
    );
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    // Check authentication
    const isLoggedIn = sessionStorage.getItem("super-admin_token");

    // Function to toggle the sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Handle responsive sidebar
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const newIsMobile = screenWidth <= 768;
            
            setIsMobile(newIsMobile);
            
            // Auto-close sidebar on mobile
            if (newIsMobile) {
                setIsSidebarOpen(false);
            } else {
                // Open sidebar by default on desktop
                setIsSidebarOpen(true);
            }
        };

        // Add resize listener
        window.addEventListener('resize', handleResize);
        
        // Initial check
        handleResize();

        // Cleanup listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isLoggedIn ? (
        <AdminLayoutWrapper>
            {/* Sidebar */}
            <Sidebar 
                isOpen={isSidebarOpen} 
                toggleSidebar={toggleSidebar} 
            />

            {/* Main Content */}
            <MainContent 
                isSidebarOpen={isSidebarOpen} 
                isMobile={isMobile}
            >
                {/* Header */}
                <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />

                {/* Content Container */}
                <ContentWrapper>
                    {/* Nested Routes (content changes based on route) */}
                    <Outlet />
                </ContentWrapper>
            </MainContent>
        </AdminLayoutWrapper>
        ) : (
            <Navigate to="/super-admin/login" replace />
    );
};

export default AdminLayout;

// Styled Components
const AdminLayoutWrapper = styled.div`
    display: flex;
    height: 100vh;
    background-color: #f4f7f6;
    overflow-x: hidden;
`;

const MainContent = styled.main<{ 
    isSidebarOpen: boolean, 
    isMobile: boolean 
}>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;

    // Margin adjustment based on sidebar state and screen size
    margin-left: ${({ isSidebarOpen, isMobile }) => {
        if (isMobile) return '0';
        return isSidebarOpen ? '250px' : '80px';
    }};

    // Responsive padding
    @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`;

const ContentWrapper = styled.div`
    padding: 20px;
    flex: 1;
    overflow-y: auto;
    background-color: #f4f7f6;

    // Responsive padding
    @media (max-width: 768px) {
        padding: 10px;
    }
`;