import { ProductProps, UserProps, PaginationResponse, ProductResponse } from "../types/Types";
import { apiService } from "./api.types";
import { handleError } from "./errorHandler";

export const adminService = {
    // Get all users (shoppers/sellers)
    getUsers: async (
        type: 'sellers' | 'shoppers',
        page = 1,
        limit = 5,
        searchTerm?: string,
        filter?: string
    ): Promise<PaginationResponse<UserProps>> => {

        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('limit', limit.toString());

        if (searchTerm) params.append('searchTerm', searchTerm);
        if (filter) params.append('status', filter);

        const url =
            type === 'sellers'
                ? `/admin/get_all_sellers?${params.toString()}`
                : `/admin/get_all_shoppers?${params.toString()}`;
        try {
            return await apiService.get<PaginationResponse<UserProps>>(url);
        } catch (error) {
            handleError(error, 'Failed to fetch users');
            throw error; // Rethrow for caller awareness
        }
    },

    // Update user status
    updateUserStatus: async (
        userId: number,
        type: 'sellers' | 'shoppers',
        status: string
    ): Promise<UserProps> => {
        try {
            const response = await apiService.patch<UserProps>(`/admin/${type}/${userId}`, { status });
            return response; // Return User object directly
        } catch (error) {
            handleError(error, `Failed to update user status for user ID ${userId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Confirm seller
    confirmSeller: async (userId: number): Promise<UserProps> => {
        try {
            return await apiService.patch<UserProps>(`/admin/confirm_seller/${userId}`);
        } catch (error) {
            handleError(error, `Error confirming seller with ID ${userId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Activate product
    activateProduct: async (productId: number): Promise<ProductProps> => {
        try {
            // Call backend API to activate the product
            return await apiService.patch<ProductProps>(`/admin/activate_product/${productId}`);
        } catch (error) {
            handleError(error, `Error confirming product with ID ${productId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Deactivate product
    deactivateProduct: async (productId: number): Promise<ProductProps> => {
        try {
            // Call backend API to deactivate the product
            return await apiService.patch<ProductProps>(`/admin/deactivate_product/${productId}`);
        } catch (error) {
            handleError(error, `Error deactivating product with ID ${productId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Delete product
    deleteProduct: async (productId: number): Promise<void> => {
        try {
            // Call backend API to delete the product
            await apiService.delete(`/admin/delete_product/${productId}`);
        } catch (error) {
            handleError(error, `Error deleting product with ID ${productId}`);
            throw error; // Rethrow for caller awareness
        }
    },

    // Fetch all products with optional search and filtering
    getProducts: async (
        page = 1,
        limit = 10,
        searchTerm?: string,
        status?: string
    ): Promise<PaginationResponse<ProductProps>> => {
        const params = new URLSearchParams();
        params.append("page", page.toString());
        params.append("limit", limit.toString());

        if (searchTerm) params.append("searchTerm", searchTerm);
        if (status && status !== "all") params.append("status", status);

        const url = `/admin/get_all_products?${params.toString()}`;

        try {
            return await apiService.get<PaginationResponse<ProductProps>>(url);
        } catch (error) {
            handleError(error, "Failed to fetch products");
            throw error; // Rethrow for caller awareness
        }
    },

    // Edit product
    async editProduct(productId: number, productData: FormData): Promise<ProductResponse> {
        try {
            const response = await apiService.put<ProductResponse>(`/products/edit_product/${productId}`, productData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Check if the response status is 200 (Success)
            if (response.status === 200) {
                return response; // Return the updated product data
            } else {
                throw new Error(response.message || 'Error updating product');
            }
        } catch (error: unknown) {
            handleError(error, 'Error updating product');
            throw new Error('An unexpected error occurred');
        }
    },
};