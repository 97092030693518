import "./product.css";
import React from 'react';
import { Link } from 'react-router-dom';

// types
import { ProductProps } from '../../types/Types';

// components
import PriceFormatter from '../product filter/PriceFormatter';
import AddToCart from "../cart/AddToCart";
import WishlistButton from "../wishlist button/WishlistButton";
import ViewProductButton from "../misc/ViewProductButton";

interface ProductCardThriftProps {
    product: ProductProps;
}

const ProductCardThrift: React.FC<ProductCardThriftProps> = ({ product }) => {
    return (
        <div className="item swiper-slide">
            <div className="dot-image">
                <a href="#" className="product-permalink" onClick={(e) => { e.preventDefault()}}></a>
                <div className="thumbnail">
                    <img 
                        src={product.main_image}
                        alt={product.product_name}
                        loading="lazy"
                    />
                </div>
                <div className="thumbnail hover">
                    <img 
                        src={product.product_images[0]}
                        alt={product.product_name}
                        loading="lazy" 
                    />
                </div>
                <div className="actions">
                    <ul>
                        <li>
                            <WishlistButton productId={product.id} />
                        </li>
                        <li>
                            <AddToCart />
                        </li>
                        <ViewProductButton productId={product.id} />
                    </ul>
                </div>
                <div className="label">
                    <span>
                     {/*-{Math.round(((product.price - product.discountPrice) / product.price) * 100)}%*/}
                    </span>
                </div>
            </div>
            <div className="dot-info">
                <h2 className="dot-title">
                    <Link to={`/details`} state={{ productId: product.id }}>
                        {product.product_name}
                    </Link>
                </h2>
                <Link to={`/details`} state={{ productId: product.id }}>
                    <div className="product-price">
                        {/*<span className="before">GHS {product.discountPrice.toFixed(2)}</span>*/}
                        <PriceFormatter price={product.product_price} />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ProductCardThrift;