import React, { useEffect, useState } from "react";
import { Modal, ModalContent } from "../../utils/ModalStyles";

interface ModalWrapperProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ isOpen, onClose, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isOpen) {
            // Slight delay to ensure transition works
            timeoutId = setTimeout(() => {
                setIsVisible(true);
            }, 10);
            document.body.style.overflow = "hidden";
        } else {
            setIsClosing(true);
            timeoutId = setTimeout(() => {
                setIsVisible(false);
                setIsClosing(false);
                document.body.style.overflow = "";
            }, 300);
        }

        return () => {
            if (timeoutId) clearTimeout(timeoutId);
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    // Prevent rendering if not visible at all
    if (!isOpen && !isVisible) return null;

    const handleClose = (e: React.MouseEvent) => {
        // Prevent multiple close calls
        e.stopPropagation();
        onClose();
    };

    return (
        <Modal 
            onClick={handleClose}
            isVisible={isVisible}
            isClosing={isClosing}
        >
            <ModalContent 
                onClick={(e) => e.stopPropagation()} 
                isVisible={isVisible}
                isClosing={isClosing}
            >
                {children}
            </ModalContent>
        </Modal>
    );
};

export default ModalWrapper;