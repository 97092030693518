import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { PropagateLoader } from "react-spinners";
import Icon from '../../../../components/icon/Icon';

const SellerReviews = ({ sellerReviews, totalReviews, loading, pageSize, reviewsPage, setReviewsPage }) => {
    
    // Handle pagination (Next Page)
    const handleNextPage = () => {
        if (reviewsPage * pageSize < totalReviews) {
            setReviewsPage(reviewsPage + 1);
        }
    };

    // Handle pagination (Previous Page)
    const handlePreviousPage = () => {
        if (reviewsPage > 1) {
            setReviewsPage(reviewsPage - 1);
        }
    };

    // Check if "Next" button should be disabled
    const isNextDisabled = reviewsPage * pageSize >= totalReviews;


    // render loading states
    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <PropagateLoader color="var(--vr-color-main)" loading={loading} />
            </div>
        );
    }

    return (
        <>
            <div id="user-review-result" className="user-reviews">
                <div className="row row-product-items row-product">
                    {sellerReviews.length === 0 ? (
                        <div className="col-12">
                            <p className="text-center text-muted">No reviews found!</p>
                        </div>
                    ) : (        
                        sellerReviews.map((review) => (
                            <div className="col-6 col-sm-4 col-md-3 col-mds-5 col-product" key={review.id}>
                                <div className="product-item">
                                    <div className="row-custom product-multiple-image">
                                        <div className="img-product-container">
                                            <Link to={`/details`} state={{ data: review.id }}>    
                                                <img 
                                                    src={review.main_image} 
                                                    alt={review.product_name} 
                                                    className="img-fluid img-product ls-is-cached lazyloaded" 
                                                    loading="lazy"
                                                />
                                                <div className="thumbnail hover">
                                                    <img 
                                                        src={review.main_image} 
                                                        alt={review.product_name} 
                                                        className="img-fluid img-product img-second ls-is-cached lazyloaded" 
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row-custom item-details">
                                        <h3 className="product-title">
                                            <Link to={`/details`} state={{ data: review.id }}>
                                                {review.product_name}
                                            </Link>
                                        </h3>
                                        <p className="product-user-comment">
                                            <strong>Comment:</strong> {review.comment}
                                        </p>
                                        <div className="product-item-rating">
                                            <div className="rating">
                                                <strong>Rating:</strong> {review.rating}/5
                                            </div>
                                        </div>
                                        <p className="product-user-name">
                                            <strong>Reviewer:</strong> {review.name}
                                        </p>
                                    </div>
                                </div>
                            </div>       
                        ))
                    )}       
                    
                    {/* Pagination */}
                    <div className="col-12 m-t-15">
                        {sellerReviews.length > 0 ? ( // Check if there are reviews to display
                            <div className="float-right">
                                <div className="pagination-buttons-container">
                                    <button
                                        className="pagination-button"
                                        disabled={reviewsPage === 1 || loading}
                                        onClick={handlePreviousPage}
                                    >
                                        <Icon name="BiChevronLeft" /> Prev
                                    </button>
                                    <button
                                        className="pagination-button"
                                        disabled={isNextDisabled || loading}
                                        onClick={handleNextPage}
                                    >
                                        Next <Icon name="BiChevronRight" />
                                    </button>
                                </div>
                                <div className="row-custom">
                                    {/* User-friendly message when no more reviews */}
                                    {isNextDisabled && <p>No more reviews available.</p>}
                                </div>
                            </div>
                        ) : null} {/* Render nothing if there are no reviews */}
                    </div>
                </div>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default SellerReviews;