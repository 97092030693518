import React, { useState, useRef, useEffect } from "react";
import "./vendor.css";

const ScrollUpButton: React.FC = () => {
    const progressPathRef = useRef<SVGPathElement | null>(null);
    const [scrollPercent, setScrollPercent] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            const totalScroll = document.documentElement.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const scrollPercentage = (scrollPosition / totalScroll) * 100;
            setScrollPercent(scrollPercentage);

            setIsVisible(window.pageYOffset > 300); // Adjust the scroll threshold as needed
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Update the progress circle stroke offset
    useEffect(() => {
        if (progressPathRef.current) {
            const pathLength = progressPathRef.current.getTotalLength();
            progressPathRef.current.style.strokeDasharray = `${pathLength}`;
            progressPathRef.current.style.strokeDashoffset = `${pathLength - (scrollPercent / 100) * pathLength}`;
        }
    }, [scrollPercent]);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <a
            href="#"
            className={`scrollup ${isVisible ? "visible" : "hidden"}`}
            onClick={scrollToTop}
            style={{ display: "inline" }}
        >
            <div className="progress-section">
                <div className="progress-page has-tooltip is-active is-visible">
                    <div className="scrolltotop is-active is-visible">
                        <div className="arrow-top"></div>
                        <div className="arrow-top-line"></div>
                        <span className="tooltip">Back to Top</span>
                    </div>
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path
                            ref={progressPathRef}
                            className="progress-path"
                            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                        ></path>
                    </svg>
                </div>
            </div>
        </a>
    );
};

export default ScrollUpButton;