import React, { useState } from "react";
import { ProductProps } from "../../types/Types";
import PriceFormatter from "../product filter/PriceFormatter";
import { CancelButton } from "../../utils/ModalStyles";
import ModalWrapper from "./ModalWrapper";
import styled from "styled-components";
import Icon from "../icon/Icon";
import FullScreenImageViewer from "../misc/FullScreenImageViewer";
import ConfirmButton from "../admin/ConfirmButton";
import { adminService } from "../../services/adminService";

interface ViewProductModalProps {
    onClose: () => void;
    isOpen: boolean;
    product: ProductProps;
    formatCreatedAt: (dateString: string) => string;
}

const ViewProductModal: React.FC<ViewProductModalProps> = ({ isOpen, onClose, product, formatCreatedAt }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState("");

    const handleImageClick = (imageUrl: string) => {
        setFullScreenImage(imageUrl);
        setIsFullScreen(true);
    };

    const handleNextImage = () => {
        if (currentImageIndex < product.product_images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };

    const handlePrevImage = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    return (
        <>
            <ModalWrapper isOpen={isOpen} onClose={onClose}>
                <h2>Product Information</h2>
                {product ? (
                    <ProductDetails>
                        <ImageContainer>
                            <MainImage src={product.main_image} alt={product.product_name} onClick={() => handleImageClick(product.main_image)} />
                            <ImageGallery>
                                <GalleryButton onClick={handlePrevImage} disabled={currentImageIndex === 0}>
                                    &lt;
                                </GalleryButton>
                                    <ProductImage src={product.product_images[currentImageIndex]} alt={product.product_name} onClick={() => handleImageClick(product.product_images[currentImageIndex])} />
                                <GalleryButton onClick={handleNextImage} disabled={currentImageIndex === product.product_images.length - 1}>
                                    &gt;
                                </GalleryButton>
                            </ImageGallery>
                            </ImageContainer>
                        <DetailsContainer>
                            {/*<p><strong>ID:</strong> {product.id}</p>*/}
                            <p><strong>Name:</strong> {product.product_name}</p>
                            <p><strong>Price:</strong> <PriceFormatter price={product.product_price} /></p>
                            <p><strong>Stock:</strong> {product.product_quantity}</p>
                            <p><strong>Company Name:</strong> {product.company_name}</p>
                            <p><strong>Category:</strong> {product.product_category}</p>
                            <p><strong>Sub Category:</strong> {product.sub_category}</p>
                            <p><strong>Section:</strong> {product.product_section}</p>
                            <p><strong>Sub Section:</strong> {product.sub_section}</p>
                            <p><strong>Description:</strong> {product.product_description}</p>
                            <p><strong>Created at:</strong> {formatCreatedAt(product.created_at)}</p>
                        </DetailsContainer>
                    </ProductDetails>
                ) : (
                    <p>No product selected.</p> // Fallback message if no product is selected
                )}

                {/* Conditionally render the "Activate" or "Deactivate" button */}
                <CenteredButtonContainer>
                    {product.product_activated ? (
                        <ConfirmButton
                            id={product.id}
                            confirmAction={async (id) => {
                                await adminService.deactivateProduct(id); // Ignore returned value
                            }}
                            label="Deactivate Product"
                            iconName="BsCartX"
                            status={!product.product_activated ? "disapproved" : null}
                        />
                    ) : (
                        <ConfirmButton
                            id={product.id}
                            confirmAction={async (id) => {
                                await adminService.activateProduct(id); // Ignore returned value
                            }}
                            label="Activate Product"
                            iconName="BsCartCheck"
                            status={product.product_activated ? "approved" : null}
                        />
                    )}
                </CenteredButtonContainer>       
                <CancelButton type="button"onClick={onClose}>Cancel</CancelButton>
            </ModalWrapper>

            {/* Full-Screen Image Viewer */}
            <FullScreenImageViewer isOpen={isFullScreen} imageUrl={fullScreenImage} onClose={() => setIsFullScreen(false)} />
        </>
    );
};
  
export default ViewProductModal;

const ProductDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
`;

const ImageContainer = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const MainImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px; // Space between main image and gallery
    cursor: pointer;

    &:hover {
        opacity: 0.8; // Add a hover effect
    }
`;

const ImageGallery = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const ProductImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
        opacity: 0.8; // Add a hover effect
    }
`;

const GalleryButton = styled.button`
    border: none;
    cursor: pointer;
    font-size: 2em;
    color: #007bff;
    color: #333;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 10px;

    &:disabled {
        color: #ccc; // Change color when disabled
        cursor: not-allowed;
    }
    
    &:hover {
        background: rgba(255, 255, 255, 1); /* Solid background on hover */
    }

    &:first-of-type {
        left: -20px; // Position for the left button
    }

    &:last-of-type {
        right: -20px; // Position for the right button
    }
`;

const DetailsContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin: 8px 0;
        font-size: 14px;
        color: #333;
    }

    p:first-child {
        margin-top: 0; /* Remove top margin for the first <p> element */
    }

    strong {
        color: #007bff; // Highlight the labels
    }
`;

const CenteredButtonContainer = styled.div`
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically if needed */
    margin-top: 0.5rem;     /* Add spacing above the button */
    margin-bottom: 0.5rem;
`;