import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface AccessDeniedModalProps {
    userRole: 'seller' | 'shopper';
    userStatus: 'blocked' | 'pending' | 'inactive';
    onClose: () => void;
}

interface ModalOverlayProps {
    isVisible: boolean;
}

interface ModalContainerProps {
    isVisible: boolean;
}

const AccessDeniedModal: React.FC<AccessDeniedModalProps> = ({ userRole, userStatus, onClose }) => {
    // Determine the content to display based on the status and role
    let title = "Access Denied";
    let message = "Your account does not meet the required status to access this page.";
    let buttonText = "Go to the Homepage";
    let showCompleteProfile = false; // Flag to show the "Complete your profile" button
    let showContactSupport = false; // Flag to show the "Contact Support" button

    if (userRole === "seller") {
        switch (userStatus) {
            case "blocked":
                title = "Account Blocked";
                message = "Your account has been blocked due to violations of our terms of service. Please contact support for further assistance.";
                showContactSupport = true; // Show Contact Support button
                break;
            case "pending":
                title = "Verification Pending";
                message = `
                    Your account verification is still in progress. <br />
                    In order to sell your products on this platform, you must be a verified member. <br />
                    Verification is a one-time process. This verification process is necessary because of spammers and fraud. <br />
                    Our team will notify you once the process is complete.
                `;
                break;
            case "inactive":
                title = "Access Denied";
                message = "Your account is currently inactive. Please complete your profile to continue.";
                showCompleteProfile = true;
                break;
        }
    } else if (userRole === "shopper") {
        switch (userStatus) {
            case "blocked":
                title = "Account Blocked";
                message = "Your account has been blocked due to violations of our terms of service. Please contact support for further assistance.";
                showContactSupport = true;
                break;
            case "pending":
                title = "Verification Pending";
                message = "Your account is currently inactive. Please activate your account to continue.";
                break;
            case "inactive":
                title = "Access Denied";
                message = "You do not have permission to access this page.";
                break;
        }
    }

    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        setIsVisible(true); // Start the animation when the modal mounts
        return () => setIsVisible(false); // Clean up animation when unmounting
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        setTimeout(onClose, 300); // Delay the actual close callback to allow animation to complete
    };

    return (
        <ModalOverlay onClick={handleClose} isVisible={isVisible}>
            <ModalContainer 
                onClick={(e) => e.stopPropagation()} 
                isVisible={isVisible}
            >
                <CloseButton onClick={handleClose}>&times;</CloseButton>
                <ModalHeader>{title}</ModalHeader>
                
                <ModalBody 
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                
                <ButtonContainer>
                    {/* Inactive Status */}
                    {userStatus === "inactive" && showCompleteProfile && (
                        <Button 
                            to="/complete-profile" 
                            className="secondary"
                            onClick={handleClose}
                        >
                            Complete Your Profile
                        </Button>
                    )}

                    {/* Pending Status */}
                    {userStatus === "pending" && (
                        <Button 
                            to="/" 
                            className="primary"
                            onClick={onClose}
                        >
                            {buttonText}
                        </Button>
                    )}

                    {/* Blocked Status */}
                    {userStatus === "blocked" && showContactSupport && (
                        <Button 
                            to="/contact" 
                            className="secondary"
                            onClick={handleClose}
                        >
                            Contact Support
                        </Button>
                    )}
                </ButtonContainer>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default AccessDeniedModal;



const ModalOverlay = styled.div<ModalOverlayProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
    transition: opacity 0.3s ease-in-out;
`;

const ModalContainer = styled.div<ModalContainerProps>`
    background: white;
    border-radius: 8px;
    max-width: 500px;
    max-width: 500px;
    width: 90%;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: ${({ isVisible }) =>
        isVisible ? "translateY(0)" : "translateY(-50px)"};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: all 0.3s ease-in-out;
`;

const ModalHeader = styled.h2`
    margin: 0 0 10px;
    font-size: 20px;
    text-align: center;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
`;

const ModalBody = styled.div`
    font-size: 16px;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
`;

const Button = styled(Link)`
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    &.primary {
        background: #2bcbba;
        color: white;
        &:hover {
            background-color: #008b8b;
        }
    }
    &.secondary {
        background: #f8f9fa;
        color: #333;
        border: 1px solid #ddd;
        &:hover {
            background-color: #f0f0f0;
        }
    }
`;