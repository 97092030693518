import React, { useState } from "react";
import styled from "styled-components";
import Icon from "../icon/Icon";
import { ClockLoader } from "react-spinners";


interface ConfirmButtonProps {
    id: number; // Could be sellerId or productId
    confirmAction: (id: number) => Promise<void>; // Generic async confirmation logic
    label: string; // Button label, e.g., "Approve Seller", "Approve Product"
    iconName: "BsCartCheck" | "BsCartX" | "BiUserCheck" | "MdDeleteForever"; // Icon name, e.g., "BiUserCheck", "BiCartCheck"
    status?: "approved" | "disapproved" | null;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({ id, confirmAction, label, iconName, status = null }) => {
    const [isConfirming, setIsConfirming] = useState(false); // Loading spinner state
    const [isConfirmed, setIsConfirmed] = useState(false); // For showing confirmed status
    const [isDisapproved, setIsDisapproved] = useState(false); // For showing disapproved status

    const handleConfirm = async () => {
        setIsConfirming(true);
        try {
            await confirmAction(id); // Call the confirm action
            setIsConfirmed(label === "Activate Product"); // True if activated
            setIsDisapproved(label === "Deactivate Product"); // True if deactivated
        } catch (error) {
            console.error(`Error during confirmation:`, error);
        } finally {
            setIsConfirming(false);
        }
    };

    return (
        <Button
            onClick={(e) => {
                e.stopPropagation();
                handleConfirm();
            }}
            disabled={isConfirming || isConfirmed || isDisapproved} // Disable based on state
        >
            {isConfirming ? (
                <ClockLoader size={8} color="#fff" />
            ) : isConfirmed ? (
                <>
                    Approved <Icon name="FcApproval" size="1.2em" style={{ color: "#28a745" }} />
                </>
            ) : isDisapproved ? (
                <>
                    Deactivated <Icon name="FcCancel" size="1.2em" style={{ color: "#dc3545" }} />
                </>
            ) : (
                <>
                    <Icon name={iconName} size="1.2em" style={{ position: "relative", top: "-1px" }} />
                    {label}
                </>
            )}
        </Button>
    );
};

export default ConfirmButton;

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: ${(props) => (props.disabled ? "#ccc" : "#f9f9f9")}; /* Disabled background color */
    color: ${(props) => (props.disabled ? "#888" : "#333")}; /* Disabled text color */
    font-size: 1rem;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}; /* Disable cursor */
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) => (props.disabled ? "#ccc" : "#f0f0f0")}; /* Hover effect */
    }

    &:disabled {
        background-color: #ccc; /* Disabled state background */
        color: #888; /* Disabled state text color */
        cursor: not-allowed; /* Disable hover effect */
    }
`;