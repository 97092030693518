// types component
export type CategoryProps = {
    id: number;
    product_category_name: string;
};

export type SectionProps = {
    id: number;
    product_section_name: string;
};

export type ProductProps = {
    id: number;
    product_name: string;
    product_mode: string;
    product_description: string;
    product_quantity: number;
    product_price: number;
    product_images: string[];
    product_section: string;
    sub_section: string;
    main_image: string;
    product_category: string;
    sub_category: string;
    company_name: string;
    seller_id: number;
    product_activated: boolean;
    created_at: string;
};

export interface ProductFormData {
    product_id: number;
    product_name: string;
    product_mode: string;
    product_quantity: number;
    product_price: number;
    product_category: string;
    sub_category: string;
    product_section: string;
    sub_section: string;
    product_description: string;
    existing_images: string[]; // URLs of existing images from the database
    main_image: string;
    new_images: File[]; // Files for newly uploaded images
}

export type ProductDetailsProps = {
    product_category: string;
    product_description: string;
    product_image: string;
    product_name: string;
    product_price: number;
    product_section: string;
    product_mode: string;
    company_name: string;
    product_quantity: number;
    seller_id: number;
    id: number;
};

export interface ProductResponse {
    status: number;
    message: string;
    product: ProductProps;
}

export interface Message {
    id: number;
    sender_role: string;
    receiver_role: string;
    message_text: string;
    created_at: string;
    timestamp: string;
}

export interface ChatListItem {
    role_id: number;
    role_type: 'shopper' | 'seller';
    chat_type: 'Receiver' | 'Sender';
    name: string; // Added name field
    profile_picture?: string;
    last_message?: string;
    is_online: boolean;
    last_seen: string;
}

export interface PaginationResponse<T> {
    data: T[];
    total: number;
    currentPage: number;
    totalPages: number;
}

export interface UserProps {
    id: number;
    first_name: string;
    last_name: string; 
    email: string;
    location?: string;  
    contact?: string;
    company_name?: string; // Optional (not always present in the response - specific to sellers)
    status?: string;
    products?: number;  // Optional (not always present in the response - specific to sellers)
    revenue?: string;   // Optional (not always present in the response - specific to sellers)
    purchases?: number; // Optional (not always present in the response - specific to shoppers)
    totalSpend?: string; // Optional (not always present in the response - specific to shoppers)
    activated?: boolean;
    shop_description?: string; // Added (specific to sellers)
    profile_picture?: string;
    created_at: string; 
    business_category?: string; // Added (specific to sellers)
}

export interface UserCardProps {
    user: UserProps;
    type: "sellers" | "shoppers";
    onToggleStatus: (id: number, type: "sellers" | "shoppers") => void;
    onMessage: (user: UserProps) => void;
    onClick?: () => void;
    isLoading?: boolean; // Add loading prop
}

export interface UsersState {
    sellers: UserProps[];
    shoppers: UserProps[];
}

export interface ShopDetailsProps {
    company_name: string;
    location: string;
    shop_description: string;
    business_category: string;
    status?: string;
}

export interface ShopDetailsResponse {
    status: number;
    message: string;
    shop: ShopDetailsProps;
}

export interface SellerDetailsProps {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    company_name: string;
    location: string;
    contact: string;
    business_category: string;
    activated: boolean;
    created_at: string;
    shop_description: string;
    profile_picture: string | null;
    is_online: boolean;
    last_seen: string;
}

export interface SellerDetailsResponse {
    status: number;
    message: string;
    user: SellerDetailsProps;
}

// Sorting options mapping
export const sortOptions = {
    most_recent: 'Most Recent',
    lowest_price: 'Lowest Price',
    highest_price: 'Highest Price',
    rating: 'Highest Rating',
} as const;

// Sort criteria type
export type SortCriteria = 'most_recent' | 'lowest_price' | 'highest_price' | 'rating';