import React from "react";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import pay from "../../assets/img/pay.png";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

const Donate = () => {
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Donate" },
    ];

    return (
        <>
            <HeaderCopy />
        
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="blog-content">
                                <Breadcrumb items={breadcrumbItems} />

                                <h1 className="page-title">Donate</h1>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="page-text-content post-text-responsive">
                                            <p>
                                                <img 
                                                    src={pay} 
                                                    alt="" 
                                                    width=" 570" 
                                                    height="800"
                                                />
                                            </p>  
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <Footer />
        </>
    );
};
      
export default Donate;