import React from "react";
import Footer from "../../../components/footer/Footer";
import HeaderCopy from "../../../components/header/HeaderCopy";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";

const Payment = () => {
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Help Center", to: "/help" },
        { label: "PAYMENT" },  
    ];

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="support">
                                <Breadcrumb items={breadcrumbItems} />
                        
                                <h1 className="title-category">
                                    PAYMENT
                                </h1>

                                <div className="col-sm-12 col-lg-9">
                                    <div className="help-center-collapse">
                                        <a 
                                            href="#related_help_topics" 
                                            data-toggle="collapse" 
                                            className="collapse-title"
                                        >
                                            Related Help Topics
                                            <div className="float-right">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="mds-svg-icon" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path>
                                                </svg>
                                            </div>
                                        </a>
                                
                                        <div className="row">
                                            <div className="col-md-3 hide-mobile">
                                                <div className="all-help-topics">
                                                    <Link to="/help-center">
                                                        <i className="icon-angle-left"></i>
                                                        All Help Topics
                                                    </Link>
                                                </div>
                                                <ul className="ul-support-articles">
                                                    <li className="active">
                                                        <a href="/payment">
                                                            WHAT PAYMENTS DO YOU ACCEPT?
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/payment">
                                                            HOW DO I PAY WITH MOBILE MONEY
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                            
                                        <div id="related_help_topics" className="collapse">
                                            <ul className="ul-support-articles">
                                                <li>
                                                    <a href="/payment">
                                                        WHAT PAYMENTS DO YOU ACCEPT?
                                                    </a>
                                                </li>
                                                <li className="active">
                                                    <a href="/payment">
                                                        HOW DO I PAY WITH MOBILE MONEY
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="article-title">
                                    WHAT PAYMENTS DO YOU ACCEPT?
                                </h2>
                                <div className="article-content">
                                    <p>
                                        <span>
                                            We accept various payment methods, including:
                                        </span>
                                    </p>
                                    <ul className="misc">
                                        <li>
                                            <span>credit/debit cards,</span>
                                        </li>
                                        <li>
                                            <span>&nbsp;Mobile money</span>
                                        </li>
                                        <li>
                                            <span>&nbsp;Bank transfer</span>
                                        </li>
                                        <li>
                                            <span>and other secure online payment gateways.</span>
                                        </li>
                                    </ul>
                                    <p>
                                        <span>&nbsp;At checkout, you will be presented with the available payment options.</span>
                                    </p>
                                </div>
                                <h2 className="article-title">
                                    HOW DO I PAY WITH MOBILE MONEY
                                </h2>
                                <div className="article-content">
                                    <p>AFTER ADDING ORDER AND PROCEEDING TO CHECKOUT,</p>
                                    <ul className="misc">
                                        <li>Add your shipping address and proceed to payment&nbsp;</li>
                                        <li>on payment method select PAYSTACK</li>
                                        <li>choose MOBILE MONEY</li>
                                        <li>Select your network provider from the drop down</li>
                                        <li>input your phone number&nbsp;</li>
                                        <li>and click proceed</li>
                                        <li>approve the request on your phone and order will be placed!</li>
                                    </ul>
                                </div>
                                <div className="need-more-help need-more-help-article">
                                    <h3 className="title">
                                        Still have questions?
                                    </h3>
                                    <span className="text-muted">
                                        If you still have a question, you can submit a support request here.
                                    </span>
                                    <Link to="/contact" className="btn btn-lg btn-custom">
                                        <svg width="18" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="#fff" className="mds-svg-icon">
                                            <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                                        </svg>
                                        Contact Support 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Payment;