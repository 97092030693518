import React from "react";
import { Link, Outlet } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Icon from "../../components/icon/Icon";

const Help = () => {
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Help Center" },
    ];

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="support">
                                <Breadcrumb items={breadcrumbItems} />

                                <h1 className="page-title main-title"><strong>How can we help?</strong></h1>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="search-container">
                                            <div className="search">
                                                <form action="/#" method="get">
                                                    <input 
                                                        type="text" 
                                                        name="q" 
                                                        className="form-control form-input" 
                                                        placeholder="Search" 
                                                        required 
                                                    />
                                                    <button type="submit">
                                                        <Icon name="CiSearch" />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Link to="/help-center/payment" className="a-box-support">
                                            <div className="box-support">
                                                <h3 className="title">PAYMENT</h3>
                                                <span>2 Articles</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-sm-4">
                                        <Link to="/help-center/orders" className="a-box-support">
                                            <div className="box-support">
                                                <h3 className="title">ORDERS</h3>
                                                <span>2 Articles</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-sm-4">
                                        <Link to="/help-center/faq" className="a-box-support">
                                            <div className="box-support">
                                                <h3 className="title">FAQ</h3>
                                                <span>2 Articles</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="need-more-help">
                                            <h3 className="title">Need more help?</h3>
                                            <span className="text-muted">If you didn't find what you were looking for, you can submit a support request here.</span>
                                            <Link to="/contact" className="btn btn-lg btn-custom">
                                                <svg width="18" height="18" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg" fill="#fff" className="mds-svg-icon">
                                                    <path d="M896 0q182 0 348 71t286 191 191 286 71 348-71 348-191 286-286 191-348 71-348-71-286-191-191-286-71-348 71-348 191-286 286-191 348-71zm0 128q-190 0-361 90l194 194q82-28 167-28t167 28l194-194q-171-90-361-90zm-678 1129l194-194q-28-82-28-167t28-167l-194-194q-90 171-90 361t90 361zm678 407q190 0 361-90l-194-194q-82 28-167 28t-167-28l-194 194q171 90 361 90zm0-384q159 0 271.5-112.5t112.5-271.5-112.5-271.5-271.5-112.5-271.5 112.5-112.5 271.5 112.5 271.5 271.5 112.5zm484-217l194 194q90-171 90-361t-90-361l-194 194q28 82 28 167t-28 167z"></path>
                                                </svg>
                                                Contact Support
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                {/* Outlet for rendering subroutes */}
                                <div className="col-md-9">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
            <Footer />
        </>
    );
};

export default Help;