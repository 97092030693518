import React, { useEffect, useState } from "react";
import { GridLoader } from "react-spinners";
import { Route, Routes, NavLink, Navigate, useLocation } from "react-router-dom";
import axios from 'axios';

// Components
import Footer from "../../components/footer/Footer";
import HeaderCopy from "../../components/header/HeaderCopy";
import Icon from '../../components/icon/Icon';

// sub routes
import SellerProducts from "./products/SellerProducts";
import SellerShopDetails from "./shop-details/SellerShopDetails";
import SellerWishlistProducts from "./wishlists/SellerWishlistProducts";
import SellerReviews from "./reviews/SellerReviews";
import Followers from "./followers/Followers";
import Following from "./following/Following";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";


const Profile = () => {
    const location = useLocation();
    let sellerId = location.state?.sellerId || sessionStorage.getItem("seller_id");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pageSize] = useState(10); // Number of items per page
    const [allProducts, setAllProducts] = useState(Number(sessionStorage.getItem("total")) || 0);
    const [shopInfo, setShopInfo] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        shop_description: '',
        created_at: '',
        file: null,
        image_cover: null,
    });

    // State for reviews and wishlist
    const [totalReviews, setTotalReviews] = useState(0);
    const [sellerReviews, setSellerReviews] = useState([]);
    const [reviewsPage, setReviewsPage] = useState(1); // Current page
    const [totalProducts, setTotalProducts] = useState(0); // total products in wishlist
    const [wishlistProducts, setWishlistProducts] = useState([]);
    const [wishlistPage, setWishlistPage] = useState(1); // current page

    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Profile" },
    ];

    // If sellerId exists in state, save it to sessionStorage for future use
    useEffect(() => {
        if (location.state?.sellerId) {
            sessionStorage.setItem("seller_id", location.state.sellerId);
        }
    }, [location.state]);

    useEffect(() => {
        if (allProducts) {
            sessionStorage.setItem("total", allProducts.toString());
        }
    }, [allProducts]);
    
    // Fetch seller and shop details, reviews, and wishlist products
    useEffect(() => {
        const fetchSellerData = async () => {
            if (!sellerId) return; // Exit if sellerId is not defined
            setLoading(true); // Set loading state
            try {
                // Use Promise.all to fetch both APIs concurrently
                const [sellerResponse, shopResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_seller_info/${sellerId}`),
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_shop_details/${sellerId}`)
                ]);
    
                // Set fetched data to respective states
                setFormData(sellerResponse.data.user);
                setShopInfo(shopResponse.data.shop);
            } catch (error) {
                console.error('Error fetching seller or shop data:', error);
                setError('Error fetching seller or shop information');
            } finally {
                setLoading(false); // Reset loading state
            }
        };
    
         // Scroll to the top of the page
        window.scrollTo(0, 0);

        fetchSellerData();
    }, [sellerId]);

    useEffect(() => {
        const fetchReviewsAndWishlist = async () => {
            if (!sellerId) return; // Exit if sellerId is not defined

            setLoading(true); // Set loading to true before fetching data
            try {
                const [reviewsResponse, wishlistResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_product_reviews/${sellerId}`, {
                        params: { limit: pageSize, offset: (reviewsPage - 1) * pageSize }
                    }),
                    axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_wishlist_product/${sellerId}`, {
                        params: { limit: pageSize, offset: (wishlistPage - 1) * pageSize }
                    })
                ]);
                setSellerReviews(reviewsResponse.data.reviews);
                setTotalReviews(reviewsResponse.data.totalCount);
                setWishlistProducts(wishlistResponse.data.wishlistProducts);
                setTotalProducts(wishlistResponse.data.totalCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchReviewsAndWishlist();
    }, [sellerId, reviewsPage, wishlistPage, pageSize]);

    // Calculate the reviews and wishlists to display based on the current page
    const displayedWishlists = wishlistProducts.slice((wishlistPage - 1) * pageSize, wishlistPage * pageSize);
    const displayedReviews = sellerReviews.slice((reviewsPage - 1) * pageSize, reviewsPage * pageSize);

    // Function to format created_at date
    const formatCreatedAt = (dateString) => {
        const date = new Date(dateString);
        const options = { month: 'long', year: 'numeric' }; // e.g., February 2024
        return date.toLocaleDateString(undefined, options);
    };

    const timeAgo = (dateString) => {
        const now = new Date();
        const lastSeenDate = new Date(dateString);
        const diffInSeconds = Math.floor((now - lastSeenDate) / 1000); // Difference in seconds
    
        if (diffInSeconds < 60) return `${diffInSeconds === 1 ? '1 second' : `${diffInSeconds} seconds`} ago`;
    
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        if (diffInMinutes < 60) return `${diffInMinutes === 1 ? '1 minute' : `${diffInMinutes} minutes`} ago`;
    
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${diffInHours === 1 ? '1 hour' : `${diffInHours} hours`} ago`;
    
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays < 7) return `${diffInDays === 1 ? '1 day' : `${diffInDays} days`} ago`;
    
        const diffInWeeks = Math.floor(diffInDays / 7);
        if (diffInWeeks < 4) return `${diffInWeeks === 1 ? '1 week' : `${diffInWeeks} weeks`} ago`;
    
        const diffInMonths = Math.floor(diffInDays / 30);
        if (diffInMonths < 12) return `${diffInMonths === 1 ? '1 month' : `${diffInMonths} months`} ago`;
    
        const diffInYears = Math.floor(diffInMonths / 12);
        return `${diffInYears === 1 ? '1 year' : `${diffInYears} years`} ago`;
    };

    return (
        <>
            <HeaderCopy />

            <div id="wrapper">
                <div className="container">
                    {/* Breadcrumb and Profile Details */}
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                    </div>
                    {/* Loading Indicator */}
                    {(!formData || !shopInfo || !sellerReviews || !wishlistProducts) ? (
                        <div style={loaderContainerStyle}>
                            <GridLoader color="var(--vr-color-main)" loading={true} />
                        </div>
                    ) : (
                        <>
                            {/* Profile Details */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="profile-page-top">
                                        <div className="row-custom">
                                            <div className="profile-details">
                                                <div className="left">
                                                    <img 
                                                        src={formData.profile_picture} 
                                                        alt="Profile" 
                                                        className="img-profile"
                                                        style={{ maxWidth: 'none' }}
                                                    />
                                                </div>
                                                <div className="right">
                                                    <div className="row-custom row-profile-username">
                                                        <h1 className="username">
                                                            {formData.company_name}
                                                        </h1>
                                                        {formData.status === 'active' && (
                                                            <i className="icon-verified icon-verified-member"></i>
                                                        )}
                                                    </div>
                                                    <div className="row-custom">
                                                        <p className="p-last-seen">
                                                            {formData.is_online ? (
                                                                <span className="last-seen last-seen-online">
                                                                    <Icon 
                                                                        name="FaCircle" 
                                                                        color="#85cc84" 
                                                                        style={{ marginRight: '5px', position: 'relative', top: '-1.2px' }} 
                                                                    />
                                                                    Online
                                                                </span>
                                                            ) : (
                                                                <span className="last-seen last-seen-offline">
                                                                    <Icon 
                                                                        name="FaCircle" 
                                                                        color="#ccc" 
                                                                        style={{ marginRight: '5px', position: 'relative', top: '-1.2px' }} 
                                                                    />
                                                                    Last seen: &nbsp;{timeAgo(formData.last_seen)}
                                                                </span>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="row-custom">
                                                        <p className="description">{formData.shop_description}</p>
                                                    </div>
                                                    <div className="row-custom user-contact">
                                                        <span className="info">Member since&nbsp;{formatCreatedAt(formData.created_at)}</span>
                                                    </div>
                                                    <div className="profile-rating"></div>
                                                    <div className="row-custom profile-buttons">
                                                        <div className="buttons">
                                                            <button className="btn btn-md btn-outline-gray" data-toggle="modal" data-target="#loginModal">
                                                                <Icon name="FaEnvelope" color="#666" style={{ marginRight: '8px', position: 'relative', top: '-1px' }} />
                                                                Ask Question
                                                            </button>
                                                            <button className="btn btn-md btn-outline-gray" data-toggle="modal" data-target="#loginModal">
                                                                <Icon name="FaUserPlus" color="#666" style={{ marginRight: '8px', position: 'relative', top: '-1px' }} />
                                                                Follow
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="products" className="row-custom"></div>
                                        <div className="row-custom report-seller-sidebar-mobile">
                                            <button 
                                                type="button" 
                                                className="button-link text-muted link-abuse-report link-abuse-report-button display-inline-flex align-items-center" 
                                                data-toggle="modal" 
                                                data-target="#reportSellerModal"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512" fill="currentColor">
                                                    <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path>
                                                </svg>
                                                &nbsp;Report this seller
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            {/* Products/wishlist/reviews */}
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs nav-tabs-horizontal nav-tabs-profile" role="tablist">
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="products"
                                                activeClassName="active"
                                            >
                                                Products <span className="count">({allProducts})</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="shop-details"
                                                activeClassName="active"
                                            >
                                                Shop Details
                                            </NavLink>
                                        </li>                 
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="followers"
                                                activeClassName="active"
                                            >
                                                Followers <span className="count">(0)</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="following"
                                                activeClassName="active"
                                            >
                                                Following <span className="count">(0)</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="wishlists"
                                                activeClassName="active"
                                            >
                                                Wishlist <span className="count">({totalProducts})</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink 
                                                className="nav-link" 
                                                to="reviews"
                                                activeClassName="active"
                                            >
                                                Reviews <span className="count">({totalReviews})</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                {/* Seller detailed content container */}
                                <div className="col-12">
                                    <div className="sidebar-tabs-content">
                                        <Routes>
                                            <Route 
                                                path="/" 
                                                element={
                                                    <Navigate to="products" />
                                                } 
                                            />
                                            <Route 
                                                path="products" 
                                                element={
                                                    <SellerProducts 
                                                        sellerId={sellerId}
                                                        setTotalProducts={setAllProducts} // Pass the setter function
                                                    />
                                                } 
                                            />
                                            <Route 
                                                path="shop-details" 
                                                element={
                                                    <SellerShopDetails 
                                                        shopInfo={shopInfo}
                                                    />
                                                } 
                                            />
                                            <Route path="followers" element={<Followers />} />
                                            <Route path="following" element={<Following />} />
                                            <Route 
                                                path="wishlists"
                                                element={
                                                    <SellerWishlistProducts 
                                                        wishlistProducts={displayedWishlists} // Pass the current page wishlist products
                                                        totalProducts={totalProducts} // Pass total wishlist products
                                                        pageSize={pageSize} // Pass page size
                                                        loading={loading}
                                                        wishlistPage={wishlistPage} // Pass current page
                                                        setWishlistPage={setWishlistPage} // Pass function to update current page
                                                    />
                                                } 
                                            />
                                            <Route 
                                                path="reviews" 
                                                element={
                                                    <SellerReviews 
                                                        sellerReviews={displayedReviews} // Pass the current page reviews
                                                        totalReviews={totalReviews} // Pass total reviews
                                                        pageSize={pageSize} // Pass page size
                                                        loading={loading}
                                                        reviewsPage={reviewsPage} // Pass current page
                                                        setReviewsPage={setReviewsPage} // Pass function to update current page
                                                    />
                                                }
                                            />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Footer />
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default Profile;