import React, { useEffect, useState } from "react";
import axios from "axios";
import StarRating from "../rating/StarRating";

interface Review {
    userId: string;
    rating: number;
    comment: string;
    date: string;
}

interface StarsProps {
    productId: number;
}

const Stars: React.FC<StarsProps> = ({ productId }) => {
    const [reviews, setReviews] = useState<Review[]>([]);
    const [averageRating, setAverageRating] = useState<number>(0);

    // Fetches reviews from the backend
    const fetchReviews = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_Base_url}/products/get_reviews?productId=${productId}`
            );
            setReviews(res.data.reviews);
            setAverageRating(res.data.averageRating);
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, [productId]);

    return (
        <>
            <StarRating rating={averageRating} onRatingChange={() => {}} />
        </>
    );
};

export default Stars;