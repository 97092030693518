import { apiService } from "./api.types";
import { ShopDetailsProps, ShopDetailsResponse, SellerDetailsProps, SellerDetailsResponse, 
    ProductProps, ProductResponse } from "../types/Types";
import { handleError } from "./errorHandler";

export const sellerService = {
    async getShopDetails(): Promise<ShopDetailsProps> {
        try {
            const response = await apiService.get<ShopDetailsResponse>('/sellers/get_seller_shop_details');
            
            // Check if the response status is 200 (Success) before extracting shop data
            if (response.status === 200 && response.shop) {
                return response.shop; // Return the shop data
            } else {
                throw new Error(response.message || 'Error fetching shop details');
            }
        } catch (error: unknown) {
            handleError(error, 'Error fetching shop details');
            throw new Error('An unexpected error occurred');
        }
    },

    async updateShopDetails(shopDetails: ShopDetailsProps) {
        try {
            const response = await apiService.post('/sellers/update_shop', shopDetails);
            return response;
        } catch (error: unknown) {
            handleError(error, 'Error updating shop settings');
            throw new Error('An unexpected error occurred');
        }
    },

    async getSellerDetails(): Promise<SellerDetailsProps> {
        try {
            const response = await apiService.get<SellerDetailsResponse>("/sellers/get_seller_details");

            // Ensure the response contains valid user data
            if (response.user) {
                return response.user; // Return seller details object
            } else {
                throw new Error("User not found");
            }
        } catch (error: unknown) {
            handleError(error, "Error fetching seller details");
            throw new Error("An unexpected error occurred");
        }
    },

    fetchReviewsAndWishlist: async ({
        reviewsPage,
        wishlistPage,
        pageSize,
    }: {
        reviewsPage: number;
        wishlistPage: number;
        pageSize: number;
    }) => {
        try {
            const [reviewsResponse, wishlistResponse] = await Promise.all([
                apiService.get<{ reviews: any[]; totalCount: number }>(
                    `/sellers/get_seller_product_reviews`,
                    {
                        params: { limit: pageSize, offset: (reviewsPage - 1) * pageSize },
                    }
                ),
                apiService.get<{ wishlistProducts: any[]; totalCount: number }>(
                    `/sellers/get_seller_wishlist_products`,
                    {
                        params: { limit: pageSize, offset: (wishlistPage - 1) * pageSize },
                    }
                ),
            ]);

            return {
                reviews: reviewsResponse.reviews,
                totalReviews: reviewsResponse.totalCount,
                wishlistProducts: wishlistResponse.wishlistProducts,
                totalProducts: wishlistResponse.totalCount,
            };
        } catch (error) {
            handleError(error, 'Failed to fetch seller reviews and wishlist products.');
            throw error; // Re-throw to allow component-level handling if necessary
        }
    },

    async updateSellerDetails(sellerDetails: Partial<SellerDetailsProps>): Promise<void> {
        try {
            await apiService.post("/sellers/update_seller", sellerDetails);
        } catch (error: unknown) {
            handleError(error, "Error updating seller details");
            throw new Error("An unexpected error occurred");
        }
    },

    async removeProfilePicture(): Promise<void> {
        try {
            await apiService.post("/sellers/remove_profile_picture", {});
        } catch (error: unknown) {
            throw new Error("Failed to remove profile picture.");
        }
    },

    async fetchSellerProducts({
        debouncedProductName,
        currentPage = 1,
        sortCriteria,
        minPrice = null,
        maxPrice = null,
    }: {
        debouncedProductName?: string;
        currentPage?: number;
        sortCriteria?: string;
        minPrice?: number | null;
        maxPrice?: number | null;
    }): Promise<{
        products: ProductProps[];
        totalPages: number;
        totalProducts: number;
    }> {
        try {
            const response = await apiService.get<{
                status: number;
                products: any[];
                total_pages: number;
                total_count: number;
                message?: string;
            }>("/sellers/search_seller_products", {
                params: {
                    product_name: debouncedProductName,
                    page: currentPage,
                    sort: sortCriteria,
                    minPrice: minPrice !== null ? minPrice : undefined,
                    maxPrice: maxPrice !== null ? maxPrice : undefined,
                },
            });

            if (response.status === 200) {
                return {
                    products: response.products,
                    totalPages: response.total_pages,
                    totalProducts: response.total_count,
                };
            } else {
                console.warn(response.message || "No products found.");
                return {
                    products: [],
                    totalPages: 1,
                    totalProducts: 0,
                };
            }
        } catch (error: unknown) {
            handleError(error, "Error fetching seller products");
            throw new Error("An unexpected error occurred");
        }
    },

    async createProduct(productData: FormData): Promise<ProductResponse> {
        try {
            const response = await apiService.post<ProductResponse>('/products/post_product', productData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        
            // Check if the response status is 201 (Created)
            if (response.status === 201) {
                return response; // Return the created product data
            } else {
                throw new Error(response.message || 'Error creating product');
            }
        } catch (error: unknown) {
            handleError(error, 'Error creating product');
            throw new Error('An unexpected error occurred');
        }
    },

    async editProduct(productId: number, productData: FormData): Promise<ProductResponse> {
        try {
            const response = await apiService.put<ProductResponse>(`/products/edit_product/${productId}`, productData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            // Check if the response status is 200 (Success)
            if (response.status === 200) {
                return response; // Return the updated product data
            } else {
                throw new Error(response.message || 'Error updating product');
            }
        } catch (error: unknown) {
            handleError(error, 'Error updating product');
            throw new Error('An unexpected error occurred');
        }
    },
};