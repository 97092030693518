import "./product.css"
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Navigation } from 'swiper';

// types
import { ProductProps } from "../../types/Types";

// components
import Icon from "../icon/Icon";
import ProductDisplayThrift from "./ProductDisplayThrift";

SwiperCore.use([Navigation]);

// Helper function to calculate items per page based on viewport
const calculateItemsPerPage = () => {
    const width = window.innerWidth; // Get viewport width  
    if (width >= 992) return 4; // Large devices (lg)
    if (width >= 768) return 3; // Medium devices (md)
    return 2; // Small devices and below (sm)
};

const ThriftSection: React.FC = () => {
    const [itemSection, setItemSection] = useState<string | null>("Thrift Shop");
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [skeletonCount, setSkeletonCount] = useState<number>(0);

    // Swiper settings
    const sliderSettings = {
        spaceBetween: 30,
        autoHeight: true,
        slidesPerView: "auto",
        centeredSlides: true,
        navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
        },
        breakpoints: {
        481: { slidesPerView: 2, slidesPerGroup: 1, centeredSlides: false },
        640: { slidesPerView: 3, slidesPerGroup: 3, centeredSlides: false },
        992: { slidesPerView: 4, slidesPerGroup: 4, centeredSlides: false },
        },
        className: "mySwiper",
    };

    useEffect(() => {
        const handleResize = () => setSkeletonCount(calculateItemsPerPage());
        window.addEventListener("resize", handleResize);
        handleResize(); // Initial calculation

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // calling the  active products by section api
    const fetchThriftItems = useCallback(async () => {
        if (!itemSection) {
            setProducts([]);
            setSkeletonCount(0);
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Base_url}/utils/get_newest_products_by_section`,
                { params: { product_section: itemSection } }
            );

            if (response.data.status === 200) {
                setProducts(response.data.products);
                setSkeletonCount(calculateItemsPerPage());
            } else {
                setProducts([]);
                setSkeletonCount(0);
                setError("No products found.");
            }
        } catch (error) {
            console.error("Error fetching products:", error);
            setError("Failed to fetch products. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [itemSection]);

    useEffect(() => {
        fetchThriftItems();
    }, [fetchThriftItems]);
  
    return (
        <>
            <div className="carousel">
                <div className="container">
                    <div className="wrap">
                        <div className="section-title">
                            <h2>Thrift Section</h2>
                        </div>
                        <div className="inner-wrapper">
                            <ProductDisplayThrift
                                products={products}
                                loading={loading}
                                error={error}
                                skeletonCount={skeletonCount}
                                fetchProducts={fetchThriftItems}
                                setError={setError}
                                renderSlider={true}
                                sliderSettings={sliderSettings}
                            />      
                        </div>

                        {/* Navigation buttons */}
                        <div className="p-nav">
                            <div className="swiper-button-next">
                                <Icon name="FaArrowRight" style={{ position: 'relative', top: '-1px' }} />
                            </div>
                            <div className="swiper-button-prev">
                                <Icon name="FaArrowLeft" style={{ position: 'relative', top: '-1px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThriftSection;