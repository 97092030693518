import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import IconDetailsPage from "../icon/IconDetailsPage";
import Icon from "../icon/Icon";

interface ShopInfo {
    company_name: string;
    location: string;
    business_category: string;
    shop_description: string;
    status: string;
}

interface ShopDetailsProps {
    sellerId: number;
}

const ShopDetails: React.FC<ShopDetailsProps> = ({ sellerId }) => {
    const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchShopInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/get_shop_details/${sellerId}`);
                setShopInfo(response.data.shop);
            } catch (error) {
                setError("Error fetching seller information");
            } finally {
                setLoading(false);
            }
        };

        fetchShopInfo();
    }, [sellerId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!shopInfo) {
        return <div>Shop information not available</div>;
    }

    return (
        <>
            <div className="tab-pane fade" id="tab_shipping_content" role="tabpanel">
                <div className="card">
                    <div className="card-header">
                        <a
                            className="card-link collapsed"
                            data-toggle="collapse"
                            href="#collapse_shipping_content"
                        >
                            Shop Details
                            <IconDetailsPage
                                name="FaPlus"
                                className="plus"
                                style={{
                                    color: "#777",
                                    float: "left",
                                    fontSize: "18px",
                                    lineHeight: "1.1rem",
                                    marginRight: "5px",
                                }}
                            />
                            <IconDetailsPage
                                name="FaMinus"
                                className="minus"
                                style={{
                                    color: "#777",
                                    float: "left",
                                    fontSize: "18px",
                                    lineHeight: "1.1rem",
                                    marginRight: "5px",
                                }}
                            />
                        </a>
                    </div>
                    <div
                        id="collapse_shipping_content"
                        className="collapse-shipping-content collapse"
                        data-parent="#accordion"
                    >
                        <div className="description">
                            <ul>
                                <li>
                                    <strong>Shop Name:</strong> {shopInfo.company_name}
                                </li>
                                <li>
                                    <strong>Shop Location:</strong> {shopInfo.location}
                                </li>
                                <li>
                                    <strong>Business Category:</strong> {shopInfo.business_category}
                                </li>
                                <li>
                                    <strong>Shop Description:</strong> {shopInfo.shop_description}
                                </li>
                                <li>
                                    <strong>Verified:</strong>
                                    {shopInfo.status === "active" ? (
                                        <Icon
                                            name="FcApproval"
                                            size="1.5em"
                                            color="#09b1ba"
                                            style={{ position: "relative", top: "-1.5px" }}
                                        />
                                    ) : (
                                        <Icon
                                            name="FcCancel"
                                            size="1.5em"
                                            color="red"
                                            style={{ position: "relative", top: "-1.5px" }}
                                        />
                                    )}
                                </li>
                                <div className="row-custom text-center">
                                    <Link to="/profile" className="link-see-more">
                                        <span>View Shop&nbsp;</span>
                                        <Icon
                                            name="BiChevronRight"
                                            size="1.5em"
                                            style={{ position: "relative", top: "-1.5px" }}
                                        />
                                    </Link>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopDetails;