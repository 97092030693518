import React, { useState } from 'react';
import visa from "../../assets/img/visa.svg";
import verve from "../../assets/img/verve.svg";
import mastercard from "../../assets/img/mastercard.svg";
import paystack from "../../assets/img/paystack.svg";
import PaystackForm from "./PaystackForm";
import DirectTransferForm from "./DirectTransferForm";

// Define the interface for the props
interface PaymentOptionsProps {
    selectedOption: string; // Adjust the type based on your actual data type
    setSelectedOption: (option: string) => void; // Function to set the selected option
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ selectedOption, setSelectedOption }) => {

    // handles option change
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };
  
    return ( 
        <form id="form_validate" className="validate_terms">
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <ul className="payment-options-list">
                            <li>
                                <div className="option-payment">
                                    <div className="list-left">
                                        <div className="custom-control custom-radio">
                                            <input 
                                                type="radio" 
                                                className="custom-control-input" 
                                                id="option_3" 
                                                name="payment_option" 
                                                value="paystack" 
                                                required 
                                                checked={selectedOption === 'paystack'}
                                                onChange={handleOptionChange}
                                            />
                                            <label className="custom-control-label label-payment-option" htmlFor="option_3">Paystack</label>
                                        </div>
                                    </div>
                                    <div className="list-right">
                                        <label htmlFor="option_3">
                                            <img src={visa} alt="visa" />
                                            <img src={mastercard} alt="mastercard" />
                                            <img src={verve} alt="verve" />
                                            <img src={paystack} alt="paystack" />                    
                                        </label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="option-payment">
                                    <div className="custom-control custom-radio">
                                        <input 
                                            type="radio" 
                                            className="custom-control-input" 
                                            id="option_bank" 
                                            name="payment_option" 
                                            value="bankTransfer" 
                                            required 
                                            checked={selectedOption === 'bankTransfer'}
                                            onChange={handleOptionChange}
                                        />
                                        <label className="custom-control-label label-payment-option" htmlFor="option_bank">MoMo Transfer<br /><small>Make your payment directly into our MoMo account.</small></label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default PaymentOptions;