import React from "react";
import AddProduct from "../../pages/seller/add-product/AddProduct";
import { CancelButton } from "../../utils/ModalStyles";
import ModalWrapper from "./ModalWrapper";

interface AddProductModalProps {
    onClose: () => void;
    isOpen: boolean;
}

const AddProductModal: React.FC<AddProductModalProps> = ({ isOpen, onClose }) => {
    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
            {/* AddProduct Component */}
            <AddProduct />
            <CancelButton type="button"onClick={onClose}>Cancel</CancelButton>
        </ModalWrapper>
    );
};
  
export default AddProductModal;