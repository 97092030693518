import React, { useState } from "react";
import Icon from "../icon/Icon";

interface StarRatingProps {
    rating: number;
    onRatingChange: (rating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onRatingChange }) => {
    const [hoverRating, setHoverRating] = useState<number>(0);

    const handleClick = (value: number) => {
        onRatingChange(value);
    };

    const handleMouseEnter = (value: number) => {
        setHoverRating(value);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    return (
        <div className="rating">
            {[1, 2, 3, 4, 5].map((value) => (
                <Icon
                    key={value}
                    name={value <= (hoverRating || rating) ? "MdOutlineStar" : "MdOutlineStarBorder"}
                    color="#fbc12a"
                    onClick={() => handleClick(value)}
                    onMouseEnter={() => handleMouseEnter(value)}
                    onMouseLeave={handleMouseLeave}
                    style={{ fontSize: "15px", marginRight: "2px" }}
                />
            ))}
        </div>
    );
};

export default StarRating;