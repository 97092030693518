import React, { useState } from 'react';
import axios from 'axios';
import StarRating from '../../components/rating/StarRating';
import ClipLoader from 'react-spinners/ClipLoader';

interface ReviewsProps {
    productId: number;
    onReviewSubmitted: () => void;
    shopperId: string;
}

const Reviews: React.FC<ReviewsProps> = ({ productId, onReviewSubmitted, shopperId }) => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [rating, setRating] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [notification, setNotification] = useState<string>('');

    // handles submission of reviews
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        setLoading(true);
        setNotification('');
        try {
            await axios.post(`${process.env.REACT_APP_Base_url}/products/add_reviews`, {
                productId,
                name,
                email,
                comment,
                rating,
                shopper_id: shopperId,
            });
            onReviewSubmitted(); // Call the callback function after submission
            setNotification('Review submitted successfully!');
        } catch (error) {
            console.error('Error submitting review:', error);
            setNotification('Failed to submit review.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {notification && <div className="notification">{notification}</div>}
            <form id="form_add_comment" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <input
                            type="text"
                            name="name"
                            id="comment_name"
                            className="form-control form-input"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group col-md-6">
                    <input
                        type="email"
                        name="email"
                        id="comment_email"
                        className="form-control form-input"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    </div>
                </div>
                <div className="form-group">
                    <textarea
                        name="comment"
                        id="comment_text"
                        className="form-control form-input form-textarea"
                        placeholder="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                    >
                    </textarea>
                </div>
                <div className="form-group">
                    <StarRating rating={rating} onRatingChange={setRating} />
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-md btn-custom" disabled={loading}>
                        {loading ? (
                            <ClipLoader color="#ffffff" loading={loading} size={24} />
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
            </form>
        </>
    );
};

export default Reviews;