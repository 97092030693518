import React from 'react';
import styled from 'styled-components';
import Icon from '../icon/Icon';

interface ErrorAlertProps {
    error: string | null;
    fetchProducts: () => void;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, fetchProducts, setError }) => {
    if (!error) return null;

    return (
        <AlertContainer role="alert">
            <AlertContent>
                <Icon name="FaExclamationTriangle" style={{ fontSize: '1.5rem', marginRight: '0.8rem' }} />
                <strong>Error: </strong> {error}
            </AlertContent>
            <ButtonContainer>
                <RetryButton onClick={fetchProducts}>
                    Retry
                </RetryButton>
                <CloseButton onClick={() => setError(null)} aria-label="Close" />
            </ButtonContainer>
        </AlertContainer>
    );
};

export default ErrorAlert;

// Styled Components
const AlertContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Border color */
    border-radius: 10px;
    padding: 1.2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem 0; /* Add some margin for spacing */
`;

const AlertContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1; /* Allow content to take available space */
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between buttons */
`;

const RetryButton = styled.button`
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3; /* Darker blue on hover */
    }

    &:focus {
        outline: none; /* Remove default outline */
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); /* Add focus shadow */
    }
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: #721c24; /* Dark red color for close button */
    transition: color 0.3s;

    &:hover {
        color: #a71c24; /* Darker red on hover */
    }
`;