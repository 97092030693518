import React, { useState } from "react";
import { ProductProps } from "../../types/Types";
import { CancelButton } from "../../utils/ModalStyles";
import ModalWrapper from "./ModalWrapper";
import ConfirmButton from "../admin/ConfirmButton";
import { adminService } from "../../services/adminService";
import styled from "styled-components";

interface DeleteProductModalProps {
    onClose: () => void;
    isOpen: boolean;
    product: ProductProps;
    onDelete: () => void;
}

const DeleteProductModal: React.FC<DeleteProductModalProps> = ({ isOpen, onClose, product, onDelete }) => {
    const [error, setError] = useState<string | null>(null);
    return (
        <>
            <ModalWrapper isOpen={isOpen} onClose={onClose}>
                <Header>
                    <h2>Delete Product</h2>
                    <CloseButton onClick={onClose}>&times;</CloseButton>
                </Header>
                <Body>
                    <p>Are you sure you want to delete <strong>{product.product_name}</strong>? This action cannot be undone.</p>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                </Body>
                <Footer>
                    <ConfirmButton
                        id={product.id}
                        confirmAction={async (id) => {
                            await adminService.deleteProduct(id);
                        }}
                        label="Delete Product"
                        iconName="MdDeleteForever"
                    />
                    <CancelButton type="button" onClick={onClose}>Cancel</CancelButton>
                </Footer>
            </ModalWrapper>
        </>
    );
};
  
export default DeleteProductModal;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
        margin: 0;
        font-size: 1.25rem;
        color: #333;
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #999;
    cursor: pointer;

    &:hover {
        color: #333;
    }
`;

const Body = styled.div`
    p {
        font-size: 1rem;
        color: #555;
        margin: 0;
    }

    strong {
        color: #333;
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    gap: 1rem;
`;

const DeleteButton = styled.button`
    background: #dc3545;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
        background: #c82333;
    }

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

const ErrorMessage = styled.p`
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 1rem;
`;