import React from "react";
import styled from "styled-components";
import { UserCardProps } from "../../types/Types";
import Icon from "../icon/Icon";
import { ClipLoader } from "react-spinners";

const UserCard: React.FC<UserCardProps> = ({
    user,
    type,
    onToggleStatus,
    onMessage,
    onClick,
    isLoading = false,
}) => {
    const isBlocked = user.status === "blocked"; // Simplify condition

    return (
        <Card onClick={onClick}>
            <CardHeader>
                <CardTitle>{user.first_name} {user.last_name}</CardTitle>
                {type === "sellers" && user.status && (
                    <Badge status={user.status}>{user.status}</Badge>
                )}
                {type === "shoppers" && user.status && (
                    <Badge status={user.status}>{user.status}</Badge>
                )}
            </CardHeader>
            
            <p>Email: {user.email}</p>
            
            {type === "sellers" && (
                <>
                    <p>Business Name: {user.company_name}</p>
                    <p>Products: {user.products ?? 0}</p>
                    <p>Revenue: {user.revenue ?? 0}</p>
                </>
            )}
            
            {type === "shoppers" && (
                <>
                    <p>Purchases: {user.purchases ?? 0}</p>
                    <p>Total Spend: {user.totalSpend ?? 0}</p>
                </>
            )}

            <CardFooter>
                <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); onMessage(user); }} variant="modern">
                    <Icon name="TbMessageCircle" size="1.2em" style={{ position: "relative", top: "-2px" }} /> Message
                </Button>
                <Button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); onToggleStatus(user.id, type); }}
                    variant="red"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <ClipLoader color="#fff" size={15} />
                    ) : (
                        <>
                            <Icon
                                name={isBlocked ? "FaUserCheck" : "FaUserTimes"}
                                size="1.2em"
                                style={{ position: "relative", top: "-2px" }}
                            /> 
                            {isBlocked ? "Unblock" : "Block"}
                        </>
                    )}
                </Button>
            </CardFooter>
        </Card>
    );
};

export default UserCard;

const Card = styled.div`
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;
    gap: 0.5rem; /* Space between the title and badge */
`;

const CardTitle = styled.h3`
    font-size: 1rem;
    margin: 0;
`;

const Badge = styled.span<{ status: string }>`
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${(props) =>
        props.status === "active"
        ? "#28a745"
        : props.status === "pending"
        ? "#ffc107"
        : "#dc3545"};
    background-color: ${(props) =>
        props.status === "active"
        ? "#d4edda"
        : props.status === "pending"
        ? "#fff3cd"
        : "#f8d7da"};
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled.button<{ variant?: "red" | "modern" }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: ${(props) => (props.variant === "red" ? "none" : "1px solid #ddd")};
    border-radius: 12px;
    background-color: ${(props) =>
        props.variant === "red" ? "#dc3545" : "#f9f9f9"};
    color: ${(props) => (props.variant === "red" ? "#fff" : "#333")};
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: ${(props) =>
        props.variant === "red" ? "#c82333" : "#f0f0f0"};
    }
`;

const CenteredHeader = styled.h2`
    text-align: center;
    margin: 20px 0;
`;