import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import 'overlayscrollbars/styles/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { CategoryProps } from "../../types/Types";
import SkeletonShimmerList from '../loader/SkeletonShimmerList';

interface ProductCategoryFilterProps {
    linkClassName?: string; // Optional className for the Link component
    scrollHeight?: string; // Optional height for the OverlayScrollbarsComponent
}

const ProductCategoryFilter = ({ linkClassName, scrollHeight }: ProductCategoryFilterProps) => {
    const [categories, setCategories] = useState<CategoryProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [skeletonCount, setSkeletonCount] = useState(5);

    // fetch categories data
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_Base_url}/utils/get_product_categories`)
            .then((res) => {

                if (res.data.status === 200) {
                    setCategories(res.data.product_categories);
                }
                setLoading(false);
            })

            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            {loading ? (
                <SkeletonShimmerList count={skeletonCount} />
            ) : (
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: "leave", theme: "os-theme-dark" },
                    }}
                    style={{ height: scrollHeight }}
                >        
                    {categories &&
                        categories.map((category: CategoryProps) => ( 
                            <li key={category.id}>
                                <Link 
                                    to="/product-category"
                                    className={linkClassName}
                                    state={{ productCategory: category.product_category_name }}
                                >
                                    {category.product_category_name}
                                </Link>
                            </li>
                        ))
                    }                                                     
                </OverlayScrollbarsComponent>
            )}
        </>                   
    )
};

export default ProductCategoryFilter;