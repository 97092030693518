import React, { useState, useEffect } from 'react';
import { PropagateLoader } from "react-spinners";
import Icon from "../../../../components/icon/Icon";
import { sellerService } from "../../../../services/sellerService";

const SellerShopDetails = ({ loading }) => {
    const [shopInfo, setShopInfo] = useState(null);
	const [error, setError] = useState(null);

    // fetch shop details
    const fetchShopInfo = async () => {
        try {
            const shop = await sellerService.getShopDetails();
            setShopInfo(shop);
        } catch (err) {
            setError('Error fetching seller information');
        }
    };

    useEffect(() => {
        fetchShopInfo();
    }, []);

    // handle loading states
    if (loading) {
        return (
            <div style={loaderContainerStyle}>
                <PropagateLoader color="var(--vr-color-main)" loading={loading} />
            </div>
        );
    }

    // Handle error states
    if (error) return <div>{error}</div>;

    // Prevent rendering if shopInfo is still null
    if (!shopInfo) {
        return (
            <div style={loaderContainerStyle}>
                <PropagateLoader color="var(--vr-color-main)" loading={true} />
            </div>
        );
    }

    return (
        <>
            <div className="shop-container"> 
                {error && <p className="text-danger text-center">{error}</p>}
                <ul className="shop-info-list">
                    <li><strong>Shop Name:</strong> {shopInfo.company_name}</li>
                    <li><strong>Shop Location:</strong> {shopInfo.location}</li>
                    <li><strong>Business Category:</strong> {shopInfo.business_category}</li>
                    <li><strong>Shop Description:</strong> {shopInfo.shop_description}</li>
                    <li>
                        <strong>Verified:</strong> 
                        {shopInfo.status === "active" ? (
                            <Icon name="FcApproval" size="1.5em" color="#09b1ba" style={{ position: 'relative', top: '-1.5px' }} />
                        ) : (
                            <Icon name="FcCancel" size="1.5em" color="red" style={{ position: 'relative', top: '-1.5px' }} />
                        )}
                    </li>
                </ul>
            </div>
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '50vh', // Full viewport height
};

export default SellerShopDetails;