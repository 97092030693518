// src/components/modals/FullScreenImageViewer.tsx
import React from "react";
import styled from "styled-components";

interface FullScreenImageViewerProps {
    isOpen: boolean;
    imageUrl?: string;
    onClose: () => void;
}

const FullScreenImageViewer: React.FC<FullScreenImageViewerProps> = ({ isOpen, imageUrl, onClose }) => {
    if (!isOpen || !imageUrl) return null; // Do not render if imageUrl is missing

    return (
        <Overlay onClick={onClose}>
            <Image 
                src={imageUrl} 
                alt="Full Screen" 
                onClick={(e) => e.stopPropagation()} 
            />
        </Overlay>
    );
};

export default FullScreenImageViewer;

// Styled Components
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Ensure it is above other content
`;

const Image = styled.img`
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
`;