import React from 'react';
import Icon from "../icon/Icon";

const Share: React.FC = () => {
    return (
        <div className="row-custom product-share">
            <label>Share:</label>
            <ul>
                <li>
                    <a 
                        href="#" 
                        onClick={() => { 
                            window.open("https://www.facebook.com/sharer/sharer.php?u=", "Share This Post", "width=640,height=450"); 
                            return false; 
                        }}
                    >
                        <Icon name="FaFacebookF" color="#365899" />
                    </a>
                </li>
                <li>
                    <a 
                        href="#" 
                        onClick={() => { 
                            window.open("https://twitter.com/share?url=", "Share This Post", "width=640,height=450"); 
                            return false; 
                        }}
                    >
                        <Icon name="FaWhatsapp" color="#008b8b" />
                    </a>
                </li>
                <li>
                    <a 
                        href="#" 
                        onClick={() => { 
                            window.open("http://pinterest.com/pin/create/button/?url=", "Share This Post", "width=640,height=450"); 
                            return false; 
                        }}
                    >
                        <Icon name="FaXTwitter" color="#000000"/>
                    </a>
                </li>
                <li>
                    <a 
                        href="#" 
                        onClick={() => { 
                            window.open("http://www.linkedin.com/shareArticle?mini=true&amp;url=", "Share This Post", "width=640,height=450"); 
                            return false; 
                        }}
                    >
                        <Icon name="FaInstagram" color="#F06292" />
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Share;