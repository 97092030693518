import React, { useState } from "react";
import { UserProps } from "../../types/Types";
import PriceFormatter from "../product filter/PriceFormatter";
import { CancelButton } from "../../utils/ModalStyles";
import ModalWrapper from "./ModalWrapper";
import styled from "styled-components";
import Icon from "../icon/Icon";
import FullScreenImageViewer from "../misc/FullScreenImageViewer";
import ConfirmButton from "../admin/ConfirmButton";

interface DetailsModalProps {
    onClose: () => void;
    isOpen: boolean;
    user: UserProps;
    activeTab: "sellers" | "shoppers";
    formatCreatedAt: (dateString: string) => string;
    confirmSeller: (id: number) => Promise<void>;
}

const DetailsModal: React.FC<DetailsModalProps> = ({ isOpen, onClose, user, formatCreatedAt, activeTab, confirmSeller }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState("");
    const placeholderImage = "https://res.cloudinary.com/dfexgcu3h/image/upload/v1734936868/Vincee/ProfilePictures/default_tdwsdx.jpg";

    const handleImageClick = (imageUrl: string) => {
        setFullScreenImage(imageUrl);
        setIsFullScreen(true);
    };

    const handleClick = () => {
        if (user.profile_picture) {
            handleImageClick(user.profile_picture); // Call only if profile_picture exists
        }
    };

    return (
        <>
            <ModalWrapper isOpen={isOpen} onClose={onClose}>
                {user ? (
                    <>
                        <h2>{user.first_name} {user.last_name}</h2>
                        {user.status && (<Badge status={user.status}>{user.status}</Badge>)}
                        <UserDetails>
                            <ImageContainer>
                                <ProfilePicture 
                                    src={user.profile_picture || placeholderImage} 
                                    alt={user.first_name || "Profile Picture"} 
                                    onClick={handleClick} 
                                />
                            </ImageContainer>
                            <DetailsContainer>
                                <p>
                                    <strong>Email: </strong> 
                                    {user.email}
                                </p>
                                <p>
                                    <strong>Contact:</strong> {" "}
                                    <a href={`tel:${user.contact}`} style={{ color: 'green', textDecoration: 'none' }}>
                                        {user.contact}
                                    </a>
                                </p>
                                <p>
                                    <strong>Location: </strong> 
                                    {user.location}
                                </p>
                                {user.company_name !== undefined && (
                                    <p>
                                        <strong>Company Name: </strong> 
                                        {user.company_name}
                                    </p>
                                )}
                                {user.business_category !== undefined && (
                                    <p>
                                        <strong>Business Category: </strong> 
                                        {user.business_category}
                                    </p>
                                )}
                                {user.shop_description !== undefined && (
                                    <p>
                                        <strong>Shop Description: </strong> 
                                        {user.shop_description}
                                    </p>
                                )}
                                <p>
                                    <strong>Activated: </strong> 
                                    {user.activated ? "Yes" : "No"}
                                </p>
                                <p>
                                    <strong>Account Created: </strong> 
                                    {formatCreatedAt(user.created_at)}
                                </p>
                                {/* Want them to only render when they are available */}
                                {user.products !== undefined && (
                                    <p>
                                        <strong>Products: </strong>
                                        {user.products}
                                    </p>
                                )}
                                {user.revenue !== undefined && (
                                    <p>
                                        <strong>Revenue: </strong>
                                        {user.revenue}
                                    </p>
                                )}
                                 {user.purchases !== undefined && (
                                    <p>
                                        <strong>Purchases: </strong>
                                        {user.purchases}
                                    </p>
                                )}
                                {user.totalSpend !== undefined && (
                                    <p>
                                        <strong>Total Spend: </strong>
                                        {user.totalSpend}
                                    </p>
                                )}
                            </DetailsContainer>
                        </UserDetails>
                    </>
                ) : (
                    <p>No user selected.</p> // Fallback message if no product is selected
                )}

                {/* Conditionally render the "Activate" or "Deactivate" button */}
                {activeTab === "sellers" && user.status === "pending" && (
                    <CenteredButtonContainer>
                        <ConfirmButton
                            id={user.id}
                            confirmAction={confirmSeller}
                            label="Approve Seller"
                            iconName="BiUserCheck"
                        />
                    </CenteredButtonContainer>
                )}
                <CancelButton type="button"onClick={onClose}>Cancel</CancelButton>
            </ModalWrapper>

            {/* Full-Screen Image Viewer */}
            <FullScreenImageViewer isOpen={isFullScreen} imageUrl={fullScreenImage} onClose={() => setIsFullScreen(false)} />
        </>
    );
};
  
export default DetailsModal;

const UserDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 20px;
`;

const ImageContainer = styled.div`
    flex: 1;
    margin-right: 20px;
`;

const ProfilePicture = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
        opacity: 0.8; // Add a hover effect
    }
`;

const DetailsContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin: 8px 0;
        font-size: 14px;
        color: #333;
    }

    p:first-child {
        margin-top: 0; /* Remove top margin for the first <p> element */
    }

    strong {
        color: #007bff; // Highlight the labels
    }
`;

const CenteredButtonContainer = styled.div`
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically if needed */
    margin-top: 0.5rem;     /* Add spacing above the button */
    margin-bottom: 0.5rem;
`;

const Badge = styled.span<{ status: string }>`
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${(props) =>
        props.status === "active"
        ? "#28a745"
        : props.status === "pending"
        ? "#ffc107"
        : "#dc3545"};
    background-color: ${(props) =>
        props.status === "active"
        ? "#d4edda"
        : props.status === "pending"
        ? "#fff3cd"
        : "#f8d7da"};
`;