import axios from "axios";
import React from "react";
import styled from "styled-components";
import { IoHome, IoBasket, IoCart, IoChatbubbleOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { MdLogout, MdReport } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { authApi } from "../../services/api";

interface SidebarProps {
    isOpen: boolean;
    toggleSidebar: () => void;
}

const menuItems = [
    {
        to: "/super-admin/dashboard",
        icon: IoHome,
        label: "Dashboard"
    },
    {
        to: "/super-admin/users",
        icon: FaUsers,
        label: "User Management"
    },
    {
        to: "/super-admin/products",
        icon: IoCart,
        label: "Product Management"
    },
    {
        to: "/super-admin/orders",
        icon: IoBasket,
        label: "Order Management"
    },
    {
        to: "/super-admin/messages",
        icon: IoChatbubbleOutline,
        label: "Messages"
    },
    {
        to: "/super-admin/reports",
        icon: MdReport,
        label: "Reports"
    }
];

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  
    const logOut = async () => {
        try {
            // Call backend logout to invalidate server-side token
            await authApi.post('/utils/logout', {});
  
            // Clear client-side storage
            sessionStorage.clear();
          
            // Redirect to login
            window.location.href = "/super-admin/login";
        } catch (error) {
            // Handle logout error
            console.error('Logout failed', error);
          
            // Fallback: still clear local data and redirect
            sessionStorage.clear();
            window.location.href = "/super-admin/login";
        }
    };

    return (
        <>
            <SidebarContainer isOpen={isOpen}>
                <SidebarHeader isOpen={isOpen}>
                    <h2>newmarket</h2>
                </SidebarHeader>
                
                <NavMenu>
                    {menuItems.map((item, index) => (
                        <StyledNavLink 
                            key={index} 
                            to={item.to}
                            className={({ isActive }) => isActive ? 'active' : ''}
                        >
                            <MenuItem isOpen={isOpen}>
                                <item.icon size={20} />
                                <MenuText isOpen={isOpen}>{item.label}</MenuText>
                            </MenuItem>
                        </StyledNavLink>
                    ))}
                </NavMenu>

                <LogoutContainer>
                    <LogoutButton 
                        isOpen={isOpen} 
                        onClick={(e) => {
                            e.preventDefault();
                            logOut();
                        }}
                    >
                        <MdLogout size={20} />
                        <MenuText isOpen={isOpen}>Logout</MenuText>
                    </LogoutButton>
                </LogoutContainer>
            </SidebarContainer>
      
            <Overlay isOpen={isOpen} onClick={toggleSidebar} />
        </>
    );
};

export default Sidebar;

const SidebarContainer = styled.aside<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${props => props.isOpen ? '250px' : '80px'};
  background-color: #2c3e50;
  color: white;
  transition: width 0.3s ease;
  z-index: 200;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: ${props => props.isOpen ? '250px' : '0'};
    box-shadow: ${props => props.isOpen ? '0 0 0 9999px rgba(0,0,0,0.5)' : 'none'};
  }
`;

const SidebarHeader = styled.div<{ isOpen: boolean }>`
    opacity: ${props => (props.isOpen ? 1 : 0)}; /* Transparent when closed */
    height: ${props => (props.isOpen ? "66px" : "0")}; /* Collapse height */
    overflow: hidden; /* Prevent content from spilling out */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    border-bottom: ${props => (props.isOpen ? "1px solid rgba(255,255,255,0.1)" : "none")};
    transition: opacity 0.3s ease, height 0.3s ease;

    h2 {
        font-family: 'Raleway', sans-serif;
        font-size: 36px;
        color: white;
    }
`;

const NavMenu = styled.nav`
  margin-top: 1rem;
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease;

    &:hover {
        color: white; /* Change color to white on hover */
    }
    
    &:focus {
        color: white; /* Change color to white on focus */
        outline: none; /* Remove default outline */
        background-color: rgba(255, 255, 255, 0.1); /* Optional: add background on focus */
    }

    &:active {
        color: white; /* Change color to white when active */
    }
  
    &.active {
        & > div {
            background-color: rgba(255,255,255,0.1);
        }
    }
`;

const MenuItem = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255,255,255,0.1);
  }
`;

const MenuText = styled.span<{ isOpen: boolean }>`
  margin-left: 15px;
  white-space: nowrap;
  opacity: ${props => props.isOpen ? 1 : 0};
  width: ${props => props.isOpen ? 'auto' : '0'};
  transition: opacity 0.3s ease, width 0.3s ease;
  overflow: hidden;
`;

const LogoutContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const LogoutButton = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(255,255,255,0.1);
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
  }
`;