import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';

// types
import { ProductProps, sortOptions, SortCriteria } from '../../../types/Types';

// hooks
import useDebounce from '../../../hooks/useDebounce';

// Components
import Pagination from '../../../components/pagination/Pagination';
import PriceFilter, { PriceFilterRef } from '../../../components/product filter/PriceFilter';
import ProductSort from '../../../components/product filter/ProductSort';
import PriceReset from '../../../components/product filter/PriceReset';
import Icon from '../../../components/icon/Icon';
import ProductDisplay from "../../../components/products/ProductDisplay";
import ReportSellerModal from '../../../components/modals/ReportSellerModal';

type TotalProductProp = {
    sellerId: string;
    setTotalProducts: (count: number) => void;
};


const SellerProducts: React.FC<TotalProductProp> = ({ sellerId, setTotalProducts }) => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<SortCriteria>("most_recent");
    const [minPrice, setMinPrice] = useState<number | null>(null);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const priceFilterRef = useRef<PriceFilterRef>(null);
    const [error, setError] = useState<string | null>(null);
    const [productName, setProductName] = useState(''); // State to store the search input
    const debouncedProductName = useDebounce(productName, 300); // Debounced value with a delay of 300ms
    const [skeletonCount, setSkeletonCount] = useState(24);

    // Fetch seller products
    const fetchProducts = async () => {
        if (!sellerId) return;
        setLoading(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_Base_url}/sellers/search_products/${sellerId}`, 
              {
                params: {
                    product_name: debouncedProductName, // Use a dynamic value here
                    page: currentPage,
                    sort: sortCriteria,
                    minPrice: minPrice !== null ? minPrice : undefined,
                    maxPrice: maxPrice !== null ? maxPrice : undefined,
                }
              },
            );
    
            if (response.data.status === 200) {
                const { products, total_pages, total_count } = response.data;

                setProducts(products);
                setTotalPages(total_pages);
                setTotalProducts(total_count); // Update the total products count/Calculate total products based on the length of the products array
                setSkeletonCount(total_count);

                // Scroll to the top after successfully fetching products
                window.scrollTo(0, 0);
            } else {
                setProducts([]);
                setTotalPages(1);
                setTotalProducts(0); // Reset total products if no products found
                setSkeletonCount(0); // No skeletons if no products are found
            }
        } catch (error) {
            console.error('Error fetching seller products:', error);
            setError('Failed to fetch products. Please try again.');
            setSkeletonCount(0); // Hide skeletons if an error occurs
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [sellerId, currentPage, sortCriteria, minPrice, maxPrice, debouncedProductName]);

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Function to sort products by highest and lowest prices, most recent and rating
    const handleSortChange = (sortValue: SortCriteria) => {
        setSortCriteria(sortValue); // Update the sort criteria
        setCurrentPage(1); // Reset to the first page
    };

    // Function to handle the filtering of products by price range
    const handleFilter = () => {
        if (!priceFilterRef.current) return; // Ensure the ref exists
    
        const min = parseFloat(String(priceFilterRef.current.minPrice)) || 0;
        const max = parseFloat(String(priceFilterRef.current.maxPrice)) || 1000000;
    
        // Apply the filter logic using min and max prices
        setMinPrice(min);
        setMaxPrice(max);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Functions to clear the minimum and maximum price inputs
    const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMinPrice(null); // Reset minPrice to null
        priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMaxPrice(null); // Reset maxPrice to null
        priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetSearch = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setProductName('');
        setCurrentPage(1);
    };

    const handleResetAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        // Call individual reset handlers
        handleResetMinPrice(e); 
        handleResetMaxPrice(e);
        handleResetSearch(e);
        setCurrentPage(1); // Reset page to 1
    };

    return (
        <>
            {/* Seller's Product Section Begin */}
            <div className="row">
                {/* product filtering on mobile devices */}
                <div className="col-12 m-b-20 container-filter-products-mobile">
                    <div className="btn-filter-products-mobile">
                        <button 
                            className="btn btn-md" 
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#collapseFilters" 
                            aria-expanded="false" 
                            aria-controls="collapseFilters"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#666666" viewBox="0 0 256 256">
                                <path d="M200,136a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,136Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z"></path>
                            </svg>
                            &nbsp;&nbsp;
                            <span className="text">Filter Products</span>
                        </button>
                    </div>
                    <ProductSort
                        sortOptions={sortOptions}
                        sortCriteria={sortCriteria}
                        handleSortChange={handleSortChange}
                    />
                </div>

                <div className="col-12 col-md-3 col-sidebar-products">
                    <div className="sticky-lg-top">
                        <div id="collapseFilters" className="product-filters collapse d-md-block">
                            {/* product categories 
                            <div className="filter-item filter-item-categories"></div>*/}
                            {/* price filter */}
                            <PriceFilter 
                                ref={priceFilterRef} 
                                onFilter={handleFilter}
                            />
                            {/* search products */}
                            <div className="filter-item">
                                <h4 className="title">Filter by keyword</h4>
                                <div className="product-search">
                                    <input 
                                        type="text" 
                                        id="input_filter_keyword" 
                                        value={productName}
                                        className="form-control form-input"
                                        placeholder="Keyword"
                                        onChange={(e) => {
                                            setProductName(e.target.value);
                                            setCurrentPage(1);
                                        }}
                                    />                                
                                    <button 
                                        type="button" 
                                        id="btnFilterByKeyword" 
                                        className="btn btn-md btn-filter-product"
                                        onClick={handleFilter}
                                        disabled={loading}
                                    > 
                                        {loading ? (
                                            <div style={loaderContainerStyle}>
                                                <PuffLoader size={20} color="#000" />
                                            </div>
                                        ) : (
                                            <>
                                                <Icon name="CiSearch" style={{ top: '-1.5px', position: 'relative' }} />
                                                &nbsp;Filter
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        {/* reporting seller modal */}
                        <div className="row-custom report-seller-sidebar">
                            <button type="button" className="button-link text-muted link-abuse-report link-abuse-report-button display-inline-flex align-items-center" data-toggle="modal" data-target="#reportSellerModal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512" fill="currentColor">
                                    <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path>
                                </svg>
                                &nbsp;Report this seller
                            </button>
                        </div>
                        <div className="row-custom"></div>
                    </div>
                </div>

                <div id="productListProfile" className="col-12 col-md-9 col-content-products">
                    {/* product sorting on large devices*/}
                    <div className="clearfix container-filter-products m-b-20">
                        <ProductSort
                            sortOptions={sortOptions}
                            sortCriteria={sortCriteria}
                            handleSortChange={handleSortChange}
                        />
                    </div>

                    {/* resetting product filter */}
                    <div className="row">
                        <div className="col-12">
                            <PriceReset
                                minPrice={minPrice}
                                maxPrice={maxPrice}
                                searchKeyword={productName}
                                handleResetMinPrice={handleResetMinPrice}
                                handleResetMaxPrice={handleResetMaxPrice}
                                handleResetSearch={handleResetSearch}
                                handleResetAll={handleResetAll}
                            />
                        </div>
                    </div>

                    {/* products display */}           
                    <ProductDisplay
                        products={products}
                        loading={loading}
                        error={error}
                        skeletonCount={skeletonCount}
                        fetchProducts={fetchProducts}
                        setError={setError}
                        className="col-6 col-sm-4 col-md-4 col-lg-3 col-product"
                    />

                    {/* Pagination */}
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
            
            {/* Report Seller Modal */}
            <ReportSellerModal />
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
};

export default SellerProducts;