import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SessionExpiredModal } from './components/modals/LogoutModal';
import { useSessionExpiration } from './hooks/useSessionExpiration';

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";       
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import "./style-1.1.min.css";
import "./style-1.0.min.css";

// Prime React libraries theme
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

// Shopper registration pages
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Activation from "./pages/activation/Activation";
import ProfileCompletion from "./pages/complete-profile/ProfileCompletion";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import Dashboard from "./pages/dashboard/Dashboard";


// Product pages
import Products from "./pages/products/Products";
import ProductByCategory from "./pages/product-category/ProductByCategory";
import ProductBySection from "./pages/product-section/ProductBySection";
import Category from "./pages/category/Category";
import Wishlist from "./pages/wishlist/Wishlist";
import Details from "./pages/details/Details";
import Search from "./pages/search/Search";

// Other pages
import Terms from "./pages/terms-conditions/Terms";
import About from "./pages/about/About";
import Privacy from "./pages/privacy-policy/Privacy";
import HelpCenter from "./pages/help-center/HelpCenter";
import FAQ from "./pages/help-center/faq/FAQ";
import OrderPlacing from "./pages/help-center/orders/OrderPlacing";
import Payment from "./pages/help-center/payment/Payment";
import Contact from "./pages/contact/Contact";
import Donate from "./pages/donate/Donate";
import Shops from "./pages/shops/Shops";
import AccessDenied from "./pages/access-denied/AccessDenied";
import Profile from "./pages/profile/Profile";

// Admin pages
import AdminLogin from "./pages/super-admin/login/AdminLogin";
import AdminLayout from "./pages/super-admin/AdminLayout";
import AdminDashboard from "./pages/super-admin/dashboard/AdminDashboard";
import UserManagement from "./pages/super-admin/users/UserManagement";
import ProductManagement from "./pages/super-admin/products/ProductManagement";
import OrderManagement from "./pages/super-admin/orders/OrderManagement";
import ReportManagement from "./pages/super-admin/reports/ReportManagement";
import AdminMessages from "./pages/super-admin/messages/AdminMessages";

// Seller pages
import SellerLayout from "./pages/seller/SellerLayout";
import SellerDashboard from "./pages/seller/dashboard/SellerDashboard";
import SellerLogin from "./pages/seller/login/SellerLogin";
import SellerSettings from "./pages/seller/settings/SellerSettings";
import SellerProfile from "./pages/seller/profile/SellerProfile";
import SellerChangePassword from "./pages/seller/change-password/SellerChangePassword";
import SellerMessages from "./pages/seller/messages/SellerMessages";
import SellerOrders from "./pages/seller/orders/SellerOrders";
import SellerProducts from "./pages/seller/products/SellerProducts";
import ShopSettings from "./pages/seller/shop-settings/ShopSettings";
import AddProduct from "./pages/seller/add-product/AddProduct";

// Protected route
import ProtectedRoute from "./ProtectedRoute";

// Components
import ScrollUpButton from './components/slider/ScrollUpButton';
import Newsletter from './components/newsletter/Newsletter';

// Route Configuration
const routes = [
    { path: "/", element: <Dashboard /> },
    { path: "/products", element: <Products /> },
    { path: "/shops", element: <Shops /> },
    { path: "/search", element: <Search /> },
    { path: "/details", element: <Details /> },
    { path: "/register", element: <Register /> },
    { path: "/activation/:type", element: <Activation /> }, // Dynamic route for activation (shoppers or sellers)
    { path: "/super-admin/login", element: <AdminLogin /> },
    { path: "/seller/login", element: <SellerLogin /> },
    { path: "/login", element: <Login /> },
    { path: "/product-category", element: <ProductByCategory /> },
    { path: "/product-section", element: <ProductBySection /> },
    { path: "/terms-conditions", element: <Terms /> },
    { path: "/about", element: <About /> },
    { path: "/privacy-policy", element: <Privacy /> },
    { path: "/help-center/*", element: <HelpCenter /> },
    { path: "/contact", element: <Contact /> },
    { path: "/wishlist", element: <Wishlist /> },
    { path: "/profile/*", element: <Profile /> },
    { path: "/donate", element: <Donate /> },
    { path: "/category", element: <Category /> },
    { path: "/complete-profile", element: <ProfileCompletion /> },
    { path: "/access-denied", element: <AccessDenied /> },
];

function App() {
    const { 
        isSessionExpired, 
        expiredRole, 
        closeSessionExpiredModal,
        handleLogin,
        errorType  // New error type from hook
    } = useSessionExpiration();

    return (
        <>
            <Router>
                <ScrollUpButton />
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                    <Route path="/help-center/*" element={<HelpCenter />}>
                        <Route path="faq" element={<FAQ />} />
                        <Route path="orders" element={<OrderPlacing />} />
                        <Route path="payment" element={<Payment />} />
                    </Route>

                    {/* Protected routes for shoppers */}
                    <Route element={<ProtectedRoute requiredRole="shopper" isVerifiedCheck />}>
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/checkout" element={<Checkout />} />
                    </Route>

                    {/* Protected routes for sellers */}
                    <Route path="/seller/*" element={<ProtectedRoute requiredRole="seller" isVerifiedCheck />}>
                         {/* Routes wrapped by SellerLayout */}
                        <Route element={<SellerLayout />}>
                            <Route path="dashboard" element={<SellerDashboard />} />
                            <Route path="products" element={<SellerProducts />} />
                            <Route path="orders" element={<SellerOrders />} />
                            <Route path="shop-settings" element={<ShopSettings />} />
                            <Route path="add-product" element={<AddProduct />} />
                        </Route>

                        {/* Profile routes */}
                        <Route path="profile/*" element={<SellerProfile />}></Route>

                        {/* Standalone routes under /seller */}
                        <Route path="settings" element={<SellerSettings />} />
                        <Route path="change-password" element={<SellerChangePassword />} />
                        <Route path="messages" element={<SellerMessages />} />
                    </Route>

                    {/* Protected routes for admins */}
                    <Route path="/super-admin/*" element={<ProtectedRoute requiredRole="super-admin" />}>
                        <Route element={<AdminLayout />}>
                            {/* Nested Routes */}
                            <Route path="dashboard" element={<AdminDashboard />} />
                            <Route path="users" element={<UserManagement />} />
                            <Route path="products" element={<ProductManagement />} />
                            <Route path="orders" element={<OrderManagement />} />
                            <Route path="reports" element={<ReportManagement />} />
                            <Route path="messages" element={<AdminMessages />} />
                        </Route>
                    </Route>

                    {/* Catch-All Route */}
                    <Route path="*" element={<h1 style={{ textAlign: "center" }}>Page Not Found</h1>} />
                </Routes>
                <Newsletter />
            </Router>

            {/* Modal will be rendered over everything */}
            <SessionExpiredModal 
                isOpen={isSessionExpired}
                onClose={closeSessionExpiredModal}
                onLogin={handleLogin}
                role={expiredRole}
                errorType={errorType}  // Pass error type
            />
        </>
    );
}

export default App;
