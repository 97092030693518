import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { PuffLoader } from 'react-spinners';

// types
import { ProductProps, sortOptions, SortCriteria } from '../../types/Types';

// hooks
import useDebounce from '../../hooks/useDebounce';

// Components
import HeaderCopy from "../../components/header/HeaderCopy";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Pagination from '../../components/pagination/Pagination';
import WishlistHeartBeat from "../../components/icon/WishlistHeartBeat";
import PriceFilter, { PriceFilterRef } from '../../components/product filter/PriceFilter';
import ProductSort from '../../components/product filter/ProductSort';
import PriceReset from '../../components/product filter/PriceReset';
import ProductSectionFilter from "../../components/product filter/ProductSectionFilter";
import Icon from "../../components/icon/Icon";
import ProductDisplay from "../../components/products/ProductDisplay";


const Wishlist = () => {
    const [products, setProducts] = useState<ProductProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [sortCriteria, setSortCriteria] = useState<SortCriteria>("most_recent");
    const [minPrice, setMinPrice] = useState<number | null>(null);
    const [maxPrice, setMaxPrice] = useState<number | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState(''); // Controlled input for search
    const debouncedsearchTerm = useDebounce(searchTerm, 300);
    const [skeletonCount, setSkeletonCount] = useState(24);
    const priceFilterRef = useRef<PriceFilterRef>(null);

    // initialise breadcrum list
    const breadcrumbItems = [
        { label: "Home", to: "/" },
        { label: "Wishlist" },
    ];

    // Fetch wishlist products with sorting and filtering
    const fetchWishlistProducts = async () => {
        setLoading(true);

        try {
            const userSessionId = localStorage.getItem('userSessionId');

            if (userSessionId) {
                // Build the parameters to send to the backend
                const params = {
                    search: debouncedsearchTerm,  // Search term for filtering
                    sort: sortCriteria,           // Sorting criteria (e.g., 'most_recent', 'lowest_price')
                    minPrice: minPrice !== null ? minPrice : undefined,      // Min price for filtering
                    maxPrice: maxPrice !== null ? maxPrice : undefined, // Max price for filtering
                    page: currentPage,            // Pagination (current page)
                };

                const response = await axios.get(
                    `${process.env.REACT_APP_Base_url}/shoppers/wishlist/${userSessionId}`,
                    { params }
                );
                    
                const { products, totalPages, total } = response.data; // Destructure the response
                    
                setProducts(products);
                setTotalPages(totalPages);
                setSkeletonCount(total);

                // Scroll to the top after successfully fetching products
                window.scrollTo(0, 0);
                } else {
                    console.error('No user session ID found');
                }
            } catch (error) {
                console.error('Error fetching wishlist products:', error);
                setError('Failed to fetch products. Please try again.');
                setSkeletonCount(0);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchWishlistProducts();
    }, [debouncedsearchTerm, currentPage, sortCriteria, minPrice, maxPrice]);

    // Function to handle pagination
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Function to sort products by highest and lowest prices, most recent and rating
    const handleSortChange = (sortValue: SortCriteria) => {
        setSortCriteria(sortValue); // Update the sort criteria
        setCurrentPage(1); // Reset to the first page
    };

    // Function to handle the filtering of products by price range
    const handleFilter = () => {
        if (!priceFilterRef.current) return; // Ensure the ref exists
    
        const min = parseFloat(String(priceFilterRef.current.minPrice)) || 0;
        const max = parseFloat(String(priceFilterRef.current.maxPrice)) || 1000000;
    
        // Apply the filter logic using min and max prices
        setMinPrice(min);
        setMaxPrice(max);
        setCurrentPage(1); // Reset to first page after filtering
    };

    // Functions to clear the minimum and maximum price inputs
    const handleResetMinPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMinPrice(null); // Reset minPrice to null
        priceFilterRef.current?.resetMinPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetMaxPrice = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Prevent default behavior
        setMaxPrice(null); // Reset maxPrice to null
        priceFilterRef.current?.resetMaxPrice(); // Call prop function to reset in PriceFilter component
        setCurrentPage(1); // Reset page to 1 to fetch products again
    };

    const handleResetSearch = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setSearchTerm('');
        setCurrentPage(1);
    };

    const handleResetAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        // Call individual reset handlers
        handleResetMinPrice(e); 
        handleResetMaxPrice(e);
        handleResetSearch(e);
        setCurrentPage(1); // Reset page to 1
    };


    return (
        <>
            <HeaderCopy />
      
            <div id="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 product-list-header">
                            <h1 className="page-title product-list-title">Wishlist&nbsp;<WishlistHeartBeat /></h1>
                        </div>
                    </div>
                    <div className="container-products-page">
                        <div className="row">
                            <div className="col-12 m-b-20 container-filter-products-mobile">
                                <div className="btn-filter-products-mobile">
                                    <button 
                                        className="btn btn-md" 
                                        type="button" 
                                        data-toggle="collapse" 
                                        data-target="#collapseFilters" 
                                        aria-expanded="true" 
                                        aria-controls="collapseFilters"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#666666" viewBox="0 0 256 256">
                                            <path d="M200,136a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,136Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z"></path>
                                        </svg>
                                        &nbsp;&nbsp;
                                        <span className="text">Filter Products</span>
                                    </button>
                                </div>
                                {/* product sorting on smaller devices */}
                                <ProductSort
                                    sortOptions={sortOptions}
                                    sortCriteria={sortCriteria}
                                    handleSortChange={handleSortChange}
                                />
                            </div>

                            <div className="col-12 col-md-3 col-sidebar-products">
                                <div className="sticky-lg-top hidden-scrollbar">
                                    <div id="collapseFilters" className="product-filters collapse d-md-block">
                                        
                                        {/* Render PriceFilter component and pass handleFilter function */}
                                        <div className="wishlist-price-filter">
                                            <PriceFilter 
                                                ref={priceFilterRef} 
                                                onFilter={handleFilter}
                                            />
                                        </div>

                                        <div className="filter-item">
                                            <h4 className="title">Filter by keyword</h4>
                                            <div className="product-search">
                                                <input 
                                                    type="text" 
                                                    id="input_filter_keyword" 
                                                    value={searchTerm}
                                                    className="form-control form-input"
                                                    placeholder="Keyword"
                                                    onChange={(e) => {
                                                        setSearchTerm(e.target.value);
                                                        setCurrentPage(1);
                                                    }}
                                                />                                
                                                <button 
                                                    type="button" 
                                                    id="btnFilterByKeyword" 
                                                    className="btn btn-md btn-filter-product"
                                                    onClick={handleFilter}
                                                    disabled={loading}
                                                > 
                                                    {loading ? (
                                                        <div style={loaderContainerStyle}>
                                                            <PuffLoader size={20} color="#000" />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <Icon name="CiSearch" style={{ top: '-1.5px', position: 'relative' }} />
                                                            &nbsp;Filter
                                                        </>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-custom"></div>
                                </div>
                            </div>
                            
                            <div className="col-12 col-md-9 col-content-products">
                               {/* product sorting on large devices*/}
                               <div className="clearfix m-b-20 container-filter-products">
                                    <ProductSort
                                        sortOptions={sortOptions}
                                        sortCriteria={sortCriteria}
                                        handleSortChange={handleSortChange}
                                    />
                                </div>

                                <PriceReset
                                    minPrice={minPrice}
                                    maxPrice={maxPrice}
                                    searchKeyword={searchTerm}
                                    handleResetMinPrice={handleResetMinPrice}
                                    handleResetMaxPrice={handleResetMaxPrice}
                                    handleResetSearch={handleResetSearch}
                                    handleResetAll={handleResetAll}
                                />
                            
                                {/* products display */}  
                                <ProductDisplay
                                    products={products}
                                    loading={loading}
                                    error={error}
                                    skeletonCount={skeletonCount}
                                    fetchProducts={fetchWishlistProducts}
                                    setError={setError}
                                    className="col-6 col-sm-4 col-md-4 col-lg-3 col-product"
                                />
                               
                                {/* Pagination */}
                                <Pagination
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <Footer />
        </>
    );
};

const loaderContainerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
};

export default Wishlist;