import React, { useState} from "react";
import { Link } from "react-router-dom";
import user from "../../assets/img/user.png";
import Icon from "../icon/Icon";
import AccessDeniedModal from "../../components/modals/AccessDeniedModal";
import { authApi } from "../../services/api";

const Navigation: React.FC = () => {
    // Get seller name and status from sessionStorage
    const SellerName: string = sessionStorage.getItem("seller_name") || "User"; // Fallback for name
    const userStatus: string = sessionStorage.getItem("status") || "active"; // Default to active if not set
    const isBlocked: boolean = userStatus === "blocked";
    
    const [showAccessDeniedModal, setShowAccessDeniedModal] = useState<boolean>(false);
    const [userRole, setUserRole] = useState<"seller" | "shopper" | null>(null);

    const isValidStatus = (status: string): status is "blocked" | "pending" | "inactive" => 
        ["blocked", "pending", "inactive"].includes(status);
    
    // handles user access
    const handleAccessDenied = (): void => {
        const role = sessionStorage.getItem("role");

        if (userStatus !== "active") {
            // Show AccessDeniedModal for inactive or other invalid statuses
            if (role === "seller" || role === "shopper" || role === null) {
                setUserRole(role);
            } else {
                console.error("Invalid role value:", role);
            }
            setShowAccessDeniedModal(true);
        }
    };

    // retrieve seller picture from session storage
    const getValidProfilePicture = (): string => {
        const profilePicture = sessionStorage.getItem("profile_picture");
        return profilePicture && profilePicture !== "null" ? profilePicture : user;
    };

    // logout function
    const logOut = async (): Promise<void> => {
        try {
          // Call backend logout to invalidate server-side token
          await authApi.post("/utils/logout", {});
    
          // Clear client-side storage
          sessionStorage.clear();
    
          // Redirect to login
          window.location.href = "/seller/login";
        } catch (error) {
          console.error("Logout failed", error);
    
          // Fallback: still clear local data and redirect
          sessionStorage.clear();
          window.location.href = "/seller/login";
        }
    };

    if (!SellerName) return null; // Do not render Nav if not logged in

    return (
        <>
            <li className="nav-item dropdown profile-dropdown p-r-0">
                <a 
                    href="#" 
                    className="nav-link dropdown-toggle a-profile" 
                    data-toggle="dropdown" 
                    aria-expanded="false" 
                    tabIndex={0}
                    onClick={(e) => e.preventDefault()}
                >
                    <img src={getValidProfilePicture()} alt={SellerName} />
                    {SellerName}
                    <Icon name="BiChevronDown" color="#888" style={{ display: 'inline-flex' }} />
                </a>
                <ul className="dropdown-menu" role="menu">
                    <li>
                        <>
                            {userStatus === "active" ? (
                                <Link to="/seller/dashboard">
                                    <div className="icon">
                                        <Icon name="FaTachometerAlt" size="1.5em" color="#4CAF50" />
                                    </div>
                                    Dashboard
                                </Link>
                            ) : userStatus === "pending" ? (
                                <a 
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAccessDenied();
                                    }}
                                >
                                    <div className="icon">
                                        <Icon name="FaExclamationTriangle" size="1.5em" color="#FFC107" />
                                    </div>
                                    Verification Pending
                                </a>
                            ) : userStatus === "blocked" ? (
                                <a 
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAccessDenied();
                                    }}
                                >
                                    <div className="icon">
                                        <Icon name="FaBan" size="1.5em" color="#F44336" />
                                    </div>
                                    Account Blocked
                                </a>
                            ) : (
                                <a 
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAccessDenied();
                                    }}
                                >
                                    <div className="icon">
                                        <Icon name="FaRegClock" size="1.5em" color="#747474" />
                                    </div>
                                    Account Inactive
                                </a>
                            )}
                        </>
                    </li>

                    {!isBlocked && (  // Only render if the user is not blocked
                        <>
                            <li>
                                <Link to="/seller/profile">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#747474" fill="none">
                                            <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="#747474" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="#747474" stroke-width="1.5"></path>
                                        </svg>
                                    </div>
                                    Profile
                                </Link>
                            </li>
                            <li>
                                <Link to="/seller/orders">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#747474" viewBox="0 0 256 256">
                                            <path d="M136,120v56a8,8,0,0,1-16,0V120a8,8,0,0,1,16,0Zm36.84-.8-5.6,56A8,8,0,0,0,174.4,184a7.32,7.32,0,0,0,.81,0,8,8,0,0,0,7.95-7.2l5.6-56a8,8,0,0,0-15.92-1.6Zm-89.68,0a8,8,0,0,0-15.92,1.6l5.6,56a8,8,0,0,0,8,7.2,7.32,7.32,0,0,0,.81,0,8,8,0,0,0,7.16-8.76ZM239.93,89.06,224.86,202.12A16.06,16.06,0,0,1,209,216H47a16.06,16.06,0,0,1-15.86-13.88L16.07,89.06A8,8,0,0,1,24,80H68.37L122,18.73a8,8,0,0,1,12,0L187.63,80H232a8,8,0,0,1,7.93,9.06ZM89.63,80h76.74L128,36.15ZM222.86,96H33.14L47,200H209Z"></path>
                                        </svg>
                                    </div>
                                    Orders
                                </Link>
                            </li>
                            <li>
                                <Link to="/seller/messages">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 32 32">
                                            <path fill="#747474" d="M17.74 30L16 29l4-7h6a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h9v2H6a4 4 0 0 1-4-4V8a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4h-4.84Z"></path>
                                            <path fill="#747474" d="M8 10h16v2H8zm0 6h10v2H8z"></path>
                                        </svg>
                                    </div>
                                    Messages
                                </Link>
                            </li>
                            <li>
                                <Link to="/seller/settings">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#747474" viewBox="0 0 256 256">
                                            <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Zm88-29.84q.06-2.16,0-4.32l14.92-18.64a8,8,0,0,0,1.48-7.06,107.21,107.21,0,0,0-10.88-26.25,8,8,0,0,0-6-3.93l-23.72-2.64q-1.48-1.56-3-3L186,40.54a8,8,0,0,0-3.94-6,107.71,107.71,0,0,0-26.25-10.87,8,8,0,0,0-7.06,1.49L130.16,40Q128,40,125.84,40L107.2,25.11a8,8,0,0,0-7.06-1.48A107.6,107.6,0,0,0,73.89,34.51a8,8,0,0,0-3.93,6L67.32,64.27q-1.56,1.49-3,3L40.54,70a8,8,0,0,0-6,3.94,107.71,107.71,0,0,0-10.87,26.25,8,8,0,0,0,1.49,7.06L40,125.84Q40,128,40,130.16L25.11,148.8a8,8,0,0,0-1.48,7.06,107.21,107.21,0,0,0,10.88,26.25,8,8,0,0,0,6,3.93l23.72,2.64q1.49,1.56,3,3L70,215.46a8,8,0,0,0,3.94,6,107.71,107.71,0,0,0,26.25,10.87,8,8,0,0,0,7.06-1.49L125.84,216q2.16.06,4.32,0l18.64,14.92a8,8,0,0,0,7.06,1.48,107.21,107.21,0,0,0,26.25-10.88,8,8,0,0,0,3.93-6l2.64-23.72q1.56-1.48,3-3L215.46,186a8,8,0,0,0,6-3.94,107.71,107.71,0,0,0,10.87-26.25,8,8,0,0,0-1.49-7.06Zm-16.1-6.5a73.93,73.93,0,0,1,0,8.68,8,8,0,0,0,1.74,5.48l14.19,17.73a91.57,91.57,0,0,1-6.23,15L187,173.11a8,8,0,0,0-5.1,2.64,74.11,74.11,0,0,1-6.14,6.14,8,8,0,0,0-2.64,5.1l-2.51,22.58a91.32,91.32,0,0,1-15,6.23l-17.74-14.19a8,8,0,0,0-5-1.75h-.48a73.93,73.93,0,0,1-8.68,0,8,8,0,0,0-5.48,1.74L100.45,215.8a91.57,91.57,0,0,1-15-6.23L82.89,187a8,8,0,0,0-2.64-5.1,74.11,74.11,0,0,1-6.14-6.14,8,8,0,0,0-5.1-2.64L46.43,170.6a91.32,91.32,0,0,1-6.23-15l14.19-17.74a8,8,0,0,0,1.74-5.48,73.93,73.93,0,0,1,0-8.68,8,8,0,0,0-1.74-5.48L40.2,100.45a91.57,91.57,0,0,1,6.23-15L69,82.89a8,8,0,0,0,5.1-2.64,74.11,74.11,0,0,1,6.14-6.14A8,8,0,0,0,82.89,69L85.4,46.43a91.32,91.32,0,0,1,15-6.23l17.74,14.19a8,8,0,0,0,5.48,1.74,73.93,73.93,0,0,1,8.68,0,8,8,0,0,0,5.48-1.74L155.55,40.2a91.57,91.57,0,0,1,15,6.23L173.11,69a8,8,0,0,0,2.64,5.1,74.11,74.11,0,0,1,6.14,6.14,8,8,0,0,0,5.1,2.64l22.58,2.51a91.32,91.32,0,0,1,6.23,15l-14.19,17.74A8,8,0,0,0,199.87,123.66Z"></path>
                                        </svg>
                                    </div>
                                    Profile Settings
                                </Link>
                            </li>
                        </>
                    )}

                    <li>
                        <form className="form-logout">
                            <button type="submit" className="btn-logout btn-logout-mobile" onClick={logOut}>
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="#747474" viewBox="0 0 256 256">
                                        <path d="M120,216a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H56V208h56A8,8,0,0,1,120,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L204.69,120H112a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,229.66,122.34Z"></path>
                                    </svg>
                                </div>
                                Logout
                            </button>
                        </form>
                    </li>
                    
                </ul>
            </li>
            {showAccessDeniedModal && userRole && isValidStatus(userStatus) && (
                <AccessDeniedModal userRole={userRole} userStatus={userStatus} onClose={() => setShowAccessDeniedModal(false)} />
            )}
        </>
    );
};

export default Navigation;