import { useState, useEffect } from "react";
import { PaginationResponse } from "../types/Types";

interface UsePaginationReturn<T> {
    data: T[];
    setData: React.Dispatch<React.SetStateAction<T[]>>;
    currentPage: number;
    totalPages: number;
    totalItems: number;
    loading: boolean;
    error: string | null;
    handlePrev: () => void;
    handleNext: () => void;
    setPage: (page: number) => void;
    startItem: number;
    endItem: number;
}

const usePagination = <T,>(
    fetchFunction: (page: number, limit: number) => Promise<PaginationResponse<T>>,
    limit: number = 5,
    dependencies: any[] = []
): UsePaginationReturn<T> => {
    const [data, setData] = useState<T[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Calculate start and end items for current page
    const startItem = totalItems === 0 ? 0 : (currentPage - 1) * limit + 1;
    const endItem = Math.min(currentPage * limit, totalItems);

    const fetchData = async (page: number) => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchFunction(page, limit);
            const { data: items, total, totalPages: newTotalPages } = response;

            if (Array.isArray(items)) {
                // If current page is greater than new total pages, reset to page 1
                if (page > newTotalPages && newTotalPages > 0) {
                    setCurrentPage(1);
                    // Fetch data again with page 1
                    const newResponse = await fetchFunction(1, limit);
                    setData(newResponse.data);
                    setTotalItems(newResponse.total);
                    setTotalPages(newResponse.totalPages);
                } else {
                    setData(items);
                    setTotalItems(total);
                    setTotalPages(newTotalPages);
                }
            } else {
                throw new Error("Invalid data format from API");
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : "Failed to fetch data");
            // Reset data on error
            setData([]);
            setTotalItems(0);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, ...dependencies]);

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const setPage = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        } else if (page < 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(totalPages);
        }
    };

    return {
        data,
        setData,
        currentPage,
        totalPages,
        totalItems,
        loading,
        error,
        handlePrev,
        handleNext,
        setPage,
        startItem,
        endItem,
    };
};

export default usePagination;